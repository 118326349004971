<template>
  <v-snackbar
    v-model="snackbarStore.showSnackbar"
    :timeout="snackbarStore.snackbarTime"
    color="rgba(80, 80, 80, 0.8)"
  >
    {{ snackbarStore.snackbarText }}

    <template #actions>
      <v-btn color="blue-lighten-3" variant="text" @click="hide()">閉じる</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useSnackbarStore } from '@/stores/snackbar'
const snackbarStore = useSnackbarStore()

const hide = () => {
  snackbarStore.setShowSnackbar(false)
  snackbarStore.setSnackbarText('')
}
</script>

<style lang="scss"></style>
