<template>
  <article class="clip-card">
    <div
      v-if="statusText"
      class="clip-card__badge"
      :class="{ 'clip-card__badge--error': statusError }"
    >
      {{ statusText }}
    </div>
    <!-- サムネイル部分 -->
    <div
      v-if="status === 'progressing' || statusError"
      class="clip-card__cover clip-card__cover--no-image"
    >
      <v-icon v-if="statusError" icon="mdi-movie-remove-outline" size="42" color="white" />
      <v-icon v-else icon="mdi-file-upload-outline" size="42" color="white" />
    </div>
    <router-link v-else :to="`/player?clipId=${clipId}`" class="clip-card__cover">
      <img :src="thumbnailURL" class="clip-card__image" />
    </router-link>
    <!-- テキスト部分 -->
    <section class="clip-card__content">
      <h1 class="clip-card__title">
        {{ title }}
      </h1>
      <p class="clip-card__sub">{{ formatDuration }}{{ formatCreated }}</p>
    </section>
    <!-- ボタン部分 -->
    <div class="clip-card__buttons">
      <router-link
        v-if="!statusError"
        :to="`/player?clipId=${clipId}`"
        class="clip-card__button clip-card__button--play"
      >
        <v-icon icon="mdi-play-box-outline" size="24" />
      </router-link>
      <router-link
        v-if="isTenant && !statusError"
        :to="`/editor/default?clipId=${clipId}`"
        class="clip-card__button clip-card__button--edit"
      >
        <v-icon icon="mdi-note-edit-outline" size="24" />
      </router-link>
      <button v-if="isTenant" class="clip-card__button clip-card__button--delete" @click="onDelete">
        <v-icon icon="mdi-trash-can-outline" size="24" />
      </button>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import dayjs from '@/common/dayjs'

// Props
const props = defineProps({
  clipId: {
    type: String,
    required: true
  },
  status: {
    type: String,
    required: false,
    default: ''
  },
  title: {
    type: String,
    required: true
  },
  duration: {
    type: Number,
    required: false,
    default: null
  },
  created: {
    type: String,
    required: false,
    default: ''
  },
  isTenant: {
    type: Boolean,
    required: true
  }
})

const thumbnailURL = computed(
  (): string =>
    `https://${import.meta.env.VITE_APP_MEDIA_DOMAIN}/private/${props.clipId}/thumbnail.jpg`
)

const formatSecondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  let result = ''

  if (hours > 0) result += hours + '時間'
  if (minutes > 0) result += minutes + '分'
  if (remainingSeconds > 0) result += remainingSeconds + '秒'

  return result
}

const statusText = computed(() => {
  if (props.status === 'submitted') {
    return 'アップロード済'
  } else if (props.status === 'progressing') {
    return '処理中'
  } else if (props.status === 'error') {
    return '処理失敗'
  } else {
    return ''
  }
})

const statusError = computed(() => {
  return props.status === 'error'
})

const formatDuration = computed(() => {
  return props.duration ? `${formatSecondsToTime(props.duration)} | ` : ''
})

const formatCreated = computed(() => {
  return props.created ? dayjs(props.created).format('YYYY.MM.DD更新') : ''
})

// Emit
interface Emits {
  (_event: 'delete'): void
}
const emit = defineEmits<Emits>()

// Methods
const onDelete = () => {
  emit('delete')
}
</script>

<style lang="scss">
.clip-card {
  width: 100%;
  background: #fff;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  @include mq-up(tablet) {
    overflow: hidden;
    border-radius: 3px;
  }

  &__badge {
    position: absolute;
    right: 0;
    top: 0;
    color: $color-mono-f;
    background: $color-primary;
    font-size: 12px;
    line-height: 1;
    padding: 6px;
    font-weight: bold;
    z-index: 10;

    @include mq-up(tablet) {
      border-top-right-radius: 3px;
    }

    @include mq-down(tablet--under) {
      font-size: 10px;
    }

    &--error {
      background: $color-danger;
    }
  }

  &__cover {
    width: 100%;
    height: 120px;
    display: block;
    background: $color-black;
    transition: 0.2s;
    overflow: hidden;

    &:active {
      opacity: 0.8;
    }

    @include mq-up(tablet-pc) {
      &:hover {
        opacity: 0.8;

        img {
          transform: scale(1.1);
        }
      }
    }
    @include mq-down(tablet--under) {
      height: 150px;
    }

    &--no-image {
      @include flex(center);
      &:active {
        opacity: 1;
      }

      @include mq-up(tablet-pc) {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    transition: 0.5s;
    object-fit: cover;
    display: block;
  }

  &__content {
    padding: 16px 12px 19px;
  }

  &__title {
    line-height: 1.6;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include mq-up(tablet) {
      min-height: 60px;
    }
  }

  &__sub {
    color: $color-sub-text;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.1;
  }

  &__buttons {
    @include flex(center);
  }

  &__button {
    @include flex(center);
    width: 100%;
    height: 40px;
    color: $color-mono-f;
    text-decoration: none;
    transition: 0.2s;

    &:active {
      opacity: 0.8;
    }

    @include mq-up(tablet-pc) {
      &:hover {
        opacity: 0.8;
      }
    }

    &--play {
      background: $color-primary;
    }
    &--edit {
      background: $color-secondary;
    }
    &--delete {
      background: $color-danger;
    }
  }
}
</style>
