<template>
  <v-container>
    <v-layout align-content-space-around class="mb-6">
      <div class="text-h6 text-md-h5 text-lg-h4">ユーザー管理</div>
      <v-spacer />
      <div style="min-width: 280px">
        <v-text-field
          v-model="searchText"
          hide-details
          label="検索メールアドレス(前方一致)"
          prepend-inner-icon="mdi-magnify"
        />
      </div>
    </v-layout>
    <v-card>
      <v-table>
        <thead>
          <tr>
            <th>有効</th>
            <th>権限</th>
            <th>ユーザー名</th>
            <th>メールアドレス</th>
            <th>ユーザID</th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="4">
              <v-row justify="center" class="pa-12">
                <v-progress-circular v-if="isLoading" indeterminate :size="32" />
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody v-if="filteredUsers?.length == 0 && !isLoading">
          <tr>
            <td colspan="4" class="pa-12 text-center">ユーザーが見つかりません。</td>
          </tr>
        </tbody>
        <tbody v-if="filteredUsers?.length > 0 && !isLoading">
          <tr v-for="(user, index) in filteredUsers" :key="index">
            <td>
              <v-checkbox-btn
                v-model="user.subscriptions[0].isEnabled"
                :disabled="user.email === authStore.user.email"
                @change="toggleEnabled(user)"
              />
            </td>
            <td>
              <v-select
                v-model="user.subscriptions[0].role"
                :disabled="user.email === authStore.user.email"
                :items="[
                  {
                    value: 'user',
                    label: 'ユーザー'
                  },
                  {
                    value: 'tenant',
                    label: '管理者'
                  }
                ]"
                item-title="label"
                item-value="value"
                color="primary"
                density="compact"
                hide-details
                style="min-width: 130px"
                @update:model-value="toggleEnabled(user, user.subscriptions[0].role)"
              />
            </td>
            <td style="max-width: 320px; min-width: 120px" class="pt-2 pb-2">
              {{ user.userName }}
            </td>
            <td style="max-width: 360px; min-width: 160px" class="pt-2 pb-2">
              {{ user.email }}
            </td>
            <td style="max-width: 360px; min-width: 160px" class="pt-2 pb-2">
              {{ user.userId }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <div v-if="calculatedPage > 1" class="text-center mt-8">
      <v-pagination
        v-model="page"
        :length="calculatedPage"
        :total-visible="4"
        @update:model-value="changePage"
      />
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSnackbarStore } from '@/stores/snackbar'
import { ref, computed, watch, onMounted } from 'vue'
import axios from '@/common/axios'
import { storeToRefs } from 'pinia'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()

type subscriptionType = {
  isEnabled: boolean
  projectId: string
  projectName: string
  role: 'user' | 'tenant'
  tenantId: string
  tenantName: string
}

type userType = {
  email: string
  subscriptions: Array<subscriptionType>
  userName: string
  userId: string
}

const users = ref<userType[]>([])
const searchText = ref('')
const isLoading = ref(false)
const page = ref(1)
const hasError = ref(false)
const errorTitle = ref('')
const errorMessage = ref('')

const filteredUsers = computed(() => {
  if (!searchText.value) return users.value
  return users.value
    .filter(user => `${user.email}`.startsWith(searchText.value))
    .slice((page.value - 1) * 10, page.value * 10)
})
const calculatedPage = computed(() => {
  return Math.ceil(filteredUsers.value.length / 10)
})

const { activeSubscription } = storeToRefs(authStore)
watch(activeSubscription, async subscription => {
  if (subscription) await fetchUserList()
})

onMounted(async () => {
  if (!authStore.activeSubscription) return
  page.value =
    typeof route.query.page === 'string' || typeof route.query.page === 'number'
      ? parseInt(route.query.page)
      : 1
  await fetchUserList()
})

const fetchUserList = async () => {
  isLoading.value = true
  try {
    const fetchedTenants = await axios.get('/users', {
      params: {
        tenantId: authStore.activeSubscription.tenantId,
        projectId: authStore.activeSubscription.projectId,
        role: 'tenant'
      }
    })
    const fetchedUsers = await axios.get('/users', {
      params: {
        tenantId: authStore.activeSubscription.tenantId,
        projectId: authStore.activeSubscription.projectId,
        role: 'user'
      }
    })
    // NOTE：ユーザーが0件の時に404で返却されるがcatchに入らないので、try内で判定
    if (fetchedUsers.data.errorCode === '0004' && fetchedTenants.data.errorCode === '0004') {
      users.value = []
    } else {
      // NOTE：ログインしている自分のアカウントは除外
      users.value = [...fetchedTenants.data.users, ...fetchedUsers.data.users]
    }
  } catch (error) {
    hasError.value = true
    errorTitle.value = 'ユーザーの取得に失敗しました。'
    errorMessage.value = 'ユーザーの取得に失敗にしました。再度お試しください。'
  } finally {
    isLoading.value = false
  }
}
const toggleEnabled = async (
  user: { subscriptions: Array<subscriptionType>; userId: string },
  role?: string
) => {
  const subscription = user.subscriptions[0]

  const params: { isEnabled: boolean; role?: string } = {
    isEnabled: subscription.isEnabled
  }

  // NOTE：管理者のチェックボックスを操作した時のみ実行
  if (role !== undefined) {
    subscription.role = role ? 'user' : 'tenant'
    params.role = role
  }

  try {
    await axios.patch(
      `/users/${user.userId}/tenants/${subscription.tenantId}/projects/${subscription.projectId}`,
      params
    )

    await fetchUserList()

    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('更新しました。')
  } catch (error) {
    hasError.value = true
    errorTitle.value = '更新に失敗しました。'
    errorMessage.value =
      '更新に失敗しました。再度お試しいただき改善されない場合は運営までお問い合わせください。'
  }
}
const changePage = (destinationPage: number) => {
  page.value = destinationPage
  router.push(`?page=${destinationPage}`)
}
</script>
