// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsConfig = {
  aws_project_region: 'ap-northeast-1',
  aws_cognito_region: 'ap-northeast-1',
  aws_user_pools_id: import.meta.env.VITE_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: import.meta.env.VITE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: import.meta.env.VITE_APP_OAUTH_DOMAIN,
    redirectSignIn: '',
    redirectSignOut: '',
    responseType: 'code'
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ['EMAIL']
}

export default awsConfig
