<template>
  <v-container>
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="パスワードリセット" color="indigo" />
      <v-form ref="form" class="pa-8" @submit.prevent="onConfirm()">
        <p class="mb-8">
          入力いただいた「メールアドレス」宛に「検証コード」を送信しました。
          <br />
          以下の入力欄から「検証コード」と「パスワード」を入力して「送信」してください。
        </p>
        <p>メールアドレス</p>
        <p class="mt-1">
          {{ mailAddress }}
        </p>
        <v-text-field v-model="code" :rules="codeRules" class="mt-8 mb-2">
          <template #label>
            検証コード
            <span style="color: red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :rules="passwordRules"
          @click:append="showPassword = !showPassword"
        >
          <template #label>
            パスワード
            <span style="color: red">*</span>
          </template>
        </v-text-field>
        <v-row class="justify-center mt-4">
          <v-col cols="auto">
            <v-btn
              class="ml-1"
              varient="outlined"
              size="large"
              min-width="120"
              @click="$router.back()"
            >
              戻る
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" class="ml-1" size="large" min-width="120" type="submit">
              送信
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="onClose(false)"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import { Auth } from 'aws-amplify'
import { useProgressDialogStore } from '@/stores/dialog/progress'

const route = useRoute()
const router = useRouter()

const progressStore = useProgressDialogStore()

const mailAddress = ref<string>('')
const code = ref<string>('')
const password = ref<string>('')
const showPassword = ref<boolean>(false)
const hasError = ref<boolean>(false)
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')
const codeRules = ref<Array<(_v: string) => boolean | string>>([
  v => !!v || '検証コードを入力してください',
  v => /^\d*$/.test(v) || '検証コードが正しい形式ではありません'
])
const passwordRules = ref<Array<(_v: string) => boolean | string>>([
  v => !!v || 'パスワードを入力してください',
  v => (v && v.length >= 8) || 'パスワードは８文字以上の半角英数字を設定してください'
])

onMounted((): void => {
  mailAddress.value = route.query.mailAddress && route.query.mailAddress.replace(' ', '+')
})

const onConfirm = async (): Promise<void> => {
  try {
    progressStore.setLoading(true)
    await Auth.forgotPasswordSubmit(mailAddress.value, code.value, password.value)
    router.push('/')
  } catch (error: any) {
    hasError.value = true
    switch (error.code) {
      case 'LimitExceededException': // コードを間違え続けた場合に起こる。
        errorTitle.value = '検証に失敗しました'
        errorMessage.value =
          '検証コードを一定回数間違えたためロックされました。しばらく時間をおいてもう一度お試しください。'
        break
      case 'ExpiredCodeException': // コードが期限切れ（1時間をオーバー）した場合に起こる。
        errorTitle.value = '検証コードの有効期限が切れました'
        errorMessage.value =
          '検証コードの有効期限が切れました。メールアドレス入力からやり直してください。'
        break
      default:
        errorTitle.value = '検証に失敗しました'
        errorMessage.value = '入力内容をご確認の上、再度お試しください。'
    }
  }
  progressStore.setLoading(false)
}
const onClose = (bool: boolean): void => {
  hasError.value = bool
  errorTitle.value = ''
  errorMessage.value = ''
}
</script>
