<template>
  <v-dialog v-model="props.show" min-width="300" max-width="400" @click:outside="onClickOutside">
    <v-card>
      <v-toolbar :title="title" :color="isDelete ? 'red' : 'primary'" />
      <v-card-text class="pa-0 pb-2">
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn variant="outlined" size="large" min-width="120" @click="onClose()">
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          v-if="showPrimaryButton"
          color="primary"
          variant="flat"
          size="large"
          min-width="120"
          :disabled="disabled"
          @click="onClick()"
        >
          {{ primaryButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false
  },
  title: {
    type: String,
    required: true
  },
  enableOutside: {
    type: Boolean,
    required: false,
    default: true
  },
  // 削除モーダルを表示する際に使用
  isDelete: {
    type: Boolean,
    required: false,
    default: false
  },
  closeButtonText: {
    type: String,
    required: false,
    default: '閉じる'
  },
  primaryButtonText: {
    type: String,
    required: false,
    default: ''
  },
  showPrimaryButton: {
    type: Boolean,
    required: false,
    default: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

// Emit
interface Emits {
  (_event: 'close', _val: false): void
  (_event: 'action', _val: false): void
}
const emit = defineEmits<Emits>()

const onClose = () => {
  emit('close', false)
}
const onClick = () => {
  emit('action', false)
}
const onClickOutside = () => {
  props.enableOutside && emit('close', false)
}
</script>

<style lang="scss"></style>
