<template>
  <v-container class="privacy-policy ml-auto mr-auto">
    <h1 class="text-h6 text-md-h5 text-lg-h4 mb-6">プライバシーポリシー</h1>
    <v-card class="pa-6">
      <v-card-text>
        MasterVisions株式会社（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
      </v-card-text>
      <v-card-title>第1条（個人情報）</v-card-title>
      <v-card-text>
        「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
      </v-card-text>
      <v-card-title>第2条（個人情報の収集方法）</v-card-title>
      <v-card-text>
        当社は、事業運営に必要な範囲内に限り、お客様や従業者の氏名、勤務先、電話番号、メールアドレスなどの個人情報をお預かり致します。個人情報の取得にあたっては、あらかじめ利用目的を明確化し、法令を遵守した適切な手段によりその取得を行います。取得した個人情報は、ご本人の同意なく利用目的以外に利用することはありません。
        <br />
        <br />
        また当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細はGoogleアナリティクスサービス利用規約のページやGoogleポリシーと規約ページをご覧ください。
      </v-card-text>
      <v-card-title>第3条（個人情報を収集・利用する目的）</v-card-title>
      <v-card-text>
        当社が取得した個人情報は、事業目的に照らし、当社が行う事業に関するご案内、契約の締結・履行、新サービスの開発、およびお客様に有益と思われる情報のご提供などのために利用させて頂きます。
        <br />
        <br />
        具体的には、以下のような目的のために利用いたします。
        <br />
        <br />
        <ul class="ml-6">
          <li>お客様からのご意見・ご感想を頂くため</li>
          <li>お問い合わせや資料請求の対応のため</li>
          <li>当社の商品・サービスのご案内のため</li>
          <li>サービスの開発または改善のため</li>
          <li>企画、デザイン、設計、施工等の業務を遂行するため</li>
          <li>業務の遂行に必要な契約の締結ならびに履行のため</li>
          <li>各種申請、報告、および事業活動に必要なその他手続きの実施のため</li>
          <li>制作案件の記録およびこれに付随する管理業務を行うため</li>
          <li>社内外に対する広報活動およびこれに付随する業務を行うため</li>
          <li>会計監査上の確認作業のため</li>
          <li>採用選考の申込受付および実施のため</li>
          <li>その他、事業活動に必要な業務を行うため</li>
        </ul>
      </v-card-text>
      <v-card-title>第4条（個人情報の第三者提供）</v-card-title>
      <v-card-text>
        当社は、前項の利用目的を達成するために、提携会社に業務を委託し、業務に必要な範囲内で個人情報を預託する場合があります。その場合は、当社と業務委託先との間で個人情報に関する秘密保持契約を締結するなど、適切な監督および管理を行います。
        <br />
        <br />
        それ以外には、以下の場合を除き、ご本人の同意なく第三者に個人情報の提供はいたしません。
        <br />
        <br />
        <ul class="ml-6">
          <li>ご本人の同意を得ている場合</li>
          <li>
            当社と個人情報に関する秘密保持契約を締結している提携会社および業務委託先に外部委託をした場合
          </li>
          <li>統計データの取得など、個人を識別できない状態に加工する場合</li>
          <li>法令等により提供が求められた場合、または法令により認められている場合</li>
          <li>
            人の生命または身体や財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合
          </li>
          <li>
            国または地方公共団体もしくはその委託を受けた事業者などが、法令の定める事務を実施する上で、当社が協力する必要がある場合であって、ご本人の同意を得ることが困難である場合
          </li>
          <li>
            裁判所、検察庁、警察またはそれに準じた権限を有する機関から、個人情報の提供を求められた場合
          </li>
        </ul>
      </v-card-text>
      <v-card-title>第5条（個人情報の開示・訂正・削除等について）</v-card-title>
      <v-card-text>
        当社は、ご本人が個人情報の開示、内容の訂正、追加、削除、利用停止および第三者への個人情報の提供の停止（以下「開示等」）などを希望された場合、ご本人であることを確認した上で、合理的な範囲において速やかに対応いたします。ただし、法令等に基づき保有している個人情報については、ご要望に応じられない場合があります。また、開示等のお問い合わせに伴い取得した個人情報は、その手続きを実施するために必要な範囲内でのみ取り扱うものとします。
      </v-card-text>
      <v-card-title>第6条（個人情報の漏えい・毀損の防止）</v-card-title>
      <v-card-text>
        当社は、個人情報の適切な管理・保護のため、コンピューターシステムの情報セキュリティ対策（セキュリティシステムの導入、アクセス権限者の限定、パスワードの定期的変更等）、機密書類の適切な廃棄を実施し、個人情報の紛失、漏えい、毀損の防止に努めます。この管理体制について、社内点検または外部機関の指摘等により不備が発見された場合は、直ちに是正および予防措置を取り再発防止に努めます。
      </v-card-text>
      <v-card-title>第7条（プライバシーポリシーの変更）</v-card-title>
      <v-card-text>
        本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ご本人に通知することなく、変更することができるものとします。当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
      </v-card-text>
      <v-card-title>第8条（お問い合わせ窓口）</v-card-title>
      <v-card-text>
        本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
        <br />
        <br />

        <v-btn
          href="https://mastervisions.co.jp"
          target="_blank"
          variant="plain"
          color="primary"
          class="pa-0"
        >
          MasterVisions株式会社
        </v-btn>
        <br />
        <br />
        ■住所
        <br />
        <address>
          〒141-0022
          <br />
          東京都品川区東五反田5-22-38
          <br />
          第二山崎ビル3F
        </address>
        <br />
        ■Eメールアドレス
        <br />
        <v-btn href="mailto:info@mastervisions.co.jp" variant="plain" color="primary" class="pa-0">
          info@mastervisions.co.jp
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup></script>

<style lang="scss"></style>
