<template>
  <v-container>
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="検証コード入力" color="indigo" />
      <v-form ref="form" class="pa-8" @submit.prevent="confirm()">
        <p class="mb-8">
          入力いただいた「メールアドレス」宛に「検証コード」を送信しました。
          <br />
          以下の入力欄から「検証コード」を入力して「送信」してください。
        </p>
        <p>メールアドレス</p>
        <p class="mt-1">
          {{ mailAddress }}
        </p>
        <p class="mt-3">ユーザー名</p>
        <p class="mt-1">
          {{ userName || '未入力' }}
        </p>
        <v-text-field v-model="code" :rules="codeRules" class="mt-8 mb-4">
          <template #label>
            検証コード
            <span style="color: red">*</span>
          </template>
        </v-text-field>

        <v-row class="justify-center mt-4">
          <v-col cols="auto">
            <v-btn
              class="ml-1"
              varient="outlined"
              size="large"
              min-width="120"
              @click="$router.back()"
            >
              戻る
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" class="ml-1" size="large" min-width="120" type="submit">
              送信
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { LocationQueryValue, useRoute, useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import { Auth } from 'aws-amplify'
import axios from '@/common/axios'
import { useProgressDialogStore } from '@/stores/dialog/progress'

const route = useRoute()
const router = useRouter()

const progressStore = useProgressDialogStore()

const code = ref<string>('')
const mailAddress = ref<string>('')
const userName = ref<string>('')
const password = ref<string>('')
const codeRules = ref<Array<(_v: string) => string | boolean>>([
  v => !!v || '検証コードを入力してください',
  v => /^\d*$/.test(v) || '検証コードが正しい形式ではありません'
])
const hasError = ref<boolean>(false)
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')

onMounted(async () => {
  mailAddress.value =
    typeof route.query.mailAddress === 'string' ? route.query.mailAddress.replace(' ', '+') : ''
  userName.value = typeof route.query.userName === 'string' ? route.query.userName : ''
  password.value = typeof route.query.password === 'string' ? route.query.password : ''
})

const confirm = async (): Promise<void> => {
  progressStore.setLoading(true)
  try {
    await Auth.confirmSignUp(mailAddress.value, code.value)
    await Auth.signIn(mailAddress.value, password.value)

    type usersPostType = {
      userToken: LocationQueryValue | LocationQueryValue[]
      userName?: string
    }
    const userPostValue: usersPostType = {
      userToken: route.query.userToken
    }

    if (userName.value) userPostValue.userName = userName.value
    await axios.post('/users', userPostValue)
    await Auth.currentAuthenticatedUser()
    router.push({ path: '/' })
  } catch (error: any) {
    hasError.value = true
    switch (error.code) {
      case 'LimitExceededException': // コードを間違え続けた場合に起こる。
        errorTitle.value = '検証に失敗しました'
        errorMessage.value =
          '検証コードを一定回数間違えたためロックされました。しばらく時間をおいてもう一度お試しください。'
        break
      case 'ExpiredCodeException': // コードが期限切れ（1時間をオーバー）した場合に起こる。
        errorTitle.value = '検証コードの有効期限が切れました'
        errorMessage.value =
          '検証コードの有効期限が切れました。メールアドレス入力からやり直してください。'
        break
      default:
        errorTitle.value = '検証に失敗しました'
        errorMessage.value = '入力内容をご確認の上、再度お試しください。'
    }
  }
  progressStore.setLoading(false)
}
</script>
