<template>
  <v-dialog v-model="props.show" width="400" @click:outside="closeDialog">
    <v-card v-if="url" color="white" class="mt-12" min-width="300" max-width="940">
      <v-toolbar :title="title" color="indigo" />
      <v-card-text class="pa-8">
        <CopiableTextField variant="outlined" label="サイトURL" disabled :value="url" />
        <CopiableTextField
          variant="outlined"
          label="担当者メールアドレス"
          disabled
          :value="email"
        />
        <vue-qrcode id="qrcode" class="qr-code" :value="url" tag="img" />
        <v-btn block color="black" size="large" variant="flat" @click="download()">
          ダウンロード
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import VueQrcode from '@chenfengyuan/vue-qrcode'

// Props
const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  url: {
    type: String,
    required: false,
    default: ''
  },
  email: {
    type: String,
    required: false,
    default: ''
  }
})

// Emit
const emit = defineEmits(['close'])

// Methods
const closeDialog = () => {
  emit('close')
}
const download = () => {
  const qrImage: any = document.querySelector('#qrcode')
  const a = document.createElement('a')
  if (!qrImage) return
  a.href = qrImage.src
  a.download = `QR_${props.title}.png`
  a.click()
}
</script>

<style lang="scss">
.qr-code {
  width: 100%;
}
</style>
