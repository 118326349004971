<template>
  <v-dialog v-model="isError" persistent min-width="300" max-width="400">
    <v-card>
      <v-toolbar :title="title" color="red" />
      <v-card-text class="pt-8 pb-4 ps-4">
        {{ text }}
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer />
        <v-btn color="grey" variant="flat" size="large" @click="onClose()">
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          v-if="showPrimaryButton"
          color="primary"
          variant="flat"
          size="large"
          @click="onClick()"
        >
          {{ primaryButtonText }}
        </v-btn>
        <v-btn
          v-if="showSecondaryButton"
          color="teal"
          variant="flat"
          size="large"
          @click="onClickSecondary"
        >
          {{ secondaryButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  error: {
    type: Boolean,
    required: false,
    default: false
  },
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  closeButtonText: {
    type: String,
    required: false,
    default: '閉じる'
  },
  primaryButtonText: {
    type: String,
    required: false,
    default: ''
  },
  secondaryButtonText: {
    type: String,
    required: false,
    default: ''
  },
  showPrimaryButton: {
    type: Boolean,
    required: false,
    default: false
  },
  showSecondaryButton: {
    type: Boolean,
    required: false,
    default: false
  }
})

const isError = computed(() => {
  return props.error
})

interface Emits {
  (_event: 'close', _val: false): void
  (_event: 'clickPrimary', _val: false): void
  (_event: 'clickSecondary', _val: false): void
}
const emit = defineEmits<Emits>()
const onClose = () => {
  emit('close', false)
}
const onClick = () => {
  emit('clickPrimary', false)
}
const onClickSecondary = () => {
  emit('clickSecondary', false)
}
</script>

<style lang="scss"></style>
