<template>
  <div class="container">
    <div class="container mb-4">
      <div class="d-flex align-center mb-4">
        <v-btn v-if="calibrationExists" icon="mdi-auto-fix" variant="plain" @click="addAuto" />
        <v-spacer />
        <div class="custom-pulldown mr-4 d-flex">
          <select v-if="localContent.streamingData" v-model="selectedSource">
            <option value="">&lt;Empty&gt;</option>
            <option v-for="(source, i) in localContent.streamingData.sources" :key="i" :value="i">
              {{
                getBasename(source.url || source.fileName) +
                (source.type === 'tiled' ? `[0:${source.tileX * source.tileY}]` : '')
              }}
            </option>
          </select>
        </div>
        <v-btn class="mr-4" text="最初に追加" size="small" @click="addVideo('top')" />
        <v-btn class="mr-4" text="最後に追加" size="small" @click="addVideo('bottom')" />
        <v-btn class="mr-4" text="キャリブレーション更新" size="small" @click="updateTransforms" />

        <v-btn size="small" color="red" icon="mdi-trash-can" @click="resetVideo" />
      </div>
    </div>
    <v-table class="mb-0 track-list-table">
      <thead>
        <tr>
          <th>Track No</th>
          <th>Tiled</th>
          <th>Single</th>
          <th colspan="2">Transform</th>
        </tr>
      </thead>
      <tbody v-if="localContent.streamingData">
        <tr
          v-for="(videoTrack, i) in [...localContent.streamingData.videos, null]"
          :key="i"
          class="transition-padding"
          :class="{
            'pb-6 is-drag-over': isDragging && dragOverId === i,
            'is-dragged': isDragging && draggingId === i
          }"
          draggable="true"
          @dragover="dragOver($event, i)"
          @dragend="dragEnd"
          @dragstart="dragStart(i)"
          @drop="drop(i)"
        >
          <template v-if="!videoTrack">
            <td />
            <td />
            <td />
            <td />
            <td />
          </template>
          <template v-else>
            <td>{{ i }}</td>
            <td>
              <div class="custom-pulldown">
                <select
                  :value="
                    videoTrack.tiled &&
                    videoTrack.tiled.sourceId + ':' + videoTrack.tiled.tilePosition
                  "
                  @change="changeVideo('tiled', videoTrack, $event.target.value)"
                >
                  <option value="">None</option>
                  <option v-for="(s, i) in tiledSources" :key="i" :value="s.id + ':' + s.pos">
                    {{ getBasename(s.title) }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <div class="custom-pulldown">
                <select
                  :value="videoTrack.single ? videoTrack.single.sourceId : ''"
                  @change="changeVideo('single', videoTrack, $event.target.value)"
                >
                  <option value="">None</option>
                  <option v-for="(s, i) in singleSources" :key="i" :value="s.id">
                    {{ getBasename(s.title) }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <div class="custom-pulldown">
                <select
                  :value="videoTrack.transformId"
                  @change="changeTransformId(videoTrack, $event.target.value)"
                >
                  <option value="">None</option>
                  <option v-for="(_, id) in [...Array(256).map((v, i) => i)]" :key="id" :value="id">
                    {{
                      id + (transformIds.indexOf(id) > -1 ? ': Transform[' + String(id) + ']' : '')
                    }}
                  </option>
                </select>
              </div>
            </td>
            <td>
              <v-btn
                size="small"
                icon="mdi-trash-can"
                variant="plain"
                color="red"
                @click="removeVideo(i)"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, PropType } from 'vue'
import { MediaSource, VideoTrack } from '@2501world/lightplayer-types'
import { createIdsMap } from '../../lib/Draggable'
import { EditorContentLoader } from '../../lib/ContentsLoader'
import { EditableContentFormat } from '../../types/EditableContentFormat'
import { basename } from '../../utils/OtherUtils'

interface FilteredSources {
  title: string
  id: number
  source: MediaSource
  pos: number
}

let sharedTransformIds: number[] = []

const props = defineProps({
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)
const isDragging = ref(false)
const dragOverId = ref(-1)
const draggingId = ref(-1)
const selectedSource = ref('')
const transformIds = ref(sharedTransformIds as number[])
const calibrationExists = ref(true)

onMounted(async () => {
  if (transformIds.value.length === 0) {
    updateTransforms()
  }
})

const singleSources = computed((): FilteredSources[] => {
  if (localContent.value.streamingData && !localContent.value.streamingData.sources) {
    return []
  }
  if (localContent.value.streamingData) {
    return localContent.value.streamingData.sources
      .map((s, i) => {
        return {
          title: getBasename(s.url || s.fileName),
          id: i,
          source: s,
          pos: 0
        }
      })
      .filter(s => {
        return s.source.type === 'single' && s.source.mediaType === 'video'
      })
  } else {
    return []
  }
})
const tiledSources = computed((): FilteredSources[] => {
  if (!localContent.value.streamingData?.sources) {
    return []
  }
  if (localContent.value.streamingData) {
    const sources: FilteredSources[] = []
    localContent.value.streamingData.sources
      .map((s, i) => {
        return {
          title: getBasename(s.url || s.fileName),
          id: i,
          source: s,
          pos: 0
        }
      })
      .forEach(s => {
        if (s.source.type === 'tiled') {
          const frames = s.source.tileX * s.source.tileY

          for (let i = 0; i < frames; i++) {
            const copy = Object.assign({}, s)
            copy.title += `:${i}`
            copy.pos = i
            sources.push(copy)
          }
        }
      })
    return sources
  } else {
    return []
  }
})

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)

interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()
const change = () => {
  emit('change', localContent.value)
}
const updateTransforms = async () => {
  let newTransformIds = [
    ...Array.from({ length: localContent.value.streamingData?.videos?.length ?? 0 }).map(
      (_, i) => i
    )
  ]
  if (localContent.value.streamingData?.transforms[0]) {
    try {
      const trSet = await EditorContentLoader.resolveTransformInput(
        localContent.value.streamingData.transforms[0]
      )

      newTransformIds = trSet.transforms
        .map((item, i) => {
          return item.isEmpty ? null : i
        })
        .filter(item => item !== null) as number[]
      transformIds.value = newTransformIds
      sharedTransformIds = newTransformIds
    } catch {
      calibrationExists.value = false
    }
  }
}
const changeTransformId = (video: VideoTrack, value: string) => {
  video.transformId = +value
  change()
}
const changeVideo = (type: 'tiled' | 'single', videoTrack: VideoTrack, value: string) => {
  if (value === '') {
    videoTrack[type] = null
    change()
    return
  }

  if (type === 'tiled') {
    const items = value.split(':').map(v => +v)
    videoTrack['tiled'] = {
      sourceId: items[0],
      tilePosition: items[1]
    }
  } else if (type == 'single') {
    videoTrack['single'] = {
      sourceId: +value,
      tilePosition: 0
    }
  }

  change()
}
const removeVideo = (i: number) => {
  if (localContent.value.streamingData && !localContent.value.streamingData.videos) {
    localContent.value.streamingData.videos = []
  }
  localContent.value.streamingData?.videos.splice(i, 1)
  change()
}
const addVideo = (insert: 'top' | 'bottom') => {
  if (localContent.value.streamingData && !localContent.value.streamingData.videos) {
    localContent.value.streamingData.videos = []
  }

  const id = selectedSource.value
  const tracks: VideoTrack[] = []

  if (
    id !== '' &&
    localContent.value.streamingData?.sources &&
    localContent.value.streamingData?.sources[Number(id)]
  ) {
    const source = localContent.value.streamingData.sources[+id]
    if (source.type === 'single') {
      tracks.push({
        single: { sourceId: +id, tilePosition: 0 },
        tiled: null
      } as any)
    } else if (source.type === 'tiled') {
      const tileCount = source.tileX * source.tileY
      for (let i = 0; i < tileCount; i++) {
        tracks.push({
          single: null,
          tiled: { sourceId: +id, tilePosition: i }
        } as any)
      }
    }
  } else {
    tracks.push({ single: null, tiled: null } as any)
  }

  if (insert === 'top' && localContent.value.streamingData) {
    localContent.value.streamingData.videos.splice(0, 0, ...tracks)
  } else {
    if (localContent.value.streamingData) {
      localContent.value.streamingData.videos.splice(
        localContent.value.streamingData.videos.length,
        0,
        ...tracks
      )
    }
  }
  change()
}
const addAuto = async () => {
  if (localContent.value.streamingData && !localContent.value.streamingData.sources) {
    localContent.value.streamingData.sources = []
  }
  if (localContent.value.streamingData && !localContent.value.streamingData.videos) {
    localContent.value.streamingData.videos = []
  }
  if (localContent.value.streamingData && localContent.value.streamingData.videos.length > 0) {
    if (!confirm('設定が上書きされますがよろしいですか?')) {
      return
    }
  }

  await updateTransforms()

  const tracks: VideoTrack[] = []
  for (let i = 0; i < tiledSources.value.length; i++) {
    const s = tiledSources.value[i]
    tracks.push({
      single: null,
      tiled: { sourceId: s.id, tilePosition: s.pos },
      transformId: transformIds.value[i] ?? undefined
    } as any)
  }

  for (let i = 0; i < singleSources.value.length; i++) {
    const s = singleSources.value[i]
    if (tracks[i]) {
      tracks[i]['single'] = { sourceId: s.id, tilePosition: 0 }
    } else {
      tracks.push({
        single: { sourceId: s.id, tilePosition: 0 },
        tiled: null,
        transformId: transformIds.value[i] ?? undefined
      } as any)
    }
  }

  if (localContent.value.streamingData) localContent.value.streamingData.videos = tracks
  change()
}
const resetVideo = () => {
  if (confirm('トラック設定をすべてリセットしますか?') && localContent.value.streamingData) {
    localContent.value.streamingData.videos = []
    change()
  }
}
const drop = (i: number) => {
  if (localContent.value.streamingData && !localContent.value.streamingData.videos) {
    localContent.value.streamingData.videos = []
  }

  const from = draggingId.value
  const to = Math.min(
    i > draggingId.value ? i - 1 : i,
    localContent.value.streamingData ? localContent.value.streamingData.videos.length - 1 : 0
  )
  if (from === to) {
    return
  }

  // 各種トラックのIDを入れ替えるためのマップを作成 (新ID = idsMap[旧ID])
  const idMap = createIdsMap(
    localContent.value.streamingData
      ? localContent.value.streamingData.videos.map((s, i) => i)
      : [],
    from,
    to
  )

  if (localContent.value.streamingData) {
    const source = localContent.value.streamingData.videos.splice(draggingId.value, 1)
    localContent.value.streamingData.videos.splice(to, 0, ...source)

    localContent.value.streamingData.groups?.forEach(g => {
      g.videoIds = g.videoIds.map(id => idMap[id])
      g.defaultVideoId = idMap[g.defaultVideoId]
    })
  }

  change()
}
const dragOver = (e: { preventDefault: () => void }, i: number) => {
  dragOverId.value = i
  e.preventDefault()
}
const dragStart = (i: number) => {
  dragOverId.value = -1
  draggingId.value = i
  isDragging.value = true
}
const dragEnd = () => {
  dragOverId.value = -1
  isDragging.value = false
}
const getBasename = (p: string) => {
  return basename(p)
}
</script>

<style scoped lang="scss">
.is-drag-over {
  border-bottom: 4px solid #39f3fa;
}

.is-dragged {
  opacity: 0.5;
}

.transition-padding {
  transition: padding-bottom 0.1s;
  padding-bottom: 4px;
}

.custom-pulldown {
  border: 1px solid #ccc;
  position: relative;
  cursor: pointer;

  select {
    width: 100%;
    padding: 0 24px 0 8px;
    font-size: 14px;
    height: 32px;
    cursor: pointer;
  }

  &:after {
    content: '▼';
    display: block;
    color: #333;
    font-size: 12px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>

<style lang="scss">
.track-list-table table {
  border-collapse: collapse !important;
}
</style>
