import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useClipStore = defineStore(
  'clip',
  () => {
    // State
    const uploadFiles = ref<Array<File>>()
    const isDescClips = ref<boolean>(true)

    // Actions
    const setUploadFiles = (files: Array<File>) => {
      uploadFiles.value = files
    }
    const clearUploadFiles = () => {
      uploadFiles.value = undefined
    }
    const setIsDescClips = (flag: boolean) => {
      isDescClips.value = flag
    }

    return {
      uploadFiles,
      setUploadFiles,
      clearUploadFiles,
      isDescClips,
      setIsDescClips
    }
  },
  {
    persist: true
  }
)
