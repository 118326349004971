export const basename = (path: string): string => {
  // pathを検証
  if (!path) {
    return ''
  }
  // pathの末尾がスラッシュであれば削除
  if (path.endsWith('/')) {
    path = path.slice(0, -1)
  }
  // URLから最後のパートを抽出
  const urlParts = path.split('/')
  return urlParts[urlParts.length - 1]
}

// 視点数と総タイル数から1タイルあたりのサイズを算出
export const calcTileSize = (viewPoints: number, tileCount: number) => {
  const val = Math.ceil(viewPoints / tileCount)
  if (val <= 2) {
    return {
      tileX: 2,
      tileY: 1
    }
  } else if (val <= 4) {
    return {
      tileX: 2,
      tileY: 2
    }
  } else if (val <= 6) {
    return {
      tileX: 3,
      tileY: 2
    }
  } else if (val <= 9) {
    return {
      tileX: 3,
      tileY: 3
    }
  } else if (val <= 12) {
    return {
      tileX: 4,
      tileY: 3
    }
  } else if (val <= 16) {
    return {
      tileX: 4,
      tileY: 4
    }
  } else {
    throw new Error('Invalid viewPoints or tileCount')
  }
}
