<template>
  <div class="container">
    <v-text-field
      v-model="sourceUrl"
      label="キャリブレーションYAML (変換用URL)"
      placeholder="Transform YAML/JSON URL"
      class="mb-8"
      color="primary"
      variant="solo"
      hide-details
    >
      <template #append>
        <v-btn icon="mdi-plus" color="primary" :disabled="!sourceUrl" @click="add" />
      </template>
    </v-text-field>
    <v-card
      v-for="(transform, i) in [...localContent.streamingData.transforms]"
      :key="i"
      class="pa-8 mt-4 pt-4"
    >
      <div v-if="localContent.streamingData.transforms[i]">
        <div class="d-flex mb-4">
          <div class="d-flex align-center">
            <v-icon icon="mdi-vector-square-close" class="mr-4" />
            {{ transform?.name || `[Untitled Transform ${i}]` }}
          </div>
          <v-spacer />
          <v-btn icon="mdi-trash-can" variant="plain" color="red" @click="remove(i)" />
        </div>
        <v-text-field
          v-model="transform.name"
          label="名前"
          color="primary"
          variant="underlined"
          placeholder="Source Name"
          draggable="false"
          @change="change()"
        />
        <div v-if="Array.isArray(transform?.transforms)" class="text-h7">
          ※RAW配列データのため非表示
        </div>
        <v-text-field
          v-model="transform.transforms.url"
          :label="localContent.clipId ? 'URL' : 'ファイル名'"
          placeholder="Source URL"
          color="primary"
          variant="underlined"
          draggable="false"
          hide-details
          @change="change()"
        />
      </div>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, PropType } from 'vue'
import { EditableContentFormat } from '../../types/EditableContentFormat'

const props = defineProps({
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<EditableContentFormat>(props.content)
const sourceUrl = ref('')

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)

interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()
const change = () => {
  emit('change', localContent.value)
}
const add = () => {
  if (!sourceUrl.value) {
    return
  }

  const src = sourceUrl.value as string
  sourceUrl.value = ''

  localContent.value.streamingData.transforms.push({
    name: '',
    transforms: {
      url: src
    },
    url: ''
  })
  change()
}
const remove = (i: number) => {
  localContent.value.streamingData.transforms.splice(i, 1)
  change()
}
</script>

<style scoped lang="scss">
.is-drag-over {
  border-top: 4px solid #7e57c2;
}

.is-dragged {
  opacity: 0.5;
}

.transition-padding {
  transition: padding-top 0.1s;
}
</style>
