import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSnackbarStore = defineStore('snackbar', () => {
  // State
  const showSnackbar = ref<boolean>(false)
  const snackbarText = ref<string>('')
  const snackbarTime = ref<number>(3000)

  // Actions
  const setShowSnackbar = (bool: boolean) => {
    showSnackbar.value = bool
  }
  const setSnackbarText = (text: string) => {
    snackbarText.value = text
  }
  const setSnackbarTime = (time: number) => {
    snackbarTime.value = time
  }

  return {
    showSnackbar,
    snackbarText,
    snackbarTime,
    setShowSnackbar,
    setSnackbarText,
    setSnackbarTime
  }
})
