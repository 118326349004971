<template>
  <div class="container">
    <div class="text-h7 mb-2">グループ選択</div>
    <div class="d-flex align-center mb-8">
      <div class="custom-pulldown">
        <select v-model="groupIndex" @change="change()">
          <option v-for="(group, i) in localContent.streamingData.groups" :key="i" :value="i">
            Group: {{ i }}
          </option>
        </select>
      </div>
      <v-btn text="追加" class="ml-4 mr-2" color="primary" size="small" @click="addGroup" />
      <v-btn text="削除" color="red" size="small" @click="removeGroup(groupIndex)" />
    </div>

    <div class="text-h7 mb-2">グループ設定</div>

    <label class="label" />
    <div class="field is-grouped">
      <div class="control">
        <v-text-field
          v-model="activeGroup.name"
          label="表示名"
          color="primary"
          variant="underlined"
          hide-details
          @change="change()"
        />
      </div>
    </div>
    <div class="field is-grouped">
      <v-checkbox
        v-model="activeGroup.cyclic"
        type="checkbox"
        label="ループ"
        color="primary"
        hide-details
        @change="change()"
      />
      <div class="text-h7 mb-2">デフォルト画面</div>
      <div class="custom-pulldown mb-8" style="max-width: 30%">
        <select v-model="activeGroup.defaultVideoId" @change="change()">
          <option v-for="(id, i) in activeGroup.videoIds" :key="i" :value="id">Tr. {{ id }}</option>
        </select>
      </div>
    </div>

    <div class="text-h7">トラック配置</div>

    <div class="container mb-4">
      <div class="field is-grouped">
        <div class="d-flex align-center mb-4">
          <v-btn icon="mdi-auto-fix" variant="plain" @click="addAuto" />
          <div class="custom-pulldown ml-4">
            <select v-model="selectedTrack">
              <option value="">&lt;Empty&gt;</option>
              <option
                v-for="(videoTrack, i) in localContent.streamingData.videos"
                :key="i"
                :value="i"
              >
                Track {{ videoTrack }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex align-center mb-8">
          <v-btn text="最初に追加" size="small" @click="addVideo('top')" />
          <v-btn text="最後に追加" class="mr-4 ml-4" size="small" @click="addVideo('bottom')" />
          <v-btn text="順番を反転" size="small" @click="reverseVideo" />
          <v-spacer />
          <v-btn size="small" color="red" icon="mdi-trash-can" @click="resetVideo" />
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap" style="gap: 12px">
      <v-card
        v-for="(id, i) in [...activeGroup.videoIds, null]"
        :key="i"
        :class="{
          'pl-3 is-drag-over': isDragging && dragOverId === i,
          'is-dragged': isDragging && draggingId === i
        }"
        color="primary"
        draggable="true"
        @dragend="dragEnd(i)"
        @dragover="dragOver($event, i)"
        @dragstart="dragStart(i)"
        @drop="drop(i)"
      >
        <template v-if="id === null">
          <div class="tags has-addons" />
        </template>
        <template v-else>
          <div class="d-flex align-center pa-1 pl-2">
            <v-icon icon="mdi-video" size="x-small" />
            <span class="text-caption ml-2 mr-1">Track&nbsp;:&nbsp;{{ id }}</span>
            <v-btn size="x-small" variant="plain" icon="mdi-close" @click="removeVideo(i)" />
          </div>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed, PropType } from 'vue'
import { VideoGroup } from '@2501world/lightplayer-types'
import { EditableContentFormat } from '../../types/EditableContentFormat'
import { createIdsMap } from '../../lib/Draggable'

const props = defineProps({
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)
const isDragging = ref(false)
const dragOverId = ref(-1)
const draggingId = ref(-1)
const selectedTrack = ref('')
const groupIndex = ref()

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)

const activeGroup = computed((): VideoGroup => {
  if (!localContent.value.streamingData.groups) {
    return {}
  }

  return localContent.value.streamingData.groups[groupIndex.value]
})

interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()
const change = () => {
  emit('change', localContent.value)
}
const addAuto = () => {
  if (!localContent.value.streamingData.videos) {
    localContent.value.streamingData.videos = []
  }
  if (activeGroup.value.videoIds.length > 0) {
    if (!confirm('設定が上書きされますがよろしいですか?')) {
      return
    }
  }

  activeGroup.value.videoIds = localContent.value.streamingData.videos.map((v, i) => i)
  activeGroup.value.defaultVideoId = Math.floor(localContent.value.streamingData.videos.length / 2)
  change()
}
const addGroup = () => {
  if (localContent.value.streamingData.groups) {
    localContent.value.streamingData.groups.push({
      videoIds: [],
      defaultVideoId: 0,
      cyclic: true,
      isDefault: true
    })
  }
}
const removeGroup = (i: number) => {
  if (!confirm('グループを削除しますか？')) {
    return
  }

  if (localContent.value.streamingData.groups) {
    if (localContent.value.streamingData.groups.length <= 1) {
      alert('Groupは1個以上必要です')
      return
    }

    localContent.value.streamingData.groups.splice(i, 1)
    if (
      groupIndex.value >= localContent.value.streamingData.groups.length &&
      localContent.value.streamingData.groups.length !== 0
    ) {
      groupIndex.value = localContent.value.streamingData.groups.length - 1
    }
  }
}
const addVideo = (insert: 'top' | 'bottom') => {
  const id = +selectedTrack.value || 0
  if (insert === 'top') {
    activeGroup.value.videoIds.unshift(id)
  } else {
    activeGroup.value.videoIds.push(id)
  }
}
const resetVideo = () => {
  if (confirm('ビデオ設定をすべてリセットしますか?')) {
    activeGroup.value.videoIds = []
    change()
  }
}
const reverseVideo = () => {
  activeGroup.value.videoIds.reverse()
  change()
}
const removeVideo = i => {
  activeGroup.value.videoIds.splice(i, 1)
  change()
}
const drop = i => {
  if (!activeGroup.value.videoIds) {
    activeGroup.value.videoIds = []
  }

  const from = draggingId.value
  const to = Math.min(i > draggingId.value ? i - 1 : i, activeGroup.value.videoIds.length - 1)
  if (from === to) {
    return
  }

  // 各種トラックのIDを入れ替えるためのマップを作成 (新ID = idsMap[旧ID])
  const idMap = createIdsMap(
    activeGroup.value.videoIds.map((s, i) => i),
    from,
    to
  )

  const source = activeGroup.value.videoIds.splice(draggingId.value, 1)
  activeGroup.value.videoIds.splice(to, 0, ...source)
  activeGroup.value.defaultVideoId = idMap[activeGroup.value.defaultVideoId]

  change()
}
const dragOver = (e, i) => {
  dragOverId.value = i
  e.preventDefault()
}
const dragStart = i => {
  dragOverId.value = -1
  draggingId.value = i
  isDragging.value = true
}
const dragEnd = () => {
  dragOverId.value = -1
  isDragging.value = false
}

const onCreated = () => {
  if (localContent.value.streamingData.groups?.length === 0) {
    localContent.value.streamingData.groups = [
      { videoIds: [], defaultVideoId: 0, cyclic: true, isDefault: true }
    ]
    change()
  }

  if (
    !localContent.value.streamingData.groups ||
    localContent.value.streamingData.groups.length === 0
  ) {
    localContent.value.streamingData.groups = []
    addGroup()
  }
  groupIndex.value = localContent.value.streamingData.groups.findIndex(g => g.isDefault) ?? 0
}
onCreated()
</script>

<style lang="scss" scoped>
.is-drag-over {
  border-left: 4px solid #39f3fa;
  border-bottom: 0;
}

.is-dragged {
  opacity: 0.5;
}

.transition-padding {
  transition: padding-left 0.1s;
}

.custom-pulldown {
  border: 1px solid #ccc;
  position: relative;
  cursor: pointer;

  select {
    width: 100%;
    padding: 0 24px 0 8px;
    font-size: 14px;
    height: 32px;
    cursor: pointer;
  }

  &:after {
    content: '▼';
    display: block;
    color: #333;
    font-size: 12px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
