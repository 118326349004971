import { Auth, Hub } from 'aws-amplify'
import { defineStore } from 'pinia'
import { ref, reactive, computed } from 'vue'
import axios from '@/common/axios'

type Subscription = any
type User = {
  userName: string
  email: string
  subscriptions: Array<Subscription>
}
const userTemplate: User = {
  userName: '',
  email: '',
  subscriptions: []
}

export const useAuthStore = defineStore(
  'auth',
  () => {
    // State
    let auth = reactive<any>({ signedIn: false })
    let user = reactive<User>(userTemplate)
    const activeTenant = ref<string | null>('')
    const activeProject = ref<string | null>('')

    // Getters
    const signedIn = computed((): boolean => auth.signedIn)
    const activeSubscription = computed((): Subscription | undefined => {
      return user.subscriptions.find(
        s => s.tenantId === activeTenant.value && s.projectId === activeProject.value && s.isEnabled
      )
    })
    const enabledSubscriptions = computed((): any => user.subscriptions.filter(s => s.isEnabled))

    // Actions
    const setUser = (inputUser: User): void => {
      Object.assign(user, inputUser)
    }
    const setSignedIn = (signedIn: boolean): void => {
      auth.signedIn = signedIn
    }
    const setActiveTenant = (tenantId: string | null): void => {
      activeTenant.value = tenantId
    }
    const setActiveProject = (projectId: string | null): void => {
      activeProject.value = projectId
    }
    const fetchAuth = async (): Promise<any> => {
      try {
        const auth: any = await Auth.currentAuthenticatedUser()
        setSignedIn(true)
        return auth
      } catch (e) {
        setSignedIn(false)
        return Promise.reject(e)
      }
    }
    const fetchUser = async (): Promise<User | undefined> => {
      try {
        const auth: any = await fetchAuth()

        if (!auth) throw new Error()

        const res: any = await axios.get(`/users/${auth.username}`)

        const subscriptions: Array<Subscription> = res.data.subscriptions

        if (subscriptions.length === 1) {
          await selectSubscription(subscriptions[0].tenantId, subscriptions[0].projectId)
        }

        const user: User = {
          userName: res.data.userName,
          email: res.data.email,
          subscriptions
        }

        setUser(user)
        return user
      } catch (e) {
        setUser(userTemplate)
        return Promise.reject(e)
      }
    }
    const selectSubscription = (tenantId: string, projectId: string): void => {
      if (tenantId && projectId) {
        setActiveTenant(tenantId)
        setActiveProject(projectId)
      } else {
        setActiveTenant(null)
        setActiveProject(null)
      }
    }

    const signOut = () => {
      auth = { signedIn: false }
      user = userTemplate
      localStorage.removeItem('auth')
    }

    return {
      auth,
      user,
      activeTenant,
      activeProject,
      signedIn,
      activeSubscription,
      enabledSubscriptions,
      setUser,
      setSignedIn,
      setActiveTenant,
      setActiveProject,
      fetchAuth,
      fetchUser,
      selectSubscription,
      signOut
    }
  },
  {
    persist: true
  }
)

Hub.listen('auth', async ({ payload: { event } }) => {
  switch (event) {
    case 'signIn':
    case 'autoSignIn':
    case 'signOut':
    case 'userDeleted':
      await useAuthStore().fetchUser()
      break
    default:
      break
  }
})
