import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useProgressDialogStore = defineStore('progressDialog', () => {
  // State
  const loading = ref<boolean>(false)
  const loadingText = ref<string>('処理中')

  // Actions
  const setLoading = (bool: boolean) => {
    loading.value = bool
  }

  const setLoadingText = (text: string) => {
    loadingText.value = text
  }

  return {
    loading,
    loadingText,
    setLoading,
    setLoadingText
  }
})
