import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
// Pinia
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Amplify読み込み
import { Amplify } from 'aws-amplify'
import awsconfig from './common/aws-config-tenant'
// Vuetify
import { registerPlugins } from './plugins'
// GA4
import VueGtag from 'vue-gtag'
// Pinia
const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

registerPlugins(app)

Amplify.configure(awsconfig)

app
  .use(VueGtag, {
    config: { id: import.meta.env.VITE_APP_GA4_ID }
  })
  .use(router)
  .use(pinia)
  .mount('#app')
