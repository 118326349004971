<template>
  <v-container>
    <v-layout class="mb-8 d-flex align-center justify-space-between">
      <div v-if="tenant" class="d-flex align-center">
        <v-btn size="small" icon="mdi-chevron-left" @click="$router.push('/admin/tenants')" />
        <div class="text-h5 ml-4">
          {{ tenant.tenantName }}
        </div>
      </div>
      <v-spacer />
      <v-btn color="primary" @click="showCreateDialog = true">プロジェクト登録</v-btn>
    </v-layout>
    <v-card>
      <v-table>
        <thead>
          <tr>
            <th>プロジェクト名</th>
            <th>担当者名</th>
            <th>担当者メールアドレス</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="4">
              <v-row justify="center" class="pa-12">
                <v-progress-circular v-if="isLoading" indeterminate :size="32" />
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody v-if="items?.length == 0 && !isLoading">
          <tr>
            <td colspan="4" class="pa-12 text-center">プロジェクトがありません。</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading">
          <tr v-for="(item, index) in sliceItems" :key="index">
            <td
              style="max-width: 360px; min-width: 160px; overflow-wrap: anywhere"
              class="pt-2 pb-2"
            >
              {{ item.projectName }}
            </td>
            <td style="min-width: 120px">
              {{ item.userName }}
            </td>
            <td
              class="pt-2 pb-2"
              style="max-width: 300px; min-width: 200px; overflow-wrap: anywhere"
            >
              {{ item.email }}
            </td>
            <td width="66" class="pr-2">
              <v-btn
                color="primary"
                variant="outlined"
                @click=";(showEditDialog = true), (selectedItem = { ...item })"
              >
                表示
              </v-btn>
            </td>
            <td width="130" class="pl-0">
              <v-btn
                v-if="item.url"
                color="primary"
                @click=";(selectedQrItem = { ...item }), (showQrDialog = true)"
              >
                QRコード表示
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <!-- Pagination -->
    <div v-if="calculatedPage > 1" class="text-center mt-8">
      <v-pagination
        v-model="page"
        :length="calculatedPage"
        :total-visible="5"
        @update:model-value="changePage"
      />
    </div>
    <!-- Registration Modal -->
    <DialogTemplate
      v-if="tenant"
      :show="showCreateDialog"
      title="プロジェクト登録"
      close-button-text="キャンセル"
      primary-button-text="登録"
      :disabled="!isValidCreateProrjectForm"
      @close="showCreateDialog = false"
      @action="createProject"
    >
      <v-form v-model="isValidCreateProrjectForm" class="pa-8 pb-0">
        <v-text-field
          v-model="tenant.tenantName"
          variant="outlined"
          label="テナント名"
          disabled
          readonly
        />
        <v-text-field
          v-model="newProjectName"
          variant="outlined"
          label="プロジェクト名"
          :rules="projectNameRule"
          class="mb-2"
        />
        <v-text-field
          v-model="createItem.email"
          variant="outlined"
          label="担当者メールアドレス"
          :rules="mailAddressRules"
        />
      </v-form>
    </DialogTemplate>
    <!-- Edit Modal -->
    <DialogTemplate
      v-if="tenant"
      :show="showEditDialog"
      title="プロジェクト編集"
      close-button-text="キャンセル"
      primary-button-text="更新"
      :disabled="!isValidUpdateProrjectForm"
      @close="showEditDialog = false"
      @action="updateProject"
    >
      <v-form v-model="isValidUpdateProrjectForm" class="pa-8 pb-0">
        <CopiableTextField
          variant="outlined"
          label="テナント名"
          disabled
          :value="tenant.tenantName"
        />
        <CopiableTextField
          variant="outlined"
          label="プロジェクトID"
          disabled
          :value="selectedItem.projectId"
        />
        <v-text-field
          v-model="selectedItem.projectName"
          variant="outlined"
          label="プロジェクト名"
          :rules="projectNameRule"
          class="mb-4"
        />
        <CopiableTextField
          v-if="selectedItem.userName"
          variant="outlined"
          label="担当者名"
          disabled
          :value="selectedItem.userName"
        />
        <CopiableTextField
          v-if="selectedItem.userId"
          variant="outlined"
          label="担当者ユーザーID"
          disabled
          :value="selectedItem.userId"
        />
        <v-text-field
          v-if="!selectedItem.userId"
          v-model="selectedItem.email"
          variant="outlined"
          label="担当者メールアドレス"
          :rules="mailAddressRules"
          class="mb-4"
        />
        <CopiableTextField
          v-if="selectedItem.userId"
          variant="outlined"
          label="担当者メールアドレス"
          disabled
          :value="selectedItem.email"
        />
      </v-form>
    </DialogTemplate>

    <!-- QR Code Modal -->
    <AdminQRDialog
      :show="showQrDialog"
      :title="selectedQrItem?.projectName"
      :url="selectedQrItem?.url"
      :email="selectedQrItem?.email"
      @close="showQrDialog = false"
    />

    <!-- ErrorDialog -->
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { ref, reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import dayjs from '@/common/dayjs'
import { useUserStore } from '@/stores/admin/user'
import { useTenantStore } from '@/stores/admin/tenant'
import { useProjectStore } from '@/stores/admin/project'
import { useSnackbarStore } from '@/stores/snackbar'

const route = useRoute()
const router = useRouter()

const userStore = useUserStore()
const tenantStore = useTenantStore()
const projectStore = useProjectStore()
const snackbarStore = useSnackbarStore()

type itemType = {
  url?: string
  projectId: string
  projectName: string
  userId?: string
  userName?: string
  userToken?: string
  email: string
  createdAt: Date
}

// Data
const isLoading = ref<boolean>(false)
const showCreateDialog = ref<boolean>(false)
const showEditDialog = ref<boolean>(false)
const showQrDialog = ref<boolean>(false)
const selectedItem = ref<any>(null)
const tenant = ref<any>(null)
const hasError = ref<boolean>(false)
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')
const isValidCreateProrjectForm = ref<boolean>(false)
const isValidUpdateProrjectForm = ref<boolean>(false)
const createItem: any = reactive({
  tenantId: '',
  email: '',
  role: 'tenant'
})
const newProjectName = ref<string>('')
const selectedQrItem = ref<any>(null)
const page = ref<number>(1)
const projectNameRule = reactive([(v: string) => !!v || 'プロジェクト名を入力してください'])
const mailAddressRules = reactive([
  (v: string) => !!v || 'メールアドレスを入力してください',
  (v: string) => /.+@.+\..+/.test(v) || 'メールアドレスが正しい形式ではありません'
])

// Computed
const items = computed(() => {
  return projectStore.projects
    .map((p: itemType) => {
      const contributor: any = userStore
        .getUser()
        .find((u: { subscriptions: any[] }) =>
          u.subscriptions?.some((s: { projectId: any }) => s.projectId === p.projectId)
        )
      if (contributor) {
        return {
          projectId: p.projectId,
          projectName: p.projectName,
          userId: contributor.userId,
          userName: contributor.userName,
          email: contributor.email,
          createdAt: p.createdAt
        } as itemType
      }
      const token: any = userStore.userTokens.find(
        (t: { projectId: string }) => t.projectId === p.projectId
      )
      if (token) {
        return {
          projectId: p.projectId,
          projectName: p.projectName,
          userToken: token.userToken,
          email: token.email,
          url: token.url,
          createdAt: p.createdAt
        } as itemType
      }
      return p
    })
    .sort((a: { createdAt: Date }, b: { createdAt: Date }) => {
      return dayjs(a.createdAt).format('YYYY-MM-DD HH:mm:ss') >
        dayjs(b.createdAt).format('YYYY-MM-DD HH:mm:ss')
        ? -1
        : 1
    })
})
const calculatedPage = computed(() => {
  return Math.ceil(items.value.length / 10)
})
const sliceItems = computed(() => {
  return items.value.slice((page.value - 1) * 10, page.value * 10)
})

// OnMounted
onMounted(async () => {
  isLoading.value = true
  // URLからtenandIdを取得
  tenantStore.putTenantID(String(route.params.id))
  // URLからページ情報を取得
  page.value = route.query.page ? Number(route.query.page) : 1

  try {
    // テナント一覧を取得して対象テナントを絞り込む
    await tenantStore.getTenants(tenantStore.tenantId, '')
    tenant.value = tenantStore.tenants.find(
      (data: { tenantId: any }) => data.tenantId === tenantStore.tenantId
    )
    // Projectの取得に時間かかるので並列で実行
    await Promise.all([
      projectStore.getProjects(tenantStore.tenantId),
      fetchUsers(),
      fetchUsersTokens()
    ])
  } catch (error) {
    hasError.value = true
    errorTitle.value = 'プロジェクトの取得に失敗しました'
    errorMessage.value = 'プロジェクトの取得に失敗しました。再度お試しください。'
  }
  isLoading.value = false
})

// Methods
const fetchUsers = async () => {
  createItem.tenantId = tenantStore.tenantId

  await userStore.getUsers(tenantStore.tenantId, 'tenant')
}
const fetchUsersTokens = async () => {
  userStore.getUserTokens(tenantStore.tenantId, 'tenant')
}
const createProject = async () => {
  try {
    await projectStore.postProject(newProjectName.value, tenantStore.tenantId)
    // テナントユーザー用
    await userStore.postUsersTokens(
      createItem.role,
      tenantStore.tenantId,
      projectStore.newProjectId,
      createItem.email
    )
    // 一般ユーザー用
    await userStore.postUsersTokens('user', tenantStore.tenantId, projectStore.newProjectId)

    showCreateDialog.value = false
    // 初期化
    createItem.email = ''
    newProjectName.value = ''
    // 登録処理後に再度取得処理を投げる
    await Promise.all([
      projectStore.getProjects(tenantStore.tenantId),
      fetchUsers(),
      fetchUsersTokens()
    ])
    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('プロジェクトを追加しました。')
  } catch (e) {
    hasError.value = true
    errorTitle.value = '登録に失敗しました'
    errorMessage.value = '入力内容をご確認の上、再度お試しください。'
  }
}
const updateProject = async () => {
  try {
    await Promise.all([
      projectStore.putProject(
        {
          projectId: selectedItem.value.projectId,
          projectName: selectedItem.value.projectName
        },
        tenantStore.tenantId
      ),
      selectedItem.value.userToken
        ? userStore.putUsersTokens(
            selectedItem.value.userToken,
            tenantStore.tenantId,
            selectedItem.value.projectId,
            'tenant',
            selectedItem.value.email
          )
        : undefined,
      !selectedItem.value.userToken && !selectedItem.value.userToken && selectedItem.value.email
        ? userStore.postUsersTokens(
            tenantStore.tenantId,
            selectedItem.value.projectId,
            selectedItem.value.email,
            'tenant'
          )
        : undefined
    ])
    showEditDialog.value = false
    await Promise.all([
      projectStore.getProjects(tenantStore.tenantId),
      fetchUsers(),
      fetchUsersTokens()
    ])
    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('プロジェクトを更新しました。')
  } catch (e) {
    hasError.value = true
    errorTitle.value = '更新に失敗しました'
    errorMessage.value = '入力内容をご確認の上、再度お試しください。'
  }
}

const changePage = (pageId: number) => {
  page.value = pageId
  router.push(`?page=${pageId}`)
}
</script>

<style lang="scss"></style>
