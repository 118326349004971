import axios, { AxiosRequestConfig } from 'axios'
import * as YAML from 'yaml'
import {
  DisplayMode,
  ExternalRef,
  LightPlayerContentFormat_v1_1,
  MediaSource,
  TransformSetInput,
  VideoGroup,
  VideoTransform
} from '@2501world/lightplayer-types'
import { StreamingData } from '../../types/EditableContentFormat'

interface AffineCalibrationV1Format {
  calibration_format: string
  bounding_box: number[]
  clockwise_distribution: number
  initial_viewpoint: number
  overwrap_area: number[]
  videos: {
    calibrated_video_abs_second: number
    calibrated_video_file: string
    calibrated_video_resolution: number[]
    calibration_point1: number[]
    calibration_point2: number[]
    id: 0
    rotation: number
    scale: number
    shift: number[]
  }[]
}

export type EditorVideoTransform = VideoTransform & { isEmpty?: boolean }
export type EditorTransformSet = {
  name: string
  transforms: EditorVideoTransform[]
}

function isExternalRef(item: any | ExternalRef | ExternalRef[]): item is ExternalRef {
  return (item as ExternalRef).url !== undefined
}

export class EditorContentLoader {
  static async conformContent(data: any): Promise<StreamingData> {
    if (data === null || data === undefined) {
      throw new Error('ERROR__CONTENT_IS_FALSY')
    }

    data.version = data.version ?? '1.0'
    switch (data.version) {
      case '1.0':
      case 'LightPlayer ContentFormat v1.1':
        return this.conformContentV1(data)
    }
    data.version = 'LightPlayer ContentFormat v1.1'

    throw new Error('ERROR__UNDEFINED_VERSION')
  }

  static async conformContentV1(data: LightPlayerContentFormat_v1_1): Promise<StreamingData> {
    const transforms = await this.resolveTransforms(data.transforms)

    const groups = data.groups.map(g => {
      g.reversed = !!g.reversed
      if (g.reversed) {
        g.reversed = false
        g.videoIds = g.videoIds.reverse()
      }
      delete g.reversed
      return g as VideoGroup
    })
    const sources: MediaSource[] = data.sources.map(s => {
      if (s.levels) {
        s.url = s.levels.find(l => !!l)?.url ?? ''
      }
      delete s.levels

      return s
    })

    // const content = cloneDeep(data) as ContentSettingsLightPlayer;
    const content: StreamingData = {
      audios: data.audios,
      authorName: data.authorName,
      closedCaptions: data.closedCaptions ?? [],
      contentType: data.contentType ?? '',
      createdDate: data.createdDate ?? '',
      description: data.description ?? '',
      duration: data.duration ?? 'auto',
      frameRate: data.frameRate ?? 0,
      groups,
      markers: data.markers ?? [],
      modes:
        !data.modes || data.modes === '*'
          ? Object.keys(DisplayMode).map(k => DisplayMode[k])
          : Array.isArray(data.modes)
            ? data.modes
            : [data.modes],
      offsetTime: data.offsetTime,
      playerOptions: Object.assign(
        {
          globalScale: 1,
          frameScale: 1,
          userPlayable: true,
          userStepControl: true,
          backgroundColor: [0, 0, 0]
        },
        data.playerOptions ?? {}
      ),
      poster: data.poster ?? '',
      profiles: data.profiles ?? [],
      sources: sources ?? [],
      title: data.title ?? '',
      transforms,
      version: data.version,
      videos: data.videos ?? [],
      domainFilter: data.domainFilter ?? undefined
    }

    return content
  }

  /**
   * transform情報を解決する
   */
  static async resolveTransforms(
    src: string | TransformSetInput[] | undefined
  ): Promise<TransformSetInput[]> {
    if (!src) {
      return []
    }

    const input: TransformSetInput[] = []
    if (typeof src === 'string') {
      input.push({
        name: 'Default',
        transforms: {
          url: src
        }
      })
    } else {
      src.forEach(item => {
        input.push(item)
      })
    }

    return input
  }

  static async resolveTransformInput(transformSet: TransformSetInput): Promise<EditorTransformSet> {
    if (!isExternalRef(transformSet.transforms)) {
      return {
        name: transformSet.name,
        transforms: transformSet.transforms
      }
    }
    const transforms = await this.resolveRefs<EditorVideoTransform[]>(
      transformSet.transforms,
      async data => {
        try {
          const obj: VideoTransform[] = await this.resolveCalibrationYAMLV1(
            data as AffineCalibrationV1Format
          )
          return obj
        } catch {
          throw new Error('ERROR__UNDEFINED_VERSION')
        }
      }
    )
    return {
      name: transformSet.name,
      transforms: transforms
    }
  }

  static async resolveRefs<T>(
    item: T | ExternalRef,
    resolver?: (obj: any) => T | Promise<T>
  ): Promise<T> {
    if (!isExternalRef(item)) {
      return item as T
    }

    let type = item.type
    if (!type) {
      const ext = item.url.substr(item.url.lastIndexOf('.') + 1)
      switch (ext) {
        case 'json':
          type = 'json'
          break
        case 'yml':
        case 'yaml':
          type = 'yaml'
      }
    }

    const url = item.url
    const raw = await this.resolveUrl(url, { responseType: 'text' })
    let data
    switch (type) {
      case 'yaml':
        data = YAML.parse(raw)
        break
      case 'json':
      default:
        data = JSON.parse(raw)
    }

    return resolver ? await resolver(data) : (data as T)
  }

  static async resolveCalibrationYAMLV1(
    calibration: AffineCalibrationV1Format
  ): Promise<EditorVideoTransform[]> {
    const transforms: EditorVideoTransform[] = []

    let max = 0
    calibration?.videos
      ?.sort((a, b) => {
        return a.id - b.id
      })
      .forEach(v => {
        max = Math.max(max, v.id)
        transforms[v.id] = {
          scale: [v.scale, v.scale],
          rotation: -v.rotation,
          offset: [v.shift[0], -v.shift[1]]
        }
      })

    for (let i = 0; i < max + 1; i++) {
      if (!transforms[i]) {
        transforms[i] = {
          scale: [1, 1],
          rotation: 0,
          offset: [0, 0],
          isEmpty: true
        }
      }
    }

    return transforms
  }

  private static async resolveUrl(url: string, config: AxiosRequestConfig = {}) {
    try {
      const result = await axios.get(url, { responseType: 'json', ...config })
      return result.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
