<template>
  <v-dialog v-model="progressStore.loading" scrim="false" persistent width="280">
    <v-card color="white" class="pa-4 pb-6">
      <v-card-text class="pl-0 pr-0">
        <p class="text-center">
          {{ progressStore.loadingText }}
        </p>
        <v-progress-linear height="6" indeterminate color="primary" striped class="mb-0 mt-4" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts" setup>
import { useProgressDialogStore } from '@/stores/dialog/progress'
const progressStore = useProgressDialogStore()
</script>

<style lang="scss"></style>
