<template>
  <div class="account-menu text-center">
    <v-menu :close-on-content-click="true" :min-width="280">
      <template #activator="{ props }">
        <v-btn v-bind="props" size="small" class="mr-3" color="white" icon="mdi-account" />
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <template #prepend>
              <v-icon>mdi-account</v-icon>
            </template>
            <template v-if="authStore.user.email !== ''">
              <v-list-item-title>{{ authStore.user.userName }}</v-list-item-title>
              <v-list-item-subtitle>{{ authStore.user.email }}</v-list-item-subtitle>
            </template>
            <v-list-item-title v-else-if="getAdminEmail">
              {{ getAdminEmail }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list v-if="authStore.activeSubscription" rounded>
          <v-list-item @click="() => (showSelectProjectDialog = true)">
            <template #prepend>
              <v-icon>mdi-domain</v-icon>
            </template>

            <v-list-item-title>
              {{ authStore.activeSubscription.tenantName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ authStore.activeSubscription ? authStore.activeSubscription.projectName : '' }}
            </v-list-item-subtitle>
            <template #append>
              <v-icon>mdi-menu-right</v-icon>
            </template>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list rounded>
          <v-list-item to="/password/change">
            <template #prepend>
              <v-icon />
            </template>
            <v-list-item-title>パスワード変更</v-list-item-title>
          </v-list-item>
          <v-list-item to="/privacy-policy">
            <template #prepend>
              <v-icon>mdi-file-eye-outline</v-icon>
            </template>
            <v-list-item-title>プライバシーポリシー</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut()">
            <template #prepend>
              <v-icon>mdi-logout</v-icon>
            </template>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
      <a class="account-menu__link" href="https://mastervisions.co.jp" target="_blank">
        <img alt="MasterVisions" class="account-menu__logo" src="@/assets/MasterVisions.png" />
      </a>
    </v-menu>

    <select-project-modal v-model="showSelectProjectDialog" @select="selectSubscription" />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { Auth } from 'aws-amplify'
import { useAuthStore } from '@/stores/auth'
import { useAdminAuthStore } from '@/stores/admin/auth'

type subscriptionType = {
  tenantId: string
  projectId: string
}

const authStore = useAuthStore()
const adminAuthStore = useAdminAuthStore()

const showSelectProjectDialog = ref(false)

const isNoActiveSubscription = computed(() => {
  return (
    authStore.signedIn && authStore.user.subscriptions.length > 0 && !authStore.activeSubscription
  )
})

const getAdminEmail = computed(() => {
  return adminAuthStore.getAuthUser().signInUserSession?.idToken.payload.email
})

watch(isNoActiveSubscription, value => {
  showSelectProjectDialog.value = value
})

const selectSubscription = (subscription: subscriptionType) => {
  showSelectProjectDialog.value = false
  authStore.selectSubscription(subscription.tenantId, subscription.projectId)
  location.reload()
}
const signOut = async () => {
  try {
    await Auth.signOut()
    await authStore.signOut()
    location.reload()
  } catch (error) {
    console.error('error signing out:', error)
  }
}
</script>

<style lang="scss">
.account-menu {
  &__link {
    background: $color-black;
    display: block;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 12px;
  }

  &__logo {
    width: 140px;
    display: block;
    margin: 0 auto;
  }
}
</style>
