<template>
  <v-dialog v-model="showModal" no-click-animation max-width="560">
    <v-card>
      <v-toolbar title="プロジェクト選択" color="indigo" />
      <v-container class="pa-0 project-box">
        <v-list
          v-for="tenants in groupedSubscriptionsByTenantId"
          :key="tenants.tenantId"
          :disabled="disabledList"
          class="pl-3 mt-n2"
        >
          <v-list-item class="mb-n1">
            <template #prepend>
              <v-icon>mdi-domain</v-icon>
            </template>

            <v-list-item-title>{{ tenants.title }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-for="subscription in tenants.items"
            :key="subscription.projectId"
            :active="subscription.isActive"
            class="pl-10"
            @click="selectSubscription(subscription)"
          >
            <template #prepend>
              <v-icon>mdi-menu-right</v-icon>
            </template>

            <v-list-item-title>{{ subscription.projectName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()

type subscriptionTypes = {
  projectId?: string
  isActive: boolean
  projectName: string
  isEnabled: boolean
  role: string
  tenantId: string
  tenantName: string
}

type tenantTypes = {
  tenantId: string
  title: string
  items: Array<subscriptionTypes>
}

const props = defineProps({
  title: {
    type: String,
    default: 'プロジェクト切り替え'
  },
  showSelectProjectDialog: {
    type: Boolean,
    required: false,
    default: false
  }
})

const disabledList = ref(false)

const showModal = computed(() => {
  return props.showSelectProjectDialog
})
const groupedSubscriptionsByTenantId = computed((): Array<tenantTypes> => {
  if (!authStore.user.subscriptions) {
    return []
  }
  return Array.from(
    authStore.user.subscriptions
      .slice()
      .sort((a, b) => {
        if (a.tenantName === b.tenantName) {
          return a.projectName > b.projectName ? -1 : 1
        }
        return a.tenantName > b.tenantName ? -1 : 1
      })
      .reduce((map, cur) => {
        const item = {
          ...cur,
          isActive: isActive(cur.tenantId, cur.projectId)
        }
        if (map.has(cur.tenantId)) {
          map.get(cur.tenantId).items.push(item)
        } else {
          map.set(cur.tenantId, {
            tenantId: cur.tenantId,
            title: cur.tenantName,
            isActive: true,
            items: [item]
          })
        }
        return map
      }, new Map())
      .values()
  )
})

const isActive = (tenantId: string, projectId: string) => {
  return (
    authStore.activeSubscription &&
    authStore.activeSubscription.tenantId === tenantId &&
    authStore.activeSubscription.projectId === projectId
  )
}
const selectSubscription = (subscription: any) => {
  emit('select', subscription)
}
const emit = defineEmits<{ (tenantId: string, projectId: string): void }>()
</script>

<style lang="scss">
.project-box {
  max-height: 390px;
  overflow-y: scroll;
}
</style>
