import axios from 'axios'
import { createPinia } from 'pinia'
import { useAdminAuthStore } from '@/stores/admin/auth'

const pinia = createPinia()
const authStore = useAdminAuthStore(pinia)

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL_BASE,
  timeout: 30000,
  responseType: 'json',
  validateStatus: status => (status >= 200 && status < 300) || status == 404,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env.VITE_APP_X_API_KEY
  }
})
// リクエスト設定(+ログ出力)
instance.interceptors.request.use(
  async config => {
    if (config.params?.requestWithoutAuthorization) {
      delete config.params.requestWithoutAuthorization
    } else {
      const auth = await authStore.getUserAuthentication()
      config.headers['Authorization'] = auth.signInUserSession.idToken.jwtToken
    }

    return config
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
)

// レスポンスログ
instance.interceptors.response.use(
  response => {
    console.info('API Response', response)
    return response
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
)

export default instance
