<template>
  <div class="g-header">
    <v-app-bar color="black">
      <v-app-bar-nav-icon
        v-if="authStore.signedIn && !checkAdminPath && isMobile"
        variant="text"
        @click.stop="showNavMenu = !showNavMenu"
      />

      <v-spacer v-if="isMobile" />

      <router-link to="/" class="g-header__logo">
        <img alt="PixyEye" class="g-header__logo__image" contain src="@/assets/pixyeye.svg" />
        <p v-if="checkAdminPath" class="g-header__logo__admin">管理システム</p>
      </router-link>

      <nav v-if="authStore.signedIn && !isMobile" class="g-header__nav">
        <ul class="g-header__menus">
          <li v-if="checkAdminPath" class="g-header__menu g-header__menu--active">テナント一覧</li>
          <li
            v-for="item in accessibleMenu"
            :key="item.name"
            class="g-header__menu"
            :class="{ 'g-header__menu--active': $route.name === item.name }"
            @click="onSelectMenu(item.name)"
          >
            {{ item.title }}
          </li>
          <li v-if="accessibleQR" class="g-header__menu" @click="showQrDialog">ユーザー招待</li>
        </ul>
      </nav>

      <v-spacer />

      <account-menu v-if="signinedAsAdminOrAnyOther" class="pl-2 pr-1" />
    </v-app-bar>

    <NavMenu
      v-if="authStore.signedIn && isMobile"
      :value="showNavMenu"
      :show-q-r-menu="accessibleQR"
      :menu="accessibleMenu"
      @select-menu="onSelectMenu"
      @show="showQrDialog"
    />

    <QRDialog
      v-if="accessibleQR"
      title="ユーザー招待"
      :show="showQR"
      :name="`QR_${authStore.activeSubscription?.projectName}`"
      :url="token"
      @hide="hideQR"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { storeToRefs } from 'pinia'
import { routes } from '@/router'
import axios from '@/common/axios'
import { useAuthStore } from '@/stores/auth'
import { useAdminAuthStore } from '@/stores/admin/auth'

type menuTypes = {
  title?: string | undefined
  name: string
  accessibleRoles?: string[] | undefined
}

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const adminAuthStore = useAdminAuthStore()
const isMobile = ref<boolean>(false)
const showNavMenu = ref<boolean>(false)
const showQR = ref<boolean>(false)
const token = ref<string>('')

const menu = ref<Array<menuTypes>>([{ name: 'contents' }, { name: 'users' }])

const { activeSubscription } = storeToRefs(authStore)

const accessibleMenu = computed((): any => {
  const subscription = authStore.activeSubscription

  if (!subscription) return []

  return menu.value.filter(
    (item: menuTypes) => !item.accessibleRoles || item.accessibleRoles.includes(subscription.role)
  )
})

const signinedAsAdminOrAnyOther = computed(() => {
  return authStore.signedIn || adminAuthStore.getIsAuthorized()
})

const accessibleQR = computed(() => {
  const subscription = authStore.activeSubscription

  if (subscription && subscription.role !== 'user') {
    return true
  } else {
    return false
  }
})

const checkAdminPath = computed(() => {
  return route.path.includes('admin')
})

watch(activeSubscription, async subscription => {
  if (subscription) {
    await fetchToken()
  }
})

const fetchToken = async () => {
  const { data } = await axios.get('/users/tokens', {
    params: {
      tenantId: authStore.activeSubscription.tenantId,
      projectId: authStore.activeSubscription.projectId,
      role: 'user'
    }
  })
  token.value = data.userTokens.length
    ? `${data.userTokens[0].url}&tenantId=${authStore.activeSubscription.tenantId}&projectId=${authStore.activeSubscription.projectId}`
    : ''
}

onMounted(async () => {
  const { mobile } = useDisplay()
  isMobile.value = mobile.value
  showNavMenu.value = !isMobile.value

  menu.value = menu.value.map((item: menuTypes) => {
    const route = routes.find(route => route.name === item.name)
    return {
      name: item.name,
      title: route?.meta?.title,
      accessibleRoles: route?.meta?.accessibleRoles
    }
  })

  authStore.activeSubscription && (await fetchToken())
})

const onSelectMenu = (name: string) => {
  if (route.name !== name && name !== 'qr') router.push({ name })
  showNavMenu.value = false
}
const showQrDialog = () => {
  showQR.value = true
}
const hideQR = () => {
  showQR.value = false
}
</script>

<style lang="scss">
.g-header {
  // Vuetify上書き
  .v-toolbar__content {
    border-bottom: 1px solid $color-sub-text;
  }
  .v-app-bar.v-toolbar:not(.v-toolbar--flat) {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  &__logo {
    height: 32px;
    display: block;
    margin: 0 60px 0 30px;
    position: relative;

    @include mq-down(tablet--under) {
      margin: 0 auto;
    }

    &__image {
      height: 32px;
      width: 100%;
    }

    &__admin {
      position: absolute;
      right: 0;
      bottom: -8px;
      color: $color-mono-f;
      font-size: 10px;
      font-weight: bold;
    }
  }

  &__menus {
    height: 64px;
    @include flex;
    gap: 60px;
    list-style: none;
  }
  &__menu {
    @include flex(center);
    height: 100%;
    cursor: pointer;
    position: relative;
    opacity: 0.8;
    transition: 0.3s;

    @include mq-up(tablet) {
      &:hover {
        opacity: 1;
      }
    }

    &--active {
      cursor: inherit;
      opacity: 1;

      &:after {
        display: block;
        content: '';
        position: absolute;
        width: 110%;
        height: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: $color-mono-f;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
