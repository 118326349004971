<template>
  <v-dialog v-model="showQRCode" permanent width="400">
    <v-card v-if="props.url" color="white" class="mt-12" min-width="300" max-width="940">
      <v-toolbar :title="title" color="indigo" />
      <v-card-text class="pa-8">
        <vue-qrcode id="qrcode" class="mb-6 qr-code" :value="props.url" tag="img" />
        <v-btn
          block
          color="black"
          class="mb-4"
          size="large"
          variant="outlined"
          @click="writeClipboard(props.url)"
        >
          {{ !clicked ? 'QRコードのURLをコピー' : 'コピーしました！' }}
        </v-btn>
        <v-btn block color="black" size="large" variant="flat" @click="download">
          ダウンロード
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false
  },
  title: {
    type: String,
    required: true
  },
  url: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  }
})
const clicked = ref(false)

const emit = defineEmits<{ (_event: 'hide'): void }>()
const showQRCode = computed({
  get: () => {
    return props.show
  },
  set: () => {
    emit('hide')
  }
})

const download = async () => {
  const qrImage = document.querySelector('#qrcode')
  const a = document.createElement('a')
  a.href = qrImage.src
  a.download = props.name
  a.click()
}
const writeClipboard = value => {
  navigator.clipboard.writeText(value)
  clicked.value = true
  setTimeout(() => (clicked.value = false), 1000)
}
</script>

<style lang="scss">
.qr-code {
  width: 100%;
}
</style>
