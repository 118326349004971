<template>
  <div class="tab-source">
    <div v-if="props.isNew" class="d-flex justify-between align-end">
      <v-spacer />
      <v-switch
        v-model="isFileSelection"
        color="primary"
        label="ファイル選択からアップ"
        hide-details
      />
    </div>
    <v-textarea
      v-if="!isFileSelection"
      v-model="sourceUrl"
      class="mb-8"
      label="ソースメディア入力(改行で複数入力可)"
      color="primary"
      hide-details
    >
      <template #append>
        <v-btn icon="mdi-plus" color="primary" :disabled="!sourceUrl" @click="addSources" />
      </template>
    </v-textarea>
    <div v-if="isFileSelection && props.isNew">
      <v-file-input
        v-model="files"
        label="ソースメディアファイル選択(複数可)"
        color="primary"
        variant="underlined"
        multiple
        @change="selectFiles"
      />
    </div>

    <div class="container">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(source, i) in [...localContent.streamingData.sources]"
          :key="i"
          :class="{
            'pt-5 is-drag-over': isDragging && dragOverId === i,
            'is-dragged': isDragging && draggingId === i
          }"
        >
          <v-expansion-panel-title
            draggable="true"
            @dragover="dragOver($event, i)"
            @dragend="dragEnd()"
            @dragstart="dragStart(i)"
            @drop="drop(i)"
          >
            <!-- Icon(種別によってアイコン出し分け) -->
            <v-icon
              v-if="cardState[i].streamType === 'audio'"
              size="x-small"
              icon="mdi-volume-medium"
            />
            <v-icon
              v-if="
                cardState[i].streamType === 'video' &&
                localContent.streamingData.sources[i].type === 'tiled'
              "
              size="x-small"
              icon="mdi-view-grid"
            />
            <v-icon
              v-else-if="
                cardState[i].streamType === 'video' &&
                (localContent.streamingData.sources[i].type === 'single' ||
                  !localContent.streamingData.sources[i].type)
              "
              size="x-small"
              icon="mdi-play-box"
            />
            <!-- Title -->
            <div class="ml-4">
              {{ getShortName(i) }}
            </div>
            <v-spacer />
            <v-btn
              icon="mdi-play-box"
              size="small"
              variant="plain"
              color="blue"
              @click.stop="openSource(i)"
            />
            <v-btn
              icon="mdi-trash-can"
              size="small"
              variant="plain"
              color="red"
              class="mr-2"
              @click.stop="remove(i)"
            />
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="card-content" @dragstart.prevent>
              <div class="pt-5">
                <v-text-field
                  v-if="localContent.clipId"
                  v-model="source.url"
                  label="URL"
                  color="primary"
                  variant="underlined"
                  placeholder="Source URL"
                  @change="change()"
                />
                <v-text-field
                  v-if="!localContent.clipId"
                  v-model="source.fileName"
                  label="ファイル名"
                  color="primary"
                  variant="underlined"
                  placeholder="Source URL"
                  @change="change()"
                />
                <v-select
                  v-model="source.mediaFormat"
                  label="フォーマット"
                  color="primary"
                  variant="underlined"
                  :items="['mp4', 'hls', 'mp3']"
                  @update:model-value="change"
                />
                <v-select
                  v-model="cardState[i].streamType"
                  label="配信種別(Stream Type)"
                  color="primary"
                  variant="underlined"
                  :items="[
                    {
                      value: 'video',
                      label: 'Video'
                    },
                    {
                      value: 'audio',
                      label: 'Audio'
                    }
                  ]"
                  item-title="label"
                  item-value="value"
                  @update:model-value="changeStreamType()"
                />
                <v-select
                  v-if="cardState[i].streamType === 'video'"
                  v-model="source.type"
                  label="配置種別(Visible Type)"
                  color="primary"
                  variant="underlined"
                  :items="[
                    {
                      value: 'tiled',
                      label: 'Tiled'
                    },
                    {
                      value: 'single',
                      label: 'Single'
                    }
                  ]"
                  item-title="label"
                  item-value="value"
                  @update:model-value="change()"
                />
                <div class="d-flex">
                  <v-text-field
                    v-if="source.type === 'tiled' && cardState[i].streamType === 'video'"
                    v-model.number="source.tileX"
                    type="number"
                    step="1"
                    label="X軸"
                    color="primary"
                    variant="underlined"
                    class="mr-4"
                    @change="change()"
                  />
                  <v-text-field
                    v-if="source.type === 'tiled' && cardState[i].streamType === 'video'"
                    v-model.number="source.tileY"
                    type="number"
                    step="1"
                    label="Y軸"
                    color="primary"
                    variant="underlined"
                    @change="change()"
                  />
                </div>

                <v-select
                  v-if="cardState[i].streamType === 'video'"
                  :value="getAspectPreset(i)"
                  label="アスペクト比"
                  color="primary"
                  variant="underlined"
                  :items="[
                    {
                      value: '',
                      label: 'オリジナル'
                    },
                    {
                      value: '16:9',
                      label: 'HDTV 16:9'
                    },
                    {
                      value: '4:3',
                      label: 'SD 4:3'
                    },
                    {
                      value: '1.66:1',
                      label: 'Vista 1.66:1'
                    },
                    {
                      value: '2.35:1',
                      label: 'CinemaScope 2.35:1'
                    },
                    {
                      value: 'custom',
                      label: 'カスタム'
                    }
                  ]"
                  item-title="label"
                  item-value="value"
                  @update:model-value="setAspect(i, 2, $event)"
                />

                <div
                  v-if="cardState[i].streamType === 'video' && getAspectPreset(i) !== ''"
                  class="d-flex"
                >
                  <v-text-field
                    v-model="getAspectOrDummy(i)[0]"
                    type="number"
                    step="0.001"
                    label="アスペクト比 X軸"
                    color="primary"
                    variant="underlined"
                    class="mr-4"
                    hide-details
                    @change="setAspect(i, 0, $event.target.value)"
                  />
                  <v-text-field
                    v-model="getAspectOrDummy(i)[1]"
                    type="number"
                    step="0.001"
                    label="アスペクト比 Y軸"
                    color="primary"
                    variant="underlined"
                    hide-details
                    @change="setAspect(i, 1, $event.target.value)"
                  />
                </div>
                <v-checkbox
                  v-model="source.muted"
                  label="ミュートする"
                  color="primary"
                  hide-details
                  @change="change()"
                />
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="mt-4 text-caption">
        ※ChromeブラウザでHLS形式の動画を再生する場合は、
        <a
          href="https://chrome.google.com/webstore/detail/native-hls-playback/emnphkkblegpebimobpbekeedfgemhof?hl=ja"
          target="_blank"
          color="primary"
        >
          拡張機能のインストール
        </a>
        が必要となります。
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, PropType } from 'vue'
import { createIdsMap } from '../../lib/Draggable'
import { basename } from '../../utils/OtherUtils'
import { EditableContentFormat } from '../../types/EditableContentFormat'

export interface CardState {
  expand: boolean
  streamType: 'video' | 'audio'
}

const props = defineProps({
  isNew: {
    type: Boolean,
    required: false,
    default: true
  },
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<EditableContentFormat>(props.content)
const cardState = ref<CardState[]>([])
const isDragging = ref(false)
const dragOverId = ref(-1)
const draggingId = ref(-1)
const sourceUrl = ref('')
const files = ref<File[]>([])
const isFileSelection = ref(false)

watch(
  () => props.content,
  obj => {
    localContent.value = obj
    updateSourceState()
  }
)

const updateSourceState = (): void => {
  cardState.value = localContent.value.streamingData.sources.map((s, i) => {
    if (cardState.value[i]) {
      return cardState.value[i]
    }
    let streamType: 'video' | 'audio' = 'video'
    if (
      localContent.value.streamingData.videos.some(
        v =>
          (v.regions && v.regions[0].tiled)?.sourceId === i ||
          (v.regions && v.regions[0].single)?.sourceId === i
      )
    ) {
      streamType = 'video'
    } else if (localContent.value.streamingData.audios.some(a => a.sourceId === i)) {
      streamType = 'audio'
    }
    return {
      expand: false,
      streamType: streamType
    }
  })
}

const addSources = (): void => {
  const url: string = sourceUrl.value
  if (!url) return

  const urls = sourceUrl.value.split('\n')

  urls.forEach(url => {
    localContent.value.streamingData.sources.push({
      url: url,
      type: 'single',
      mediaType: 'video',
      mediaFormat: 'mp4'
    })
  })
  sourceUrl.value = ''
  updateSourceState()
  change()
}

const selectFiles = (): void => {
  Object.values(files.value).forEach((file: File): void => {
    localContent.value.streamingData.sources.push({
      url: file.name,
      type: 'single',
      mediaType: 'video',
      mediaFormat: 'mp4'
    })
  })
  updateSourceState()
  change()
}

const openSource = (i: number): void => {
  const source = localContent.value.streamingData.sources[i]
  if (!source) {
    return
  }
  window.open(source.url)
}
const remove = (i: number): void => {
  cardState.value.splice(i, 1)
  localContent.value.streamingData.sources.splice(i, 1)

  for (let j = 0; j < localContent.value.streamingData.audios.length; j++) {
    if (localContent.value.streamingData.audios[j].sourceId === i) {
      localContent.value.streamingData.audios.splice(j, 1)
      j--
    }
  }
  for (let j = 0; j < localContent.value.streamingData.videos.length; j++) {
    const single = localContent.value.streamingData.videos[j].regions[0].single
    const tiled = localContent.value.streamingData.videos[j].regions[0].tiled
    if ((!single || single.sourceId === i) && (!tiled || tiled.sourceId === i)) {
      localContent.value.streamingData.videos.splice(j, 1)
      j--
    }
  }

  change()
}
const getShortName = (i: number): string => {
  const source = localContent.value.streamingData.sources[i]
  const p = source?.url
  const fileName = basename(p)
  return `${fileName} [${source?.type ?? '-'}] ${
    source?.type === 'tiled' ? source?.tileX + 'x' + source?.tileY : ''
  }`
}
const changeStreamType = (): void => {
  localContent.value.streamingData.audios = cardState.value
    .map((s, i) => i)
    .filter(i => cardState.value[i].streamType === 'audio')
    .map(i => {
      return {
        sourceId: i,
        offsetTime: 0
      }
    })
  change()
}
const getAspectPreset = (i: number): string => {
  const preset = ['', '16:9', '4:3', '1.66:1', '2.35:1']
  const aspectValues = getAspect(i)

  const aspect: string = aspectValues ? aspectValues.join(':') : ''

  return preset.indexOf(aspect) > -1 ? aspect : 'custom'
}
const setAspect = (i: number, mode: number, val: string): void => {
  const source = localContent.value.streamingData.sources[i]
  if (!source) {
    return
  }

  if (mode < 2) {
    if (!source.aspect) {
      source.aspect = [1, 1]
    } else if (!Array.isArray(source.aspect) && typeof source.aspect === 'number') {
      source.aspect = [source.aspect, 1]
    }
    source.aspect[mode] = Number(val)
    change()
    return
  }

  if (val === '') {
    source.aspect = undefined
    change()
    return
  }
  if (val === 'custom') {
    source.aspect = [16, 9]
    change()
    return
  }

  source.aspect = val.split(':').map(v => Number(v))
  change()
}
const getAspect = (i: number): number[] | null => {
  const source = localContent.value.streamingData.sources[i]
  return source.aspect ? (Array.isArray(source.aspect) ? source.aspect : [source.aspect, 1]) : null
}
const getAspectOrDummy = (i: number): number[] => {
  /*
  getAspect()をv-modelで利用すると
	message": "Object is possibly 'null'.
	というtypescriptがエラーが出るため、
	このラッパー関数でエラー対策する
  */
  const aspect = getAspect(i)
  return aspect ? aspect : [0, 0]
}
interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()
const change = (): void => {
  emit('change', localContent.value)
}
const dragOver = (e: DragEvent, i: number): void => {
  dragOverId.value = i
  e.preventDefault()
}
const dragEnd = (): void => {
  dragOverId.value = -1
  isDragging.value = false
}
const dragStart = (i: number): void => {
  dragOverId.value = -1
  draggingId.value = i
  isDragging.value = true
}
const drop = (i: number): void => {
  const from = draggingId.value
  const to = Math.min(
    i > draggingId.value ? i - 1 : i,
    localContent.value.streamingData.sources.length - 1
  )
  if (from === to) {
    return
  }

  // 各種トラックのIDを入れ替えるためのマップを作成 (新ID = idsMap[旧ID])
  const idMap = createIdsMap(
    localContent.value.streamingData.sources.map((s, i) => i),
    from,
    to
  )

  const source = localContent.value.streamingData.sources.splice(draggingId.value, 1)
  const state = cardState.value.splice(draggingId.value, 1)

  localContent.value.streamingData.sources.splice(to, 0, ...source)
  cardState.value.splice(to, 0, ...state)

  localContent.value.streamingData.videos.forEach(v => {
    const single = v.regions[0].single
    const tiled = v.regions[0].tiled
    if (tiled) {
      tiled.sourceId = idMap[tiled.sourceId]
    }
    if (single) {
      single.sourceId = idMap[single.sourceId]
    }
  })
  localContent.value.streamingData.audios.forEach(a => {
    a.sourceId = idMap[a.sourceId]
  })

  change()
}

updateSourceState()
</script>

<style lang="scss">
.is-drag-over {
  border-bottom: 4px solid #39f3fa;
}

.is-dragged {
  opacity: 0.5;
}

.transition-padding {
  transition: padding-bottom 0.1s;
}

.tab-source {
  // v-checkboxのチェックエリアが右横に広がってしまうので上書き
  .v-input__control {
    justify-content: flex-end !important;
  }
  .v-selection-control {
    justify-content: flex-end !important;
    .v-label {
      width: auto !important;
    }
  }
}
</style>
