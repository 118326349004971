<template>
  <div class="customTextarea">
    <v-text-field v-model="props.value" :label="label" class="customTextarea__field" disabled />
    <v-icon
      size="small"
      type="rounded"
      variant="ourlined"
      class="customTextarea__button"
      :icon="!clicked ? 'mdi-clipboard-text' : 'mdi-clipboard-check'"
      @click.prevent="copyToClipboard(value)"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useSnackbarStore } from '@/stores/snackbar'

const snackbarStore = useSnackbarStore()

// Props
const props = defineProps({
  value: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  disabled: {
    type: String, // NOTE：HTMLのdisabledはString扱いとなる
    required: false,
    default: 'false'
  }
})

// Data
const clicked = ref<boolean>(false)

// Methods
const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value)
  clicked.value = true

  snackbarStore.setShowSnackbar(true)
  snackbarStore.setSnackbarText('コピーしました。')
  snackbarStore.setSnackbarTime(1500)

  setTimeout(() => (clicked.value = false), 1000)
}
</script>

<style lang="scss">
.customTextarea {
  position: relative;

  &__field {
    input {
      padding-right: 40px;
    }
  }

  &__button {
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 10;
  }
}
</style>
