import { Auth } from 'aws-amplify'
import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'

export const useAdminAuthStore = defineStore(
  'adminAuth',
  () => {
    // State
    const isAuthorized = ref(false)
    let authUser: any = reactive({})

    // Getters
    const getAuthUser = () => {
      return authUser
    }
    const getIsAuthorized = () => {
      return isAuthorized.value
    }

    // Actions
    const signIn = async (mailAddress: string, password: string) => {
      try {
        return await Auth.signIn(mailAddress, password)
      } catch (error) {
        return Promise.reject(error)
      }
    }
    const federatedSignIn = async (provider: any) => {
      try {
        return await Auth.federatedSignIn({ provider: provider })
      } catch (error) {
        return Promise.reject(error)
      }
    }
    const signOut = async () => {
      try {
        await Auth.signOut()
        authUser = {}
        localStorage.removeItem('auth')
      } catch (error) {
        return Promise.reject(error)
      }
    }
    const getUserAuthentication = async () => {
      try {
        return await Auth.currentAuthenticatedUser()
      } catch (error) {
        return Promise.reject(error)
      }
    }
    const setUserAuthentication = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        authUser = user
        isAuthorized.value = true
      } catch (error) {
        isAuthorized.value = false
        return Promise.reject(error)
      }
    }

    return {
      getAuthUser,
      getIsAuthorized,
      signIn,
      federatedSignIn,
      signOut,
      getUserAuthentication,
      setUserAuthentication
    }
  },
  {
    persist: true
  }
)
