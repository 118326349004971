<template>
  <v-navigation-drawer v-model="showNavMenu" width="260" :permanent="false" :scrim="false">
    <v-container class="pa-0 navigation-menu">
      <v-list rounded>
        <v-list-item
          v-for="item in menu"
          :key="item.name"
          :active="$route.name === item.name"
          class="navigation-menu__item"
          active-class="navigation-menu__item--active"
          @click="selectMenu(item.name)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="props.showQRMenu" @click="openQRDialog">
          <v-list-item-title>ユーザー招待</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-container>
  </v-navigation-drawer>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, PropType } from 'vue'

type menuTypes = {
  title?: string | undefined
  name: string
  accessibleRoles?: string[] | undefined
}

const route = useRoute()
const router = useRouter()

const props = defineProps({
  value: {
    type: Boolean,
    required: true
  },
  showQRMenu: {
    type: Boolean,
    required: true
  },
  menu: {
    type: Object as PropType<menuTypes[]>,
    required: false,
    default: null
  }
})

const showNavMenu = ref(false)

interface Emits {
  (_event: 'show'): void
  (_event: 'selectMenu', _val: string): void
}
const emit = defineEmits<Emits>()

watch(
  () => props.value,
  val => {
    showNavMenu.value = val
  }
)

const openQRDialog = () => {
  emit('show')
  emit('selectMenu', '')
}
const selectMenu = (name: string) => {
  if (route.name !== name && name !== 'qr') router.push({ name })
  emit('selectMenu', name)
}
</script>

<style lang="scss">
.navigation-menu {
  height: 100%;
  position: absolute;
  &__item {
    border-left: 5px solid transparent;
    &--active {
      border-left: 5px solid #3f51b5;

      & > .v-list-item__overlay {
        opacity: 0;
      }
    }
  }
}
</style>
