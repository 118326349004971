<template>
  <div class="player-view">
    <div v-if="isError" class="player-view__error">
      <v-icon icon="mdi-alert-circle-outline" size="48" />
      <span>この動画は再生できません。</span>
    </div>
    <bullet-light-player-component
      v-else
      :json="contentModel"
      :auto-load="queries.autoLoad"
      :auto-start="queries.autoStart"
      :controls="queries.controls"
      :poster-only="queries.posterOnly"
    />
    <!-- PixyEye内で閲覧している時のみ戻るボタン表示 -->
    <v-btn
      v-if="authStore.signedIn"
      class="player-view__button player-view__button--close"
      large
      icon
      color="rgba(100%,100%,100%,70%)"
      @click="close()"
    >
      <v-icon size="30px">mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts" setup>
import { LocationQueryValue, useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useAdminAuthStore } from '@/stores/admin/auth'
import { ref } from 'vue'
import axios from '@/common/axios'

import { BulletLightPlayerComponent } from '@2501world/lightplayer-vue'
import { ContentLoader } from '@2501world/lightplayer-lib'
import '@2501world/lightplayer-vue/dist/style.css' //プレイヤーのCSSをロード
import { onMounted } from 'vue'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const adminAuthStore = useAdminAuthStore()

const contentModel = ref<any>(null)
const queries = ref({
  autoLoad: route.query.autoLoad || route.query.autoStart,
  autoStart: route.query.autoStart,
  controls: route.query.hideControls,
  posterOnly: route.query.posterOnly
})
const isError = ref<boolean>(false)

onMounted(async () => {
  contentModel.value = await getContentModel(route.query.clipId)
})
const getContentModel = async (
  clipId: string | LocationQueryValue | LocationQueryValue[]
): Promise<any> => {
  const requestWithoutAuthorization = !authStore.signedIn && !adminAuthStore.getIsAuthorized()
  try {
    const { data } = await axios.get(`/clips/${clipId}`, {
      params: { requestWithoutAuthorization },
      withCredentials: true
    })
    const contentModel = await ContentLoader.conformContent(data.streamingData)

    return contentModel
  } catch (error) {
    if (!authStore.signedIn) {
      sessionStorage.setItem('return-path', `/player?clipId=${route.query.clipId}`)
      router.push('login')
    }

    isError.value = true
  }
}

const close = () => {
  router.back()
}
</script>

<style lang="scss">
.player-view {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 2000; // drawerより上に設定
  left: 0;
  top: 0;
  background: #000;

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    position: absolute;
    z-index: 2001;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
    min-width: 90vw;
    line-height: 1.4;
  }

  &__button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 2002; /* プレイヤーのoverlayクラスのz-indexよりも上にしておく。 */

    @include mq-down(tablet--under) {
      width: 40px;
      height: 40px;
      right: 12px;
      top: 12px;
    }

    &--qr {
      right: 80px;

      @include mq-down(tablet--under) {
        right: 74px;
      }
    }
  }
}
</style>
