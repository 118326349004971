<template>
  <v-container>
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="プロバイダーログイン" color="indigo" />
      <v-row v-if="!getIsAuthorized()" justify="center" class="pa-12">
        <v-col cols="auto">
          <v-btn color="primary" size="x-large" min-width="120" @click="signIn()">
            Googleアカウントでログイン
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- ErrorDialog -->
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script lang="ts" setup>
import URLParse from 'url-parse'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/admin/user'
import { useAdminAuthStore } from '@/stores/admin/auth'

// Amplify読み込み
import { Amplify } from 'aws-amplify'
import awsconfig from '../../../common/aws-config-provider'

const url = new URLParse(location.href)
awsconfig.oauth.redirectSignIn =
  awsconfig.oauth.redirectSignOut = `${url.protocol}//${url.host}/admin/login`
awsconfig.oauth.responseType = 'code'
awsconfig.oauth.domain = import.meta.env.VITE_APP_OAUTH_DOMAIN
Amplify.configure(awsconfig)

const router = useRouter()

const userStore = useUserStore()
const { getAuthUser, getIsAuthorized, federatedSignIn, setUserAuthentication } = useAdminAuthStore()
const { isUnregisteredUser } = storeToRefs(userStore)

// data
const hasError = ref<boolean>(false)
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')

// methods
const signIn = async (): Promise<void> => {
  try {
    await federatedSignIn(CognitoHostedUIIdentityProvider.Google)
  } catch (error) {
    hasError.value = true
    errorTitle.value = 'ログインに失敗しました'
    errorMessage.value = 'ログインに失敗しました。再度お試しください。'
  }
}

// mounted
onMounted(async () => {
  // NOTE：ログイン時に一回Googleの認証画面に遷移してしまってVuexにstateを追加出来ないので、読み込み時に実行
  await setUserAuthentication()

  if (getIsAuthorized()) {
    try {
      const userId = getAuthUser().signInUserSession.accessToken.payload.sub
      // ユーザー情報を取得して登録されてなかった場合は登録処理を投げる
      await userStore.checkUnregisteredUser(userId)

      if (isUnregisteredUser) await userStore.postUsers()
    } catch (e) {
      hasError.value = true
      errorTitle.value = 'ログインに失敗しました'
      errorMessage.value = 'ログインに失敗しました。再度お試しください。'
    }

    router.push('/admin/tenants')
  }
})
</script>
