<template>
  <v-app>
    <GlobalHeader />

    <v-main>
      <router-view />
    </v-main>

    <Snackbar />
    <ProgressDialog />
  </v-app>
</template>

<script lang="ts" setup></script>

<style lang="scss">
.v-main {
  background: #f7f5ff;
}
</style>
