import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/common/axios'

export const useUserStore = defineStore('user', () => {
  // State
  const users = ref([])
  const userTokens = ref([])
  const isUnregisteredUser = ref(false)

  // Getters
  const getUser = () => {
    return userTokens.value
  }

  // Actions
  const getUsers = async (tenantId: string | string[], role: string) => {
    try {
      const { data } = await axios.get('/users', {
        params: {
          tenantId: tenantId,
          role: role
        }
      })
      users.value = data.users
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const checkUnregisteredUser = async (userId: string) => {
    try {
      const res = await axios.get(`/users/${userId}`)

      isUnregisteredUser.value = res.status === 404 ? true : false
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const postUsers = async () => {
    try {
      return await axios.post('/users')
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getUserTokens = async (tenantId: string | string[], role: string) => {
    try {
      const { data } = await axios.get('/users/tokens', {
        params: {
          tenantId: tenantId,
          role: role
        }
      })
      userTokens.value = data.userTokens
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const postUsersTokens = async (
    role: string,
    tenantId: string,
    projectId: string,
    email?: string
  ) => {
    try {
      return await axios.post('/users/tokens', {
        role: role,
        tenantId: tenantId,
        projectId: projectId,
        email: email
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const putUsersTokens = async (
    userToken: string,
    tenantId: string,
    projectId: string,
    role: string,
    email: string
  ) => {
    try {
      return await axios.put(`/users/tokens/${userToken}`, {
        role: role,
        tenantId: tenantId,
        projectId: projectId,
        email: email
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    users,
    userTokens,
    isUnregisteredUser,
    getUser,
    getUsers,
    checkUnregisteredUser,
    postUsers,
    getUserTokens,
    postUsersTokens,
    putUsersTokens
  }
})
