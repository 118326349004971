<template>
  <v-dialog v-model="props.show" min-width="300" max-width="640" @click:outside="closeDialog">
    <v-card color="white" class="w-100 mt-12 caption-dialog">
      <v-toolbar :title="title" color="indigo" />
      <v-card-text class="pl-8 pr-8">
        <v-text-field
          v-model="option.fontSize"
          color="primary"
          variant="underlined"
          :rules="[value => !!value || '入力必須です']"
        >
          <template #label>
            文字サイズ(単位含む)
            <span style="color: red">*</span>
          </template>
        </v-text-field>
        <v-text-field
          v-model="option.inlineSize"
          color="primary"
          variant="underlined"
          :rules="[value => !!value || '入力必須です']"
        >
          <template #label>
            字幕表示幅(単位含む)
            <span style="color: red">*</span>
          </template>
        </v-text-field>
        <div class="d-flex align-center mb-3 mt-1">
          <label class="mr-4">
            文字色 ({{ option.color }})
            <span style="color: red">*</span>
          </label>
          <input v-model="option.color" type="color" />
        </div>
        <div class="d-flex align-center mb-3">
          <label class="mr-4">
            背景色 ({{ option.bgColor }})
            <span style="color: red">*</span>
          </label>
          <input v-model="option.bgColor" type="color" />
        </div>
        <div class="d-flex">
          <label class="mr-4 mt-1">
            透明度 ({{ option.opacity * 100 }}%)
            <span style="color: red">*</span>
          </label>
          <v-slider
            v-model="option.opacity"
            type="range"
            min="0"
            max="1"
            step="0.1"
            color="primary"
          />
        </div>
        <v-select
          v-model="option.writingMode"
          item-title="label"
          item-value="value"
          color="primary"
          label="視点選択"
          variant="underlined"
          :items="writingModeItems"
        />
        <v-select
          v-model="option.textAlign"
          item-title="label"
          item-value="value"
          color="primary"
          label="文字寄せ"
          variant="underlined"
          :items="alignItems"
        />
        <v-select
          v-model="option.align"
          item-title="label"
          item-value="value"
          color="primary"
          label="字幕位置"
          variant="underlined"
          :items="alignItems"
        />
        <v-select
          v-model="option.verticalAlign"
          item-title="label"
          item-value="value"
          color="primary"
          label="文字上下位置"
          variant="underlined"
          :items="verticalAlignItems"
        />
        <v-text-field
          v-model.number="option.offsetX"
          color="primary"
          variant="underlined"
          :rules="[value => value !== '' || '入力必須です']"
          type="number"
        >
          <template #label>横ずらし位置(単位不要)</template>
        </v-text-field>
        <v-text-field
          v-model.number="option.offsetY"
          color="primary"
          variant="underlined"
          :rules="[value => value !== '' || '入力必須です']"
          type="number"
        >
          <template #label>
            縦ずらし位置(単位不要)
            <span style="color: red">*</span>
          </template>
        </v-text-field>
      </v-card-text>
      <v-card-actions class="mb-2 mr-6">
        <v-spacer />
        <v-btn color="primary" size="large" variant="flat" text="閉じる" @click="closeDialog" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import {
  writingModeItems,
  alignItems,
  verticalAlignItems
} from '@/views/Clips/Editor/utils/SelectItemsUtil'
import { CaptionOptionFormat } from '@/views/Clips/Editor/types/EditableContentFormat'
// Props
const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false
  },
  title: {
    type: String,
    required: false,
    default: ''
  },
  options: {
    type: Object,
    required: false,
    default: null
  }
})

const option = ref<Partial<CaptionOptionFormat>>(props.options)

watch(
  () => props.options,
  obj => {
    option.value = obj
  }
)

// Emit
interface Emits {
  (_event: 'close', _val: Partial<CaptionOptionFormat>): void
}
const emit = defineEmits<Emits>()

// Methods
const closeDialog = () => {
  emit('close', option.value)
}
</script>

<style lang="scss">
.caption-dialog {
  min-width: 300px;
  width: 80%;
  max-width: 640px;
}
</style>
