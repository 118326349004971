import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useAdminAuthStore } from '@/stores/admin/auth'

// テナント、一般ユーザー用ページ
import Clips from '../views/Clips/index.vue'
// TODO：キャプチャ取り込み時にパス修正
import ClipEditorRoute from '../views/Clips/Editor/index.vue'
import ClipEditorDefault from '../views/Clips/Editor/views/Default/index.vue'
import ClipManualEditor from '../views/Clips/Editor/views/Manual/index.vue'
import ClipCaptionEditor from '../views/Clips/Editor/views/Caption/index.vue'
import UserList from '../views/UserList.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import RegisterConfirm from '../views/RegisterConfirm.vue'
import PasswordReset from '../views/PasswordSetting/Reset.vue'
import PasswordConfirm from '../views/PasswordSetting/Confirm.vue'
import PasswordChange from '../views/PasswordSetting/Change.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Player from '../views/Player.vue'
import NotFound from '../views/NotFound/index.vue'

// プロバイダーページ
import AdminLogin from '../views/Admin/Login/index.vue'
import AdminTenantList from '../views/Admin/TenantList/index.vue'
import AdminProjectList from '../views/Admin/ProjectList/index.vue'

export const routes = [
  // トップに表示するものが無いのでコンテンツ一覧にリダイレクト
  {
    path: '/',
    redirect: '/contents'
  },
  {
    path: '/admin',
    redirect: '/admin/tenants'
  },
  {
    // コンテンツ一覧ページ
    path: '/contents',
    name: 'contents',
    component: Clips,
    meta: {
      title: 'コンテンツ一覧',
      requiresAuth: true,
      accessibleRoles: ['user', 'tenant']
    }
  },
  {
    // エディターページ
    path: '/editor',
    name: 'editor',
    component: ClipEditorRoute,
    children: [
      {
        path: 'default',
        component: ClipEditorDefault
      },
      {
        path: 'manual',
        component: ClipManualEditor
      },
      {
        path: 'caption',
        component: ClipCaptionEditor
      }
    ],
    meta: {
      title: 'ClipEditor',
      requiresAuth: true,
      accessibleRoles: ['tenant']
    }
  },
  {
    // プレイヤーページ
    path: '/player',
    name: 'player',
    component: Player,
    meta: {
      title: 'プレイヤー',
      requiresAuth: false
    }
  },
  {
    // ユーザー管理ページ
    path: '/users',
    name: 'users',
    component: UserList,
    meta: {
      title: 'ユーザー管理',
      requiresAuth: true,
      accessibleRoles: ['tenant']
    }
  },
  {
    // ログインページ
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'ログイン',
      requiresAuth: false,
      disableIfAuthorized: true
    }
  },
  {
    // ユーザ登録
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      title: 'ユーザ登録',
      requiresAuth: false,
      disableIfAuthorized: false
    }
  },
  {
    // パスワード検証
    path: '/register/confirm',
    name: 'register/confirm',
    component: RegisterConfirm,
    meta: {
      title: '検証コード入力',
      requiresAuth: false,
      disableIfAuthorized: true
    }
  },
  {
    // パスワードリセット
    path: '/password/reset',
    name: 'password/reset',
    component: PasswordReset,
    meta: {
      title: 'パスワードリセット',
      requiresAuth: false
    }
  },
  {
    // パスワード認証(パスワード再設定)
    path: '/password/confirm',
    name: 'password/confirm',
    component: PasswordConfirm,
    meta: {
      title: '検証コード入力',
      requiresAuth: false
    }
  },
  {
    // パスワード変更
    path: '/password/change',
    name: 'password/change',
    component: PasswordChange,
    meta: {
      title: 'パスワード変更',
      requiresAuth: true
    }
  },
  {
    // プライバシーポリシー
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      title: 'プライバシーポリシー',
      requiresAuth: false,
      disableIfAuthorized: false
    }
  },
  {
    // 404ページ
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  /*----------
  [Provider]
  ----------*/
  {
    // プロバイダーログイン
    path: '/admin/login',
    name: 'admin-login',
    component: AdminLogin,
    meta: {
      title: 'プロバイダーログイン',
      requiredAuthProvider: false
    }
  },
  {
    // テナント一覧
    path: '/admin/tenants',
    name: 'admin-tenants',
    component: AdminTenantList,
    meta: {
      title: 'テナント一覧',
      requiredAuthProvider: true
    }
  },
  {
    // テナント一覧
    path: '/admin/tenants/:id/projects',
    name: 'admin-projects',
    component: AdminProjectList,
    meta: {
      title: 'プロジェクト一覧',
      requiredAuthProvider: true
    }
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})

// リダイレクト設定
router.beforeResolve(async (to, from, next) => {
  const authStore = useAuthStore()
  const adminAuthStore = useAdminAuthStore()

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiredAuthProvider = to.matched.some(record => record.meta.requiredAuthProvider)

  // Tenantで一般かどうか取得
  if (requiresAuth && authStore.user.subscriptions.length === 0) {
    try {
      await authStore.fetchUser()
    } catch (error) {
      return next({
        name: 'login'
      })
    }
  }

  // Providerリダイレクト設定
  if (requiredAuthProvider && !adminAuthStore.getIsAuthorized()) {
    return next({
      name: 'admin-login'
    })
  }
  // Tenantリダイレクト設定
  if (requiresAuth && !authStore.signedIn) {
    return next({
      name: 'login'
    })
  }

  return next()
})

// titleタグ指定
const DefaultTitle = 'PIXY EYE'
router.afterEach(to => {
  document.title = to.meta.title ? `${to.meta.title} | ${DefaultTitle}` : DefaultTitle
})
