<template>
  <v-container>
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="サインアップ" color="indigo" />
      <v-form ref="form" class="pa-8" @submit.prevent="signup()">
        <v-text-field v-model="mailAddress" :rules="mailAddressRules">
          <template #label>
            メールアドレス
            <span style="color: red">*</span>
          </template>
        </v-text-field>
        <v-text-field v-model="userName" label="ユーザ名" :rules="userNameRules" class="mt-2" />
        <v-text-field
          v-model="password"
          class="mt-2"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :rules="passwordRules"
          @click:append="showPassword = !showPassword"
        >
          <template #label>
            パスワード
            <span style="color: red">*</span>
          </template>
        </v-text-field>
        <v-row justify="center" class="mt-4">
          <v-col cols="auto">
            <v-btn color="primary" variant="flat" size="large" block min-width="240" type="submit">
              登録
            </v-btn>
          </v-col>
        </v-row>
        <hr class="mt-8 mb-5" />
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn
              variant="plain"
              block
              color="primary"
              :to="{ name: 'login', query: { userToken: userToken } }"
            >
              アカウントをお持ちの方はこちら
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      :show-primary-button="true"
      :show-secondary-button="errorNum === 1"
      primary-button-text="ログイン画面へ"
      secondary-button-text="検証メール再送"
      @close="hasError = false"
      @click-primary="toLogin()"
      @click-secondary="resendVerification()"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSnackbarStore } from '@/stores/snackbar'
import { useProgressDialogStore } from '@/stores/dialog/progress'
import { ref, onMounted } from 'vue'
import { Auth } from '@aws-amplify/auth'
import axios from '@/common/axios'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()
const progressStore = useProgressDialogStore()

const hasError = ref<boolean>(false)
const errorNum = ref<number>()
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')
const showErrorResendBtn = ref<boolean>(false)
const errorRedirectSignin = ref<boolean>(false)
const showPassword = ref<boolean>(false)
const userToken = ref<string>('')
const mailAddress = ref<string>('')
const mailAddressRules = ref<Array<(_v: string) => string | boolean>>([
  v => !!v || 'メールアドレスを入力してください',
  v => /.+@.+\..+/.test(v) || 'メールアドレスが正しい形式ではありません'
])
const userName = ref<string>('')
const userNameRules = ref<Array<(_v: string) => string | boolean>>([
  v => !v || v.length <= 15 || 'ユーザ名は15文字以内で設定してください'
])
const password = ref<string>('')
const passwordRules = ref<Array<(_v: string) => string | boolean>>([
  v => !!v || 'パスワードを入力してください',
  v => (v && v.length >= 8) || 'パスワードは8文字以上の半角英数字を設定してください'
])

onMounted(async () => {
  userToken.value = typeof route.query.userToken === 'string' ? route.query.userToken : ''

  // NOTE：ログイン済みかつuserTokenが正常に取得出来たらusersAPIでuserTokenを渡してトップに遷移
  if (authStore.signedIn && userToken.value) {
    await axios.post('/users', { userToken: userToken.value })
    // NOTE：TenantとProjectを取り直してSnackbarを表示
    await authStore.fetchUser()
    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('プロジェクトを追加しました。')
    router.push('/')
  }
})

const signup = async () => {
  progressStore.setLoading(true)
  try {
    await Auth.signUp({
      username: mailAddress.value,
      password: password.value
    })
    router.push(
      `/register/confirm?mailAddress=${mailAddress.value}&userName=${userName.value}&password=${password.value}&userToken=${userToken.value}`
    )
  } catch (error) {
    if (`${error}`.match('UsernameExistsException')) {
      errorTitle.value = '登録に失敗しました'
      errorNum.value = 1
      hasError.value = true
      errorMessage.value =
        'ご指定のメールアドレスはすでに登録されています。\n' +
        'ご指定のメールアドレスが未検証である場合は、\n' +
        '検証メール再送ボタンにて再度検証コードを送信し、\n' +
        'ユーザ登録を完了してください。'
      showErrorResendBtn.value = true
      errorRedirectSignin.value = true
    } else {
      errorTitle.value = '登録に失敗しました'
      hasError.value = true
    }
  }
  progressStore.setLoading(false)
}
const resendVerification = async () => {
  hasError.value = false
  progressStore.setLoading(true)
  await Auth.resendSignUp(mailAddress.value)
  router.push(
    `/register/confirm?mailAddress=${mailAddress.value}&userName=${userName.value}&password=${password.value}&userToken=${userToken.value}`
  )
  progressStore.setLoading(false)
}
const toLogin = () => {
  hasError.value = false
  router.push('/login')
}
</script>

<style scoped>
.v-card .v-card-text.errorMessage {
  white-space: pre-wrap;
  font-size: 14px;
  padding: 16px 1rem 10px;
}
</style>
