<template>
  <v-container>
    <v-layout class="mb-8 d-flex align-center justify-space-between">
      <div class="text-h5">テナント一覧</div>
      <v-spacer />
      <v-btn color="primary" @click="clearCreateItem(), (showCreateDialog = true)">
        テナント登録
      </v-btn>
    </v-layout>

    <v-card>
      <v-table class="table-wrapper">
        <thead>
          <tr>
            <th>テナント名</th>
            <th>テナント情報</th>
            <th>登録日時</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="4">
              <v-row justify="center" class="pa-12">
                <v-progress-circular v-if="isLoading" indeterminate :size="32" />
              </v-row>
            </td>
          </tr>
        </tbody>
        <tbody v-if="tenantList?.length == 0 && !isLoading">
          <tr>
            <td colspan="4" class="pa-12 text-center">ユーザーが見つかりません。</td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(tenant, index) in sliceTenants()" :key="index">
            <td style="max-width: 360px; min-width: 180px" class="pt-2 pb-2">
              {{ tenant.tenantName }}
            </td>
            <td
              class="pt-2 pb-2 text-wrap"
              style="max-width: 360px; min-width: 180px; overflow-wrap: anywhere"
            >
              {{ tenant.information }}
            </td>
            <td width="157" style="min-width: 157px">
              {{ dateFormat(tenant.createdAt) }}
            </td>
            <td width="66" class="pr-2">
              <v-btn
                color="primary"
                variant="outlined"
                @click=";(showEditDialog = true), (selectedItem = { ...tenant })"
              >
                表示
              </v-btn>
            </td>
            <td class="pl-0" width="155">
              <v-btn
                color="primary"
                depressed
                @click="$router.push({ name: 'admin-projects', params: { id: tenant.tenantId } })"
              >
                プロジェクト一覧
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <div class="text-center mt-8">
      <v-pagination
        v-model="page"
        :length="pageLength"
        :total-visible="5"
        @update:model-value="changePage"
      />
    </div>
    <DialogTemplate
      :show="showCreateDialog"
      title="テナント登録"
      close-button-text="キャンセル"
      primary-button-text="登録"
      :disabled="checkValidation(createItem.tenantName)"
      @close="showCreateDialog = false"
      @action="createTenant"
    >
      <v-form>
        <v-card-text>
          <v-text-field
            v-model="createItem.tenantName"
            outlined
            label="テナント名"
            :rules="tenantNameRule"
            class="mb-2"
          />
          <v-textarea v-model="createItem.information" outlined label="テナント登録情報" />
          <div class="text-subtitle-2 mb-2">使用するクリップのフォーマット</div>
          <v-btn-toggle v-model="createItem.clipFormats" multiple>
            <v-btn disabled tile outlined value="hls">
              <v-icon size="20.5">
                {{
                  createItem.clipFormats.includes('hls')
                    ? 'mdi-checkbox-marked-circle'
                    : 'mdi-checkbox-blank-circle-outline'
                }}
              </v-icon>
              HLS
            </v-btn>
            <v-btn tile outlined value="mp4">
              <v-icon size="20.5">
                {{
                  createItem.clipFormats.includes('mp4')
                    ? 'mdi-checkbox-marked-circle'
                    : 'mdi-checkbox-blank-circle-outline'
                }}
              </v-icon>
              MP4
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-form>
    </DialogTemplate>
    <DialogTemplate
      v-if="selectedItem"
      :show="showEditDialog"
      title="テナント編集"
      close-button-text="キャンセル"
      primary-button-text="更新"
      :disabled="checkValidation(selectedItem.tenantName)"
      @close="showEditDialog = false"
      @action="editTenant"
    >
      <v-form class="pa-8 pt-6 pb-4">
        <CopiableTextField
          :value="selectedItem.tenantId"
          outlined
          label="テナントID"
          disabled="true"
        />
        <v-text-field
          v-model="selectedItem.tenantName"
          outlined
          label="テナント名"
          :rules="tenantNameRule"
          class="mb-2"
        />
        <v-textarea v-model="selectedItem.information" outlined label="テナント登録情報" />
        <div class="text-subtitle-2 mb-2">使用するクリップのフォーマット</div>
        <v-btn-toggle v-model="selectedItem.clipFormats" multiple>
          <v-btn disabled tile outlined value="hls">
            <v-icon size="20.5">
              {{
                selectedItem.clipFormats.includes('hls')
                  ? 'mdi-checkbox-marked-circle'
                  : 'mdi-checkbox-blank-circle-outline'
              }}
            </v-icon>
            HLS
          </v-btn>
          <v-btn tile outlined value="mp4">
            <v-icon size="20.5">
              {{
                selectedItem.clipFormats.includes('mp4')
                  ? 'mdi-checkbox-marked-circle'
                  : 'mdi-checkbox-blank-circle-outline'
              }}
            </v-icon>
            MP4
          </v-btn>
        </v-btn-toggle>
      </v-form>
    </DialogTemplate>

    <!-- ErrorDialog -->
    <ErrorDialog
      :error="errors.hasError"
      :title="errors.title"
      :text="errors.message"
      @close="errors.hasError = false"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTenantStore } from '@/stores/admin/tenant'
import { useSnackbarStore } from '@/stores/snackbar'
import { systemTenants } from '@/constants/system-tenants'
import dayjs from '@/common/dayjs'

const router = useRouter()
const route = useRoute()

const tenantStore = useTenantStore()
const snackbarStore = useSnackbarStore()

// data
const isLoading = ref<boolean>(false)
const showCreateDialog = ref<boolean>(false)
const showEditDialog = ref<boolean>(false)
const projectId = ref<string>('')
let tenantList = reactive<any>([])
const page = ref<number>(1)
const pageLength = ref<number>(0)
const createItem = reactive({
  tenantName: '',
  information: '',
  clipFormats: ['hls']
})
type selectedItemType = {
  tenantName: string
  tenantId: string
  information: string
  clipFormats: string
}
const selectedItem = ref<selectedItemType>()
const tenantNameRule = reactive([(v: any) => !!v || 'テナント名を入力してください'])
const errors = reactive({
  hasError: false,
  title: '',
  message: ''
})

// Methods
const dateFormat = (date: Date) => dayjs(date).format('YYYY年M月D日')

const changePage = (pageNumber: number) => {
  page.value = pageNumber
  router.push(`/admin/tenants/?page=${page.value}`)
}

const sliceTenants = () => tenantList.slice((page.value - 1) * 10, page.value * 10)

const getTenantList = async () => {
  isLoading.value = true
  try {
    await tenantStore.getTenants(tenantStore.tenantId, projectId.value)

    tenantList = tenantStore.tenants.filter(tenant => !systemTenants.includes(tenant.tenantName))
    pageLength.value = Math.ceil(tenantList.length / 10)
  } catch (error) {
    errors.hasError = true
    errors.title = 'テナントの取得に失敗しました'
    errors.message = 'テナントの取得に失敗しました。再度お試しください。'
  } finally {
    isLoading.value = false
  }
}

const checkValidation = (value: string) => value === ''

const clearCreateItem = () => {
  createItem.tenantName = ''
  createItem.information = ''
  createItem.clipFormats = ['hls']
}

const createTenant = async () => {
  try {
    await tenantStore.postTenants(createItem)

    showCreateDialog.value = false
    await getTenantList()

    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('テナントを追加しました。')
  } catch (error) {
    errors.hasError = true
    errors.title = '登録に失敗しました'
    errors.message = '入力内容をご確認の上、再度お試しください。'
  }
}
const editTenant = async () => {
  try {
    await tenantStore.putTenants(selectedItem.value)

    showEditDialog.value = false
    await getTenantList()
    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('テナントを更新しました。')
  } catch (error) {
    errors.hasError = true
    errors.title = '更新に失敗しました'
    errors.message = '入力内容をご確認の上、再度お試しください。'
  }
}

// Mounted
onMounted(async () => {
  const pageId = route.query.page
  page.value = pageId ? Number(pageId) : 1
  await getTenantList()
})
</script>

<style lang="scss"></style>
