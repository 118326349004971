export function createIdsMap(idMap: number[], from: number, to: number) {
  if (to > from) {
    for (let i = from + 1; i <= to; i++) {
      idMap[i]--
    }
    idMap[from] = to
  } else if (to < from) {
    for (let i = to; i <= from; i++) {
      idMap[i]++
    }
    idMap[from] = to
  }
  return idMap
}
