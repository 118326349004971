<template>
  <div class="container">
    <v-select
      v-model="localContent.streamingData.modes[0]"
      color="primary"
      label="モード選択"
      variant="underlined"
      :items="['FreeView', 'MultiSelect']"
      @update:model-value="changeRotation()"
    />
    <div class="text-h7">ユーザ操作</div>
    <v-checkbox
      v-model="allowRotationOnMultiSelect"
      :disabled="!isMultiSelectEnabled"
      color="primary"
      label="回転を許可する (MultiSelect)"
      hide-details
      @change="changeRotation()"
    />
    <v-checkbox
      v-model="localPlayerContent.userPlayable"
      color="primary"
      label="再生操作を許可する"
      hide-details
      @change="change()"
    />
    <v-checkbox
      v-model="localPlayerContent.userStepControl"
      color="primary"
      label="コマ送りを許可する"
      hide-details
      @change="change()"
    />
    <v-checkbox
      v-model="localPlayerContent.userTransformable"
      color="primary"
      label="移動・スケールを許可する"
      hide-details
      @change="change()"
    />
    <v-checkbox
      v-model="localPlayerContent.showGroupMenu"
      color="primary"
      label="グループ選択UIを表示"
      hide-details
      @change="change()"
    />
    <v-checkbox
      v-model="localPlayerContent.loop"
      color="primary"
      label="初期状態でループ再生"
      hide-details
      @change="change()"
    />
    <v-checkbox
      v-model="localPlayerContent.alwaysDisplayTimeCode"
      color="primary"
      label="時刻を常に表示"
      hide-details
      @change="change()"
    />

    <div class="text-h7 mb-2 mt-6">画面設定</div>
    <v-text-field
      v-model.number="localPlayerContent.globalScale"
      min="0.01"
      color="primary"
      label="動画全体の拡大率"
      variant="underlined"
      placeholder="スケール値"
      step="0.01"
      type="number"
      hide-details
      class="mb-4"
      @change="change()"
    />
    <v-text-field
      v-model.number="localPlayerContent.thumbnailCount"
      :disabled="!isMultiSelectEnabled"
      color="primary"
      label="サムネイル数"
      variant="underlined"
      placeholder="MultiSelectでのサムネイル表示量を指定"
      step="1"
      type="number"
      hide-details
      class="mb-8"
      @change="change"
    />
    <!-- TODO:HEXの変換できるようにしたい -->
    <div class="text-h7 mb-2">背景色設定(RGB)</div>
    <div class="d-flex">
      <v-text-field
        v-model.number="localPlayerContent.backgroundColor[0]"
        color="red"
        label="Red"
        variant="underlined"
        step="1"
        type="number"
        hide-details
        class="mr-2"
        @change="change()"
      />
      <v-text-field
        v-model.number="localPlayerContent.backgroundColor[1]"
        color="green"
        label="Green"
        variant="underlined"
        step="1"
        type="number"
        hide-details
        class="mr-2"
        @change="change()"
      />
      <v-text-field
        v-model.number="localPlayerContent.backgroundColor[2]"
        color="blue"
        label="Blue"
        variant="underlined"
        step="1"
        type="number"
        hide-details
        @change="change()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, PropType } from 'vue'
import { ContentSettingsLightPlayer, PlayerOptions } from '@2501world/lightplayer-types'
import { EditableContentFormat } from '../../types/EditableContentFormat'

const props = defineProps({
  content: {
    type: Object as PropType<ContentSettingsLightPlayer>,
    required: true
  }
})

interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()

const localContent = ref<Partial<EditableContentFormat>>(props.content)

const localPlayerContent = ref<Partial<PlayerOptions>>({
  allowRotation: true,
  thumbnailCount: props.content.streamingData.playerOptions.thumbnailCount
    ? props.content.streamingData.playerOptions.thumbnailCount
    : props.content.streamingData.videos.length,
  globalScale: 1,
  frameScale: 1,
  backgroundColor: [1, 1, 1],
  userPlayable: true,
  userStepControl: true,
  userTransformable: true,
  showGroupMenu: true,
  loop: false,
  alwaysDisplayTimeCode: false,
  ...props.content.streamingData.playerOptions
})

const userPlayable = ref<boolean>(true)
const allowRotationOnMultiSelect = ref<boolean>(false)

if (
  Array.isArray(localContent.value.streamingData?.modes) &&
  localContent.value.streamingData.modes[0] === 'MultiSelect'
) {
  allowRotationOnMultiSelect.value = localPlayerContent.value.allowRotation
    ? localPlayerContent.value.allowRotation
    : false
}

const isMultiSelectEnabled = computed((): boolean => {
  return Array.isArray(localContent.value.streamingData?.modes)
    ? localContent.value.streamingData.modes[0] === 'MultiSelect'
    : true
})

watch(
  () => props.content,
  obj => {
    localContent.value = obj
    userPlayable.value =
      localContent.value.streamingData?.playerOptions?.userPlayable ?? userPlayable.value
  }
)

const changeRotation = () => {
  if (
    !localContent.value.streamingData?.modes &&
    !Array.isArray(localContent.value.streamingData?.modes)
  ) {
    return
  }

  switch (localContent.value.streamingData.modes[0]) {
    case 'MultiSelect':
      localPlayerContent.value.allowRotation = allowRotationOnMultiSelect.value
      break
    case 'FreeView':
      localPlayerContent.value.allowRotation = true
      break
  }

  change()
}

const change = () => {
  if ((localPlayerContent.value.thumbnailCount as any) === '') {
    localPlayerContent.value.thumbnailCount = undefined
  }
  if ((localPlayerContent.value.globalScale as any) === '') {
    localPlayerContent.value.globalScale = undefined
  }

  if (localContent.value.streamingData) {
    localContent.value.streamingData.playerOptions = localPlayerContent.value
  }
  emit('change', localContent.value)
}
</script>

<style scoped></style>
