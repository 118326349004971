<template>
  <div class="clips">
    <div class="clips__items">
      <ClipCard
        v-for="(clip, index) in clips"
        id="clips"
        :key="index"
        class="clips__item"
        :status="clip.jobStatus"
        :clip-id="clip.clipId"
        :title="clip.title"
        :duration="clip.duration"
        :created="clip.createdAt"
        :is-tenant="isTenantRoleNow"
        @delete="showDeleteConfirmDialog(clip)"
      />
      <InfiniteLoading target="#clips" :identifier="clips" @infinite="load">
        <template #complete>
          <span />
        </template>
        <template #spinner>
          <v-progress-circular class="clips__spinner" color="primary" indeterminate />
        </template>
      </InfiniteLoading>
    </div>

    <!-- 固定ボタン -->
    <div class="clips__actions">
      <button class="clips__action-button clips__action-button--sort" @click="sortCreatedAt">
        <v-icon v-if="clipStore.isDescClips" icon="mdi-sort-ascending" color="white" />
        <v-icon v-else icon="mdi-sort-descending" color="white" />
      </button>
      <template v-if="isTenantRoleNow">
        <input
          id="fileUpload"
          ref="inputFileRef"
          type="file"
          multiple
          class="clips__upload-input"
          accept=".mp3, .mp4, .mov, .jpeg, .jpg, .png, .webp, .json, .yaml, .yml"
          @change="uploadFiles"
        />
        <button
          class="clips__action-button clips__action-button--upload"
          @click="invokeUploadFiles"
        >
          <v-icon icon="mdi-plus" color="white" />
        </button>
      </template>
    </div>
    <!-- Clip削除 -->
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="560">
      <v-card>
        <v-toolbar title="クリップ削除" color="red" />
        <v-card-text class="pa-8 pb-2">
          <div class="d-flex justify-start align-center">
            <v-icon icon="mdi-alert-outline" color="red" />
            <p class="ml-2 text-red font-weight-bold">クリップを削除します。</p>
          </div>
          <v-list lines="two">
            <v-list-item key="クリップ名" title="クリップ名" :subtitle="selectedItem.title" />
            <v-list-item
              key="録画時間(秒) | 更新日"
              title="録画時間(秒) | 更新日"
              :subtitle="`${selectedItem.duration}秒 | ${dayjs(selectedItem.createdAt).format(
                'YYYY/M/D HH:mm'
              )}`"
            />
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn variant="outlined" size="large" min-width="120" @click="deleteDialog = false">
            キャンセル
          </v-btn>
          <v-btn color="red" size="large" variant="flat" min-width="120" @click="onDeleteClip">
            削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import InfiniteLoading from 'v3-infinite-loading'
import 'v3-infinite-loading/lib/style.css'
import { useAuthStore } from '@/stores/auth'
import { useClipStore } from '@/stores/clip'
import { useSnackbarStore } from '@/stores/snackbar'
import { useProgressDialogStore } from '@/stores/dialog/progress'
import axios from '@/common/axios'
import dayjs from '@/common/dayjs'
import { ClipType } from '@/types/ClipTypes'
import { VideosJsonTemplate } from '@/views/Clips/templates/VideosJsonTemplate'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const clipStore = useClipStore()
const snackbarStore = useSnackbarStore()
const progressStore = useProgressDialogStore()

const deleteDialog = ref(false)

const selectedItem = ref<ClipType | undefined>()
const clips = ref<ClipType[]>([])
const hasError = ref(false)
const errorTitle = ref('')
const errorMessage = ref('')
const inputFileRef = ref<HTMLImageElement>()
const cursor = ref<string>('')
const sorting = ref<boolean>(false)

type requestClipType = {
  tenantId: string
  projectId: string
  limit: number
  cursor?: string
  isDesc?: boolean
}

const load = async ($state?: any) => {
  if (!authStore.activeSubscription) {
    $state.error()
    hasError.value = true
    errorTitle.value = 'コンテンツを閲覧する権限がありません。'
    errorMessage.value = 'コンテンツを閲覧する権限がないため、管理者にお問合せください。'
  }

  const params: requestClipType = {
    tenantId: authStore.activeSubscription.tenantId,
    projectId: authStore.activeSubscription.projectId,
    limit: 50,
    isDesc: clipStore.isDescClips
  }

  if (cursor.value) params.cursor = cursor.value

  try {
    const { data } = await axios.get('/clips', { params })
    data.clips.map((clip: ClipType) => {
      clips.value.push(clip)
    })
    cursor.value = data.cursor
    if (!data.cursor) {
      $state.complete()
    } else {
      $state.loaded()
    }
  } catch (error) {
    $state.error()
    hasError.value = true
    errorTitle.value = 'コンテンツの取得に失敗しました。'
    errorMessage.value = 'コンテンツの取得に失敗にしました。再度お試しください。'
  }
}

const isTenantRoleNow = computed(() => {
  return authStore.activeSubscription.role === 'tenant'
})

const invokeUploadFiles = () => {
  if (inputFileRef.value) inputFileRef.value.click()
}

const uploadFiles = (e: Event) => {
  if (!(e.target instanceof HTMLInputElement && e.target.files)) {
    return
  }

  // clipStoreの中身を一回初期化しておく
  clipStore.clearUploadFiles()
  const files = Array.from(e.target.files).map(file =>
    file.name.toLowerCase() === 'videos.json'
      ? new File([file], file.name.toLowerCase(), { type: file.type })
      : file
  )

  if (
    !files.every(file => {
      const ext = file.name.split('.').pop() ?? ''
      return ext === ext.toLowerCase() || ext === ext.toUpperCase()
    })
  ) {
    showUploadErrorDialog('ファイルの拡張子は小文字または大文字に統一してください。')
    return
  }

  let videosJsonFile: File | undefined = files.find(d => d.name === 'videos.json')
  const clonedVideosJsonTemplate = structuredClone(VideosJsonTemplate)
  if (!videosJsonFile) {
    // NOTE：audioとvideoがアップロードファイルに含まれている場合はvideos.jsonに追加
    files.map(d => {
      const lowerCaseName = d.name.toLowerCase()
      if (lowerCaseName === 'audio.mp4' || lowerCaseName === 'audio.mp3') {
        clonedVideosJsonTemplate.audio_file = d.name
      } else if (
        lowerCaseName.split('.').pop() === 'mp4' ||
        lowerCaseName.split('.').pop() === 'mov'
      ) {
        clonedVideosJsonTemplate.video_files?.push(d.name)
      }
    })

    // NOTE：新しく用意したvideos.jsonをinput fileでアップロードしたものに追加しておく
    const videosJsonString = JSON.stringify(clonedVideosJsonTemplate, null, 2)

    // NOTE：新しく用意したvideos.jsonを配列にpushしておく
    videosJsonFile = new File([videosJsonString], 'videos.json', {
      type: 'application/json'
    })
    files.push(videosJsonFile)
  }

  // NOTE；videos.jsonとアップロード内容が一致しているかチェック
  const reader = new FileReader()

  reader.onload = (event: ProgressEvent<FileReader>) => {
    const videosData = JSON.parse(event.target?.result as string)

    try {
      validateFiles(videosData, files)
    } catch (e) {
      if (e instanceof Error) {
        showUploadErrorDialog(e.message)
      }
      return
    }

    clipStore.setUploadFiles(files)
    router.push('editor/default')
  }

  reader.readAsText(videosJsonFile)
}

const validateFiles = (videosData: any, files: File[]) => {
  if (videosData.audio_file && !files.find(d => d.name === videosData.audio_file)) {
    throw new Error(
      'audio_fileで指定されている音声ファイルが存在しません。アップロードの内容を再度ご確認ください。'
    )
  }

  if (
    !Array.isArray(videosData.video_files) ||
    (videosData.calibrationData && !Array.isArray(videosData.calibrationData))
  ) {
    throw new Error(
      'videos.jsonのフォーマットが誤っています。アップロードの内容を再度ご確認ください。'
    )
  }
  const selectedVideoFiles = videosData.video_files.filter((video: string) =>
    files.find(file => file.name === video)
  )
  if (selectedVideoFiles.length !== videosData.video_files.length) {
    throw new Error(
      'video_filesで指定されている動画ファイルの数と選択された動画ファイルが一致しません。アップロードの内容を再度ご確認ください。'
    )
  }

  const selectedCalibrationFiles = videosData.calibrationData?.filter((calibration: any) =>
    files.find(file => file.name === calibration?.url)
  )
  if (
    selectedCalibrationFiles &&
    selectedCalibrationFiles.length !== videosData.calibrationData.length
  ) {
    console.log({ selectedCalibrationFiles, calibrationData: videosData.calibrationData })
    throw new Error(
      'calibrationDataで指定されているキャリブレーションファイルと選択されたキャリブレーションファイルが一致しません。アップロードの内容を再度ご確認ください。'
    )
  } else if (videosData.ini_file && !files.find(file => file.name === videosData.ini_file)) {
    throw new Error(
      'ini_fileで指定されているキャリブレーションファイルが存在しません。アップロードの内容を再度ご確認ください。'
    )
  }

  return true
}

const showUploadErrorDialog = (message: string) => {
  hasError.value = true
  errorTitle.value = 'アップロードに失敗しました'
  errorMessage.value = message
}

const showDeleteConfirmDialog = (clip: ClipType) => {
  selectedItem.value = clip
  deleteDialog.value = true
}

const onDeleteClip = async () => {
  try {
    deleteDialog.value = false
    progressStore.setLoading(true)
    await axios.delete(`/clips/${selectedItem.value?.clipId}`)
    // 無限スクロールが発火しない少ない件数の時に、削除後に無限ローディングが2回発火してダブって表示されるバグ？があるので件数少ないときはリロードで一旦回避
    if (clips.value.length > 20) {
      clips.value = clips.value.filter(
        (clip: ClipType) => clip.clipId !== selectedItem.value?.clipId
      )
    } else {
      window.location.reload()
    }

    snackbarStore.setShowSnackbar(true)
    snackbarStore.setSnackbarText('コンテンツを削除しました。')
  } catch (error) {
    hasError.value = true
    errorTitle.value = '削除に失敗しました'
    errorMessage.value = 'コンテンツの削除に失敗しました。'
  } finally {
    progressStore.setLoading(false)
  }
}

const sortTypeString = (isDesc: boolean) => {
  return isDesc ? 'desc' : 'asc'
}

const sortCreatedAt = () => {
  if (sorting.value) return
  sorting.value = true
  clipStore.setIsDescClips(!clipStore.isDescClips)
  router.replace({ query: { sort: sortTypeString(clipStore.isDescClips) } })
  // NOTE: clipsの中身を空にしてcursorの初期化も必要
  cursor.value = ''
  clips.value = []
  sorting.value = false
}

onMounted(() => {
  if (route.query.sort) {
    const isDescValue = route.query.sort === 'desc' ? true : false
    clipStore.setIsDescClips(isDescValue)
  }
})
</script>

<style lang="scss">
#player-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 50px;
  height: 50px;
  z-index: 300; /* プレイヤーのoverlayクラスのz-indexよりも上にしておく。 */
}

.clips {
  width: 100%;
  height: 100%;
  padding: 0 0 10px;

  @include mq-up(tablet) {
    padding: 40px 80px;
  }

  &__spinner {
    width: 100%;
    @include flex(center);
    margin: 20px auto;
  }

  &__items {
    @include mq-up(tablet) {
      width: auto;
      @include flex(start, start);
      flex-wrap: wrap;
      gap: 20px;

      > div {
        flex: 100%;
        width: 100%;
      }
    }
  }
  &__item {
    width: 100%;
    @include mq-up(tablet) {
      width: calc(100% / 3 - 20px);
    }
    @media (min-width: 1281px) {
      width: calc(100% / 4 - 20px);
    }
    @media (min-width: 1441px) {
      width: calc(100% / 5 - 20px);
    }
    @media (min-width: 2001px) {
      width: calc(100% / 6 - 20px);
    }
  }
  &__actions {
    @include flex(end);
    gap: 12px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 10;

    @include mq-down(tablet--under) {
      flex-direction: column-reverse;
      gap: 8px;
      right: 12px;
      bottom: 12px;
    }
  }
  &__upload-input {
    display: none;
  }
  &__action-button {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    transition: 0.2s;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

    &:active {
      opacity: 0.8;
    }

    @include mq-up(tablet) {
      &:hover {
        opacity: 0.8;
      }
    }

    @include mq-down(tablet--under) {
      width: 40px;
      height: 40px;
    }

    &--upload {
      background: $color-primary;

      .v-icon {
        font-size: 42px;

        @include mq-down(tablet--under) {
          font-size: 28px;
        }
      }
    }

    &--sort {
      background: $color-tertiary;

      .v-icon {
        font-size: 34px;

        @include mq-down(tablet--under) {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
