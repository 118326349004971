import { VideosJsonFormat } from '@/views/Clips/Editor/types/EditableContentFormat'

export const VideosJsonTemplate: VideosJsonFormat = {
  name: 'テンプレート',
  description: 'テンプレート',
  startSec: 0,
  duration: 0,
  version: 1,
  audio_file: null,
  video_files: [],
  rotate360: false,
  cameraRotation: 'clockwise',
  initialCamera: 0,
  rotationTick: 360
}
