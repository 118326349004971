<template>
  <div class="container">
    <v-text-field
      v-model="addingDomain"
      type="text"
      placeholder="www.mycompany.com"
      label="アクセス制限設定"
      class="mb-8"
      color="primary"
      variant="solo"
      hide-details
    >
      <template #append>
        <v-btn icon="mdi-plus" color="primary" :disabled="!addingDomain" @click="addDomain" />
      </template>
    </v-text-field>
    <v-text-field
      v-for="(domain, i) in domainWhiteList"
      :key="i"
      v-model="domainWhiteList[i]"
      :value="domain"
      type="text"
      variant="solo"
      readonly
      hide-details
      class="mt-4"
    >
      <template #append>
        <v-btn icon="mdi-trash-can" variant="plain" color="red" @click="removeDomain(domain)" />
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, PropType } from 'vue'
import { EditableContentFormat } from '../../types/EditableContentFormat'

const props = defineProps({
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)
const addingDomain = ref<string>('')

const domainWhiteList = computed<Array<string>>(() => {
  if (localContent.value.streamingData?.domainFilter) {
    return localContent.value.streamingData?.domainFilter.domainWhiteList
  } else {
    return []
  }
})

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)

const addDomain = (): void => {
  const whiteListHas = (val: string): boolean => {
    const whiteList = localContent.value.streamingData?.domainFilter?.domainWhiteList
    return whiteList && whiteList.indexOf(val) >= 0 ? true : false
  }
  if (!addingDomain.value || whiteListHas(addingDomain.value) || !localContent.value.streamingData)
    return

  if (!localContent.value.streamingData?.domainFilter) {
    localContent.value.streamingData.domainFilter = { domainWhiteList: [] }
  }

  localContent.value.streamingData.domainFilter.domainWhiteList.push(addingDomain.value)
  addingDomain.value = ''
  change()
}
const removeDomain = (removingDomain: string): void => {
  if (localContent.value.streamingData?.domainFilter) {
    localContent.value.streamingData.domainFilter.domainWhiteList =
      localContent.value.streamingData?.domainFilter.domainWhiteList.filter(
        (domain: string) => removingDomain !== domain
      )
    if (localContent.value.streamingData.domainFilter.domainWhiteList.length === 0) {
      // フィルターに関する内容がない場合はdomainFilterフィールドそのものを削除する
      delete localContent.value.streamingData.domainFilter
    }
    change()
  }
}
interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()
const change = (): void => {
  emit('change', localContent.value)
}
</script>

<style scoped></style>
