<template>
  <v-container class="pa-8">
    <v-layout class="mb-8 d-flex align-center justify-space-between">
      <v-btn
        variant="text"
        color="primary"
        prepend-icon="mdi-arrow-left"
        class="ml-8"
        @click="close()"
      >
        <template #prepend>
          <v-icon color="primary" />
        </template>
        クリップ一覧に戻る
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!route.path.includes('/caption') && content.deliveryFormat !== 'download'"
        variant="text"
        color="primary"
        class="mr-2"
        @click="changeMode()"
      >
        {{ route.path.includes('/manual') ? '通常' : 'マニュアル' }}操作に切り替え
      </v-btn>
      <template v-if="!route.path.includes('/caption') && !editStatus.isNew">
        <v-btn
          v-if="content.deliveryFormat !== 'download'"
          class="mr-4"
          text="字幕編集"
          color="teal"
          @click="toEditCaption"
        />
        <v-btn
          v-if="content.deliveryFormat === 'download'"
          color="green"
          class="mr-4"
          text="QR"
          :disabled="!hasDownloadUrl"
          @click="showQR = true"
        />
        <v-btn
          v-if="!editStatus.isNew"
          class="mr-4"
          text="削除"
          color="red"
          @click="deleteContent"
        />
      </template>
      <template v-if="route.path.includes('/caption') && !editStatus.isNew">
        <v-btn class="mr-4" text="字幕編集を終了" color="teal" @click="router.back()" />
        <v-btn text="字幕を保存" color="primary" :disabled="!captionAlreadyEdited" @click="save" />
      </template>
      <v-btn
        v-else
        :text="editStatus.isNew ? '登録' : '保存'"
        color="primary"
        :disabled="content.title === '' || !editStatus.isChanged"
        @click="save"
        @change-delivery-format="onChangeDeliveryFormat"
      />
    </v-layout>
    <v-row no-gutters>
      <v-col class="ma-8 mt-0 mr-0">
        <router-view
          :is-new="editStatus.isNew"
          :content="content"
          @change="onChange"
          @change-caption="onChangeCaption"
          @change-delivery-format="onChangeDeliveryFormat"
        />
      </v-col>
      <v-col v-if="!editStatus.isNew && content.deliveryFormat !== 'download'" class="ml-8">
        <!-- TODO；縦長動画のプレビューに対応できてないので縦長どうかかどうかを元に判定できるようにする -->
        <div style="aspect-ratio: 16 / 9">
          <BulletLightPlayerComponent :json="contentModel" />
        </div>
        <div class="mt-4 mb-4 d-flex">
          <v-spacer />
          <v-btn
            v-if="route.path.includes('/caption')"
            color="teal"
            class="mr-4"
            text="字幕追加"
            @click="addCaptionAtPlayingTime"
          />
          <v-btn color="primary" text="プレビュー再読み込み" @click="loadEditingJsonToPlayer" />
        </div>
        <template v-if="!route.path.includes('/caption')">
          <div class="d-flex flex-wrap pl-0">
            <v-checkbox
              v-for="(item, key) in embedQs"
              :key="key"
              v-model="item.enabled"
              :label="item.label"
              class="mr-2"
              color="primary"
              hide-details
            />
          </div>

          <div class="text-h5 mb-5 mt-6">公開URL</div>
          <v-text-field v-model="embedUrl" variant="solo" readonly @click="copyUrl" />
          <div class="d-flex">
            <v-spacer />
            <v-btn
              v-if="content.clipId"
              text="開く"
              :href="embedUrl"
              target="_blank"
              color="primary"
              class="mr-4"
            />
            <v-btn v-if="content.clipId" class="mr-4" color="teal" text="コピー" @click="copyUrl" />
            <v-btn color="green" text="QR" :disabled="!hasDownloadUrl" @click="showQR = true" />
          </div>
        </template>
      </v-col>
    </v-row>
    <QRDialog
      v-if="hasDownloadUrl && downloadURL"
      :show="showQR"
      title="ダウンロード用QR"
      name="QRコード"
      :url="downloadURL"
      @hide="showQR = false"
    />
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
    <!-- ファイルアップロード時に使用 -->
    <v-dialog v-model="showUploadDialog" persistent max-width="290">
      <v-card color="white" class="pa-4 pb-6">
        <v-card-text class="pl-0 pr-0">
          <p class="text-center">ファイルアップロード中</p>
          <v-progress-linear height="6" indeterminate color="primary" striped class="mb-2 mt-4" />
          <v-card-text class="text-small text-center">※画面を閉じないでください。</v-card-text>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSnackbarStore } from '@/stores/snackbar'
import { useProgressDialogStore } from '@/stores/dialog/progress'
import { useClipStore } from '@/stores/clip'
import { ref, computed, onMounted } from 'vue'
import axios from '@/common/axios'

import { LightPlayerContentFormat_v1_1, MediaSource } from '@2501world/lightplayer-types'
import { ContentUtils, optionDefaultSetting } from './utils/ContentUtils'
import { calcTileSize } from './utils/OtherUtils'
import { EditorContentLoader } from './lib/ContentsLoader'
import {
  EditableContentFormat,
  ClipFormDataFormat,
  GenerateFile,
  UploadFile
} from './types/EditableContentFormat'

import { BulletLightPlayerComponent } from '@2501world/lightplayer-vue'
import { ContentLoader } from '@2501world/lightplayer-lib'
import '@2501world/lightplayer-vue/dist/style.css' //プレイヤーのCSSをロード

import { S3Client } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'

const router = useRouter()
const route = useRoute()

const authStore = useAuthStore()
const snackbarStore = useSnackbarStore()
const progressStore = useProgressDialogStore()
const clipStore = useClipStore()

interface EditStatus {
  isNew: boolean
  isChanged: boolean
}

const thumbnailFile = ref<any>()

const showUploadDialog = ref<boolean>(false)

const clipIdInDb = ref<any>()
const deliveryFormatType = ref<string>('all')

const content = ref<EditableContentFormat>(
  ContentUtils.createEmptyContent(
    authStore.activeSubscription.tenantId,
    authStore.activeSubscription.projectId
  ) as EditableContentFormat
)
const playerStreamingData = ref<LightPlayerContentFormat_v1_1>()
const editStatus = ref<EditStatus>({
  isNew: true,
  isChanged: false
})
const captionAlreadyEdited = ref<boolean>(false)
interface EmbedOption {
  enabled: boolean
  label: string
}
const embedQs = ref<Record<string, EmbedOption>>({
  autoLoad: { enabled: false, label: '事前読込' },
  autoStart: { enabled: false, label: '自動再生' },
  hideControls: { enabled: false, label: 'コントロール非表示' },
  posterOnly: { enabled: false, label: 'サムネイル表示のみ' }
})

const hasDownloadUrl = ref<boolean>(false)
const downloadURL = ref<string>('')
const showQR = ref<boolean>(false)

const hasError = ref<boolean>(false)
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')

const embedUrl = computed((): string => {
  const baseUrl = import.meta.env.VITE_APP_URL_BASE
  const paramsString = Object.keys(embedQs.value)
    .filter(paramKey => embedQs.value[paramKey].enabled)
    .map(paramKey => `${paramKey}=1`)
    .concat(`clipId=${clipIdInDb.value}`)
    .sort((a, b) => a.length - b.length)
    .join('&')
  return `${baseUrl}/player?${paramsString}`
})

const fetchClipDetail = async (clipId: string) => {
  const { data } = await axios.get(`/clips/${clipId}`, {
    withCredentials: true
  })
  if (data.downloadingData) hasDownloadUrl.value = true
  downloadURL.value = `https://${import.meta.env.VITE_APP_MEDIA_DOMAIN}/private/${clipId}/videos.json`
  return data
}

const changeMode = () => {
  if (clipIdInDb.value) {
    route.path.includes('/manual')
      ? router.replace(`/editor/default?clipId=${clipIdInDb.value}`)
      : router.replace(`/editor/manual?clipId=${clipIdInDb.value}`)
  } else {
    route.path.includes('/manual')
      ? router.replace('/editor/default')
      : router.replace('/editor/manual')
  }
}

const saveNewClip = async (content: EditableContentFormat): Promise<any> => {
  const clipFormData: ClipFormDataFormat = {
    tenantId: content.tenantId,
    projectId: content.projectId,
    status: content.status,
    title: content.title,
    description: content.description,
    file: content.file ?? { uploads: [], generates: [] }
  }

  if (deliveryFormatType.value === 'all') {
    clipFormData.streamingData = content.streamingData
    clipFormData.downloadingData = content.downloadingData
  } else if (deliveryFormatType.value === 'streaming') {
    clipFormData.streamingData = content.streamingData
  } else if (deliveryFormatType.value === 'download') {
    clipFormData.downloadingData = content.downloadingData
  }

  const result = await axios.post('/clips', clipFormData)
  const contentInBackendDb = result.data
  return contentInBackendDb
}
const renewClip = async (content: EditableContentFormat, clipId: string) => {
  await axios.put(`/clips/${clipId}`, content)
}
const deleteClip = async (clipId: string) => {
  await axios.delete(`/clips/${clipId}`)
}

const onChange = (obj: any) => {
  if (route.path.includes('/caption')) return
  content.value = obj
  editStatus.value.isChanged = true
  syncDuplicatedFields(obj)
}
const syncDuplicatedFields = (content: EditableContentFormat) => {
  // title, description は json.title, json.streamingData.title, json.downloadingData.title という様に3つも重複している。titleとdescriptionへの変更はstreamingData直下のそれが変わるため、残りの他2つに同期をかける
  if (content.streamingData?.title) {
    content.streamingData.title = content.title
    content.streamingData.description = content.description
  }
  if (content.downloadingData) {
    content.downloadingData.title = content.title
    content.downloadingData.description = content.description
  }
}
const onChangeCaption = () => {
  captionAlreadyEdited.value = true
}

const makeVideoData = (type: string) => {
  deliveryFormatType.value = type
  console.log(type)

  const generateFiles: GenerateFile[] = []
  content.value.streamingData.sources = []

  if (type === 'all' || type === 'streaming') {
    makeTiledVideoData(generateFiles)
    makeAudioData(generateFiles)
  }

  // TODO: 画質/速度優先モード実装時に画質優先モードの場合はisHybridがtrueになるよう修正する
  const isHybrid: boolean =
    content.value.streamingData.sources.filter(s => s.type === 'tiled').length === 1

  const videoFiles = content.value.downloadingData.video_files
  videoFiles.map((video: string) => {
    if (type === 'all' || type === 'streaming') {
      if (isHybrid) {
        const ext = video.split('.').pop() ?? ''
        generateFiles.push({
          fileName: video.replace(ext, 'm3u8'),
          fileType: 'singleVideo',
          sources: [video]
        })
        content.value.streamingData.sources.push({
          fileName: video.replace(ext, 'm3u8'),
          type: 'single',
          url: '',
          mediaType: 'video',
          mediaFormat: 'hls'
        })
      }
    }
    if (type === 'all' || type === 'download') {
      generateFiles.push({
        fileName: video,
        fileType: 'singleVideo',
        sources: [video]
      })
    }
  })

  if (!thumbnailFile.value) {
    generateFiles.push({
      fileName: 'thumbnail.jpg',
      fileType: 'thumbnail',
      sources: [videoFiles[0]]
    })
  }

  content.value.file = {
    uploads: content.value.file?.uploads,
    generates: generateFiles
  }
}

const makeTiledVideoData = (generateFiles: GenerateFile[]) => {
  const videoFiles = content.value.downloadingData.video_files
  const viewPoints: number = videoFiles.length
  const maxGridsPerTile: number = 16 as const
  const tileCount: number = Math.ceil(viewPoints / maxGridsPerTile)

  const tileSize: { tileX: number; tileY: number } | undefined = calcTileSize(viewPoints, tileCount)

  let from = 0
  for (let i = 1; i <= tileCount; i++) {
    const fileName = `tiling${i.toString().padStart(2, '0')}.m3u8`

    content.value.streamingData.sources.push({
      fileName,
      type: 'tiled',
      tileX: tileSize ? tileSize.tileX : 0,
      tileY: tileSize ? tileSize.tileY : 0,
      mediaFormat: 'hls',
      url: '',
      mediaType: 'video'
    })

    const to = from + Math.ceil(viewPoints / tileCount)
    generateFiles.push({
      fileName,
      fileType: 'tiledVideo',
      sources: videoFiles.slice(from, to)
    })

    const tiledVideoSource = content.value.streamingData.sources.find(d => d.fileName === fileName)
    const firstGridFile = clipStore.uploadFiles?.find(d => d.name === videoFiles[from])
    if (firstGridFile && tiledVideoSource) {
      setGridAscpect(firstGridFile, tiledVideoSource)
    }

    from = to
  }
}

const makeAudioData = (generateFiles: GenerateFile[]) => {
  const audioFile = content.value.downloadingData.audio_file
  if (audioFile) {
    const hlsAudioFile = audioFile.replace(audioFile.split('.').pop(), 'm3u8')
    generateFiles.push({
      fileName: hlsAudioFile,
      fileType: 'audio',
      sources: [audioFile]
    })
    content.value.streamingData.sources.push({
      fileName: hlsAudioFile,
      type: 'single',
      url: '',
      mediaType: 'audio',
      mediaFormat: 'hls'
    })
    content.value.streamingData.audios = [
      {
        sourceId: content.value.streamingData.sources.length - 1,
        offsetTime: 0
      }
    ]
  }
}

const setGridAscpect = (gridVideoFile: File, tileSource: any) => {
  const video = document.createElement('video')
  const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a)
  video.onloadedmetadata = () => {
    const aspectRatioGcd = gcd(video.videoWidth, video.videoHeight)
    tileSource.aspect = [video.videoWidth / aspectRatioGcd, video.videoHeight / aspectRatioGcd]
    video.remove()
  }
  video.src = URL.createObjectURL(gridVideoFile)
}

const onChangeDeliveryFormat = (type: string) => makeVideoData(type)

const save = async () => {
  progressStore.setLoading(true)
  try {
    if (!editStatus.value) return

    if (editStatus.value.isNew) {
      const { registeredClipData, s3UploadCredential } = await saveNewClip(content.value)

      content.value = registeredClipData

      showUploadDialog.value = true

      const excludeVideosJson = (clipStore.uploadFiles || []).filter(f => f.name !== 'videos.json')

      // ファイルのアップロードを実施
      for (const [index, file] of Object.entries(excludeVideosJson)) {
        const multipartUpload = new Upload({
          client: new S3Client({
            region: 'ap-northeast-1',
            credentials: {
              accessKeyId: s3UploadCredential.accessKeyId,
              secretAccessKey: s3UploadCredential.secretAccessKey,
              sessionToken: s3UploadCredential.sessionToken
            }
          }),
          params: {
            Bucket: s3UploadCredential.s3BucketName,
            Key: s3UploadCredential.s3Key + file.name,
            Body: file.stream()
          }
        })
        multipartUpload.on('httpUploadProgress', (progress: any) => {
          console.log(progress)
        })
        await multipartUpload.done()

        if (Number(index) === Array.from(excludeVideosJson).length - 1) {
          editStatus.value.isNew = false
          editStatus.value.isChanged = false
          showUploadDialog.value = false
          clipIdInDb.value = content.value.clipId
          clipStore.clearUploadFiles()
          route.path.includes('/manual')
            ? window.location.replace(`/editor/manual?clipId=${clipIdInDb.value}`)
            : window.location.replace(`/editor/default?clipId=${clipIdInDb.value}`)
          snackbarStore.setShowSnackbar(true)
          snackbarStore.setSnackbarText('アップロードが完了しました。')
        }
      }
    } else {
      await renewClip(content.value, content.value.clipId)
      editStatus.value.isChanged = false

      snackbarStore.setShowSnackbar(true)
      snackbarStore.setSnackbarText('コンテンツを更新しました。')
    }
  } catch (error) {
    console.log('upload error', JSON.stringify(error, null, 4), error)
    showUploadDialog.value = false
    hasError.value = true
    errorTitle.value = '更新に失敗しました'
    errorMessage.value = 'コンテンツの更新に失敗しました。'
  } finally {
    progressStore.setLoading(false)
    captionAlreadyEdited.value = false
  }
}

const deleteContent = async () => {
  if (
    !confirm(
      `[${content.value.clipId}] ${content.value.title} を削除します。\nこの操作は取り消せません。\n実行しますか？`
    )
  ) {
    return
  }

  await deleteClip(content.value.clipId)
  router.push({ name: 'contents' })
}
const close = () => {
  if (editStatus.value.isChanged) {
    if (!confirm('変更を保存せずに閉じますか？')) {
      return
    }
  }
  router.push({ name: 'contents' })
}

const copyUrl = () => {
  navigator.clipboard.writeText(embedUrl.value)
  snackbarStore.setShowSnackbar(true)
  snackbarStore.setSnackbarText('URLをコピーしました。')
}

const contentModel = ref<LightPlayerContentFormat_v1_1>()

const loadEditingJsonToPlayer = async () => {
  contentModel.value = await ContentLoader.conformContent(playerStreamingData.value)
}

const toEditCaption = () =>
  router.push({ path: '/editor/caption', query: { clipId: clipIdInDb.value } })

onMounted(async () => {
  clipIdInDb.value = route.query.clipId

  if (clipIdInDb.value) {
    await loadClip()
  } else {
    createClip()
  }
})

const removeQueryString = (url: string) => url.split('?')[0]
const removeQueryStringUrl = () => {
  content.value.downloadingData && updateDownloadingData(content.value.downloadingData)
  content.value.streamingData && updateStreamingData(content.value.streamingData)
}

const updateDownloadingData = (downloadingData: any) => {
  if (downloadingData.audio_file) {
    downloadingData.audio_file = removeQueryString(downloadingData.audio_file)
  }

  if (downloadingData.video_files && downloadingData.video_files.length > 0) {
    downloadingData.video_files = downloadingData.video_files.map(removeQueryString)
  }

  if (downloadingData.calibrationData && downloadingData.calibrationData.length > 0) {
    downloadingData.calibrationData = downloadingData.calibrationData.map(
      (data: { description: string; url: string }) => ({
        ...data,
        url: removeQueryString(data.url)
      })
    )
  }
}

const updateStreamingData = (streamingData: any) => {
  if (streamingData.poster) {
    streamingData.poster = removeQueryString(streamingData.poster)
  }

  if (streamingData.transforms && streamingData.transforms.length > 0) {
    streamingData.transforms = streamingData.transforms.map(
      (transform: { name: string; transforms: { url: string } }) => ({
        ...transform,
        transforms: {
          url: removeQueryString(transform.transforms.url)
        }
      })
    )
  }

  if (streamingData.sources && streamingData.sources.length > 0) {
    streamingData.sources = streamingData.sources.map((source: MediaSource) => ({
      ...source,
      url: removeQueryString(source.url)
    }))
  }
}

const loadClip = async () => {
  const data = await fetchClipDetail(clipIdInDb.value)
  content.value = data
  if (data.streamingData) {
    playerStreamingData.value = data.streamingData

    // NOTE: conformContent()は、プレイヤーにJSONを渡すことで再生する直前にさえ実施していればよい。
    // ここでconformContent()する意味は、JSONへの再生前処理を予め実施し、
    // その処理済みのJSONをバックエンドのDBに格納したいということ。
    // もしあえてこの処理をしたくない場合、conformContent()をコメントアウトまたは削除する。
    playerStreamingData.value = await EditorContentLoader.conformContent(data.streamingData)
  }

  // NOTE：不要なqueryStringの削除
  removeQueryStringUrl()

  editStatus.value.isNew = false
  editStatus.value.isChanged = false

  // stramingDataとdonwloadingDataの存在チェックをして配信形式に反映
  if (
    Object.prototype.hasOwnProperty.call(data, 'streamingData') &&
    Object.prototype.hasOwnProperty.call(data, 'downloadingData')
  ) {
    content.value.deliveryFormat = 'all'
  } else if (
    Object.prototype.hasOwnProperty.call(data, 'streamingData') &&
    !Object.prototype.hasOwnProperty.call(data, 'downloadingData')
  ) {
    content.value.deliveryFormat = 'streaming'
  } else {
    content.value.deliveryFormat = 'download'
  }
  // streamingData.closedCaptionsが存在しない場合はパラメーター追加
  if (
    Object.prototype.hasOwnProperty.call(data, 'streamingData') &&
    !Object.prototype.hasOwnProperty.call(data, 'downloadingData') &&
    !data.streamingData
  ) {
    data.streamingData.closedCaptions = []
  }

  if (data.streamingData) loadEditingJsonToPlayer()
}

const createClip = () => {
  if (!clipStore.uploadFiles) {
    return
  }

  const files = clipStore.uploadFiles

  const videosJsonFile: any = files.find(d => d.name === 'videos.json')

  thumbnailFile.value = files.find(d => d.name.match(/\.(jpg|png|webp)$/i) !== null)

  const reader = new FileReader()

  reader.onload = (event: ProgressEvent<FileReader>) => {
    content.value.downloadingData = JSON.parse(event.target?.result as string)

    makeCalibrationData()
    setUploadFiles(files)

    // uploads用のデータ成形
    makeVideoData(deliveryFormatType.value)

    makeTrack()
    makeGroup()
    makePlayerOptions()
  }

  reader.readAsText(videosJsonFile)
}

const setUploadFiles = (files: File[]) => {
  const uploads: UploadFile[] = []
  const audioFile = files.find(d => d.name === content.value.downloadingData.audio_file)
  if (audioFile) {
    uploads.push({
      fileName: audioFile.name,
      fileType: 'audio'
    })
  }

  content.value.downloadingData.video_files.forEach((video: string) => {
    if (files.some(d => d.name === video)) {
      uploads.push({
        fileName: video,
        fileType: 'singleVideo'
      })
    }
  })

  const uploadCalibrations =
    (content.value.downloadingData.calibrationData
      ?.map((calibration: any): File | undefined => files.find(d => d.name === calibration.url))
      .filter((calibration: File) => !!calibration) as File[]) ?? []
  uploadCalibrations.forEach((calibration: any) =>
    uploads.push({
      fileName: calibration.name,
      fileType: 'calibration'
    })
  )

  if (thumbnailFile.value) {
    uploads.push({
      fileName: thumbnailFile.value.name,
      fileType: 'thumbnail'
    })
  }
  content.value.file = {
    uploads: uploads,
    generates: content.value.file?.generates
  }
}

const makeCalibrationData = () => {
  if (!content.value.downloadingData.ini_file && !content.value.downloadingData.calibrationData) {
    return
  }

  // ini_fileはdeprecatedのためcalibrationDataとini_fileが両方存在する場合はcalibrationDataを優先とし、
  // ini_fileのみの場合はini_fileをcalibrationDataに変換して登録する。
  if (content.value.downloadingData.calibrationData) {
    delete content.value.downloadingData.ini_file
  } else if (content.value.downloadingData.ini_file) {
    content.value.downloadingData.calibrationData = [
      {
        description: content.value.downloadingData.ini_file,
        url: content.value.downloadingData.ini_file
      }
    ]
    delete content.value.downloadingData.ini_file
  }

  content.value.streamingData.transforms = content.value.downloadingData.calibrationData.map(
    (data: any) => ({
      name: data.description,
      transforms: {
        url: data.url
      },
      fileName: data.url
    })
  )
}

const makeTrack = () => {
  const sourceIds = content.value.streamingData.sources.reduce(
    (ids, source, i) => {
      if (source.type === 'single' && source.mediaType === 'video') {
        ids.video.push(i)
      } else if (source.type === 'tiled') {
        ids.tiled.push(i)
      }
      return ids
    },
    {
      video: [] as number[],
      tiled: [] as number[]
    }
  )
  // TODO: 画質/速度優先モード実装時に「画質優先モードの場合はisHybridがtrue」になるよう修正する
  const isHybrid: boolean = sourceIds.tiled.length === 1
  const videoFiles = content.value.downloadingData.video_files
  const maxTilePosition = Math.ceil(videoFiles.length / sourceIds.tiled.length)

  let tileIndex = 0
  content.value.streamingData.videos = videoFiles.map((_video: string, i: number) => {
    const single = isHybrid
      ? {
          sourceId: sourceIds.video[i],
          tilePosition: 0
        }
      : null
    const tiled = {
      sourceId: sourceIds.tiled[tileIndex],
      tilePosition: i - maxTilePosition * tileIndex
    }
    const transformId = i

    if ((i + 1) % maxTilePosition === 0) {
      tileIndex++
    }

    return { single, tiled, transformId }
  })
}

const makeGroup = () => {
  content.value.streamingData.groups[0] = {
    isDefault: true,
    cyclic: true,
    defaultVideoId: 0,
    videoIds: content.value.downloadingData.video_files.map((_video: string, i: number) => i)
  }

  // videos.jsonの内容を元にgroupタブ内のデータ一部変更
  if (content.value.downloadingData.cameraRotation === 'clockwise') {
    content.value.streamingData.groups[0].videoIds.reverse()
  }

  content.value.streamingData.groups[0].cyclic = !!content.value.downloadingData.rotate360
}

const makePlayerOptions = () => {
  if (!content.value.streamingData.playerOptions.thumbnailCount) {
    content.value.streamingData.playerOptions.thumbnailCount =
      content.value.streamingData.videos.length
  }
}

const timeToSeconds = (timeString: string | null | undefined) => {
  if (timeString) {
    const [minutes, seconds] = timeString.split(':').map(Number)
    return minutes * 60 + seconds
  } else {
    return 0
  }
}

const addCaptionAtPlayingTime = () => {
  const iframeTimeElement = document.querySelector('.controls .time time')

  if (content.value.streamingData.closedCaptions[0]?.captions) {
    content.value.streamingData.closedCaptions[0].captions.push({
      time: timeToSeconds(iframeTimeElement?.textContent),
      duration: 0,
      label: ''
    })
  } else {
    content.value.streamingData.closedCaptions[0] = {
      lang: 'ja',
      isDefault: true,
      options: optionDefaultSetting,
      captions: []
    }
    content.value.streamingData.closedCaptions[0].captions.push({
      time: timeToSeconds(iframeTimeElement?.textContent),
      duration: 1,
      label: ''
    })
  }
}
</script>

<style lang="scss"></style>
