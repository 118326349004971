<template>
  <v-container>
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="パスワードリセット" color="indigo" />
      <v-form ref="form" class="pa-8" @submit.prevent="onReset()">
        <p class="mb-8">ログイン登録済の「メールアドレス」を入力後「送信」してください。</p>
        <v-text-field v-model="mailAddress" label="メールアドレス" :rules="mailAddressRules" />
        <v-row class="justify-center mt-4">
          <v-col cols="auto">
            <v-btn to="/login" class="ml-1" varient="outlined" size="large" min-width="120">
              戻る
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" class="ml-1" size="large" min-width="120" type="submit">
              送信
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="onClose(false)"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { Auth } from 'aws-amplify'
import { useProgressDialogStore } from '@/stores/dialog/progress'

const router = useRouter()
const progressStore = useProgressDialogStore()

const mailAddress = ref<string>('')
const mailAddressRules = ref<Array<(_v: string) => boolean | string>>([
  v => !!v || 'メールアドレスを入力してください',
  v => /.+@.+\..+/.test(v) || 'メールアドレスが正しい形式ではありません'
])
const hasError = ref<boolean>(false)
const errorTitle = ref<string>('')
const errorMessage = ref<string>('')

const onReset = async (): Promise<void> => {
  Auth.forgotPassword(mailAddress.value)
    .then(() => {
      progressStore.setLoading(true)
      router.push(`/password/confirm?mailAddress=${mailAddress.value}`)
    })
    .catch(error => {
      if (`${error}`.match('LimitExceededException')) {
        hasError.value = true
        errorTitle.value = '送信に失敗しました'
        errorMessage.value =
          'メール送信の上限に達しました。しばらく時間をおいてもう一度お試しください。'
      }
    })
    .finally(() => {
      progressStore.setLoading(false)
    })
}
const onClose = (bool: boolean): void => {
  hasError.value = bool
  errorTitle.value = ''
  errorMessage.value = ''
}
</script>
