<template>
  <v-container class="d-flex align-center flex-column">
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="パスワード変更" color="indigo" />
      <v-form class="pa-8" @submit.prevent="onChange()">
        <p class="mb-8">
          確認のため「現在のパスワード」と「新しいパスワード」を入力して「送信」してください。
        </p>
        <v-text-field
          v-model="oldPassword"
          label="現在のパスワード"
          class="mb-2"
          :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showOldPassword ? 'text' : 'password'"
          :rules="passwordRules"
          autocomplete="on"
          @click:append="showOldPassword = !showOldPassword"
        />
        <v-text-field
          v-model="newPassword"
          label="新しいパスワード"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          :rules="passwordRules"
          autocomplete="on"
          @click:append="showNewPassword = !showNewPassword"
        />
        <v-row class="justify-center mt-4">
          <v-col cols="auto">
            <v-btn color="primary" class="ml-1" size="large" min-width="120" type="submit">
              送信
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-dialog v-model="success" persistent width="300">
      <v-card>
        <v-card-title>パスワード変更に成功しました</v-card-title>
        <v-card-actions>
          <v-btn to="/">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ErrorDialog
      :error="hasError"
      title="変更に失敗"
      :text="errorMessage"
      @close="onClose(false)"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { Auth } from 'aws-amplify'
import { useProgressDialogStore } from '@/stores/dialog/progress'

const progressStore = useProgressDialogStore()

const oldPassword = ref<string>('')
const newPassword = ref<string>('')
const showOldPassword = ref<boolean>(false)
const showNewPassword = ref<boolean>(false)
const success = ref<boolean>(false)
const hasError = ref<boolean>(false)
const errorMessage = ref<string>('')
const passwordRules = ref<Array<(_v: string) => boolean | string>>([
  v => !!v || 'パスワードを入力してください',
  v => (v && v.length >= 8) || 'パスワードは8文字以上の半角英数字を設定してください'
])

const onClose = (bool: boolean): void => {
  hasError.value = bool
  errorMessage.value = ''
}
const onChange = async (): Promise<void> => {
  try {
    progressStore.setLoading(true)
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword.value, newPassword.value)
    success.value = true
  } catch (error) {
    if (`${error}`.match('NotAuthorizedException')) {
      hasError.value = true
      errorMessage.value = '現在のパスワードが正しくありません。'
    } else {
      hasError.value = true
      errorMessage.value = 'パスワード変更に失敗しました。'
    }
  } finally {
    progressStore.setLoading(false)
  }
}
</script>
