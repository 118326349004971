import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/common/axios'
import dayjs from '@/common/dayjs'

export const useTenantStore = defineStore('tenant', () => {
  // State
  const tenantId = ref<string>('')
  const tenants = ref<Array<any>>([])

  // Getters
  const getTenantId = () => {
    return tenantId.value
  }

  // Actions
  const getTenants = async (tenantId: string | string[], projectId: string) => {
    try {
      const { data } = await axios.get('/tenants', {
        params: {
          tenantId: tenantId,
          projectId: projectId
        }
      })
      tenants.value = data.tenants.sort((a: any, b: any) => {
        return dayjs(a.createdAt).format('YYYY-MM-DD HH:mm:ss') >
          dayjs(b.createdAt).format('YYYY-MM-DD HH:mm:ss')
          ? -1
          : 1
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const postTenants = async (tenant: object) => {
    try {
      return await axios.post('tenants', { ...tenant })
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const putTenants = async (tenant: any) => {
    try {
      return await axios.put(`tenants/${tenant.tenantId}`, { ...tenant })
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const putTenantID = (id: string) => {
    tenantId.value = id
  }

  return {
    tenantId,
    tenants,
    getTenantId,
    getTenants,
    postTenants,
    putTenants,
    putTenantID
  }
})
