import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/common/axios'

export const useProjectStore = defineStore('project', () => {
  // State
  const newProjectId = ref<string>('')
  const projects = ref<Array<any>>([])

  // Actions
  const getProjects = async (id: string) => {
    try {
      const { data } = await axios.get(`/tenants/${id}/projects`)
      projects.value = data.projects
      return projects.value
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const postProject = async (projectName: string, id: string) => {
    try {
      const { data } = await axios.post(`/tenants/${id}/projects`, {
        projectName: projectName
      })
      newProjectId.value = data.projectId
    } catch (error) {
      return Promise.reject(error)
    }
  }
  const putProject = async (project: any, id: string) => {
    try {
      return await axios.put(`/tenants/${id}/projects/${project.projectId}`, {
        ...project
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    newProjectId,
    projects,
    getProjects,
    postProject,
    putProject
  }
})
