type itemsType = {
  value: string | number
  label: string
}
// 配信形式
export const deliveryFormatItems: Array<itemsType> = [
  { value: 'all', label: 'ストリーミング + ダウンロード' },
  { value: 'streaming', label: 'ストリーミング' },
  { value: 'download', label: 'ダウンロード' }
]
// 視点選択
export const modeFormatItems: Array<itemsType> = [
  { value: 'MultiSelect', label: 'マルチアングル' },
  { value: 'FreeView', label: '自由視点' }
]
// 公開設定
export const visibillitySettingItems: Array<itemsType> = [
  { value: 'public', label: '全体公開' },
  { value: 'projectOnly', label: 'プロジェクト限定' }
]
// バージョン設定
export const formatItems: Array<itemsType> = [
  { value: 'LightPlayer ContentFormat v1.1', label: 'LightPlayer v1.1' },
  { value: '1.0', label: 'LightPlayer v1.0 [Deprecated]' }
]
// フレームレート
export const frameRateItems: Array<itemsType> = [
  { value: 0, label: 'Auto (30fps)' },
  { value: 24, label: '24fps' },
  { value: 30, label: '30fps' },
  { value: 60, label: '60fps' },
  { value: 120, label: '120fps' }
]
// 字幕表示方向選択
export const writingModeItems: Array<itemsType> = [
  { value: 'horizontal', label: '横向き' },
  { value: 'vertical', label: '縦向き' }
]
// 字幕文字横位置設定
export const alignItems: Array<itemsType> = [
  { value: 'left', label: '左寄せ' },
  { value: 'center', label: '中央寄せ' },
  { value: 'right', label: '右寄せ' }
]
// 字幕文字縦位置設定
export const verticalAlignItems: Array<itemsType> = [
  { value: 'top', label: '上寄せ' },
  { value: 'middle', label: '中央寄せ' },
  { value: 'bottom', label: '下寄せ' }
]
