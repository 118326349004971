<template>
  <v-container class="pa-0">
    <v-tabs v-model="editorTab" bg-color="indigo" class="rounded-t-lg">
      <v-tab
        v-for="(tabName, i) in editor.tabs"
        :key="i"
        :value="tabName"
        @click="changeVisualTab(i)"
      >
        {{ tabName }}
      </v-tab>
    </v-tabs>

    <v-window v-model="editorTab">
      <v-card class="pa-8">
        <component
          :is="activeTab"
          v-if="activeTab"
          :content="localContent"
          :is-new="props.isNew"
          @change="onChange"
          @change-delivery-format="onChangeDeliveryFormat"
        />
      </v-card>
    </v-window>
  </v-container>
</template>

<script lang="ts" setup>
import { ref, computed, PropType, watch } from 'vue'

import { EditableContentFormat } from '../../types/EditableContentFormat'

import GeneralTab from '@/views/Clips/Editor/tabs/GeneralTab/index.vue'
import PlayerTab from '@/views/Clips/Editor/tabs/PlayerTab/index.vue'
import TransformTab from '@/views/Clips/Editor/tabs/TransformTab/index.vue'
import SourcesTab from '@/views/Clips/Editor/tabs/SourcesTab/index.vue'
import TracksTab from '@/views/Clips/Editor/tabs/TracksTab/index.vue'
import GroupTab from '@/views/Clips/Editor/tabs/GroupTab/index.vue'
import DomainFilterTab from '@/views/Clips/Editor/tabs/DomainFilterTab/index.vue'

const props = defineProps({
  isNew: {
    type: Boolean,
    required: false,
    default: true
  },
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)

interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
  (_event: 'changeDeliveryFormat', _val: string | undefined): void
}
const emit = defineEmits<Emits>()

const onChange = () => {
  emit('change', localContent.value)
}

const onChangeDeliveryFormat = (val: string) => {
  emit('changeDeliveryFormat', val)
}

const editorTab = ref<string>('')

const editor = ref<any>({
  tabComponent: [
    GeneralTab,
    PlayerTab,
    TransformTab,
    SourcesTab,
    TracksTab,
    GroupTab,
    DomainFilterTab
  ],
  tabs: ['全般', 'プレイヤー', '変形', 'ソース', 'トラック', 'グループ', 'ドメイン'],
  currentTab: 0
})

const activeTab = computed((): any => {
  return editor.value.tabComponent[editor.value.currentTab] ?? null
})

const changeVisualTab = (index: number) => {
  editor.value.currentTab = index
}

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)
</script>

<style lang="scss"></style>
