<template>
  <div class="container">
    <v-text-field
      v-model="localContent.title"
      color="primary"
      variant="underlined"
      :rules="[value => !!value || '入力必須です']"
      @change="change()"
    >
      <template #label>
        タイトル
        <span style="color: red">*</span>
      </template>
    </v-text-field>

    <v-text-field
      v-model="localContent.description"
      label="説明文"
      color="primary"
      variant="underlined"
      @change="change()"
    />

    <div class="field">
      <v-select
        v-model="localContent.streamingData.version"
        :items="formatItems"
        item-title="label"
        item-value="value"
        label="フォーマット"
        color="primary"
        variant="underlined"
        @update:model-value="change()"
      />
    </div>

    <div class="field">
      <v-select
        v-model="localContent.status"
        item-title="label"
        item-value="value"
        :items="visibillitySettingItems"
        label="公開範囲"
        color="primary"
        variant="underlined"
        @update:model-value="change()"
      />
    </div>

    <v-text-field
      v-model="localContent.streamingData.poster"
      type="text"
      label="ポスター画像"
      color="primary"
      variant="underlined"
      placeholder="ポスター画像のURLを設定"
      @change="change()"
    />

    <v-select
      v-model="localContent.streamingData.contentType"
      label="コンテンツタイプ"
      color="primary"
      variant="underlined"
      :items="['recorded', 'live']"
      @update:model-value="change()"
    />

    <v-select
      v-model="localContent.deliveryFormat"
      item-title="label"
      item-value="value"
      label="配信形式"
      color="primary"
      variant="underlined"
      :disabled="!props.isNew"
      :items="deliveryFormatItems"
      @update:model-value="changeDeliveryFormat(localContent.deliveryFormat)"
    />

    <div class="field">
      <v-select
        v-model.number="localContent.streamingData.frameRate"
        label="フレームレート"
        color="primary"
        variant="underlined"
        :items="frameRateItems"
        item-title="label"
        item-value="value"
        @update:model-value="change()"
      />
      <div class="text-caption">
        動画データのFPS情報。データの再生速度に影響は与えず、1フレームの長さを参照する機能（コマ送り）などで利用されます
      </div>
    </div>

    <!-- <div class="container mb-4">
      <label class="label">Duration</label>
      <div class="field is-grouped">
        <div class="control">
          <div class="select">
            <select v-model="durationType" @change="changeDuration()">
              <option value="auto" selected>Auto</option>
              <option value="fixed">Fixed</option>
            </select>
          </div>
        </div>
        <div class="control">
          <input
            v-model.number="duration"
            class="input"
            type="number"
            placeholder="再生時間(秒)"
            :disabled="durationType === 'auto'"
            @change="changeDuration()"
          />
        </div>
      </div>
      <div class="help">動画の終了時間の設定。未実装。</div>
    </div> -->

    <!-- <div class="field">
      <label class="label">Offset Time</label>
      <div class="control">
        <input
          v-model.number="localContent.streamingData.offsetTime"
          class="input"
          type="number"
          placeholder="時間オフセット(秒)"
          @change="change()"
        />
      </div>
      <div class="help">表示上の再生時間をずらす設定。未実装。</div>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, PropType, onMounted } from 'vue'
import { EditableContentFormat } from '@/views/Clips/Editor/types/EditableContentFormat'
import {
  formatItems,
  frameRateItems,
  visibillitySettingItems,
  deliveryFormatItems
} from '@/views/Clips/Editor/utils/SelectItemsUtil'

const props = defineProps({
  isNew: {
    type: Boolean,
    required: false,
    default: true
  },
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)

// localContent.duration は他のフィールドの様にv-modelでbindしていない。
// なので、changeDurationメソッドとsetupDurationsメソッドで、localContent.durationとコンポーネント変数durationで同期をとる
const durationType = ref<'auto' | 'fixed'>('auto')
const duration = ref(-1)
const setupDurations = () => {
  if (!localContent.value) return
  const passedDuration = localContent.value.streamingData.duration
  if (passedDuration) {
    const isMoreEqualZero = (n: any): boolean => {
      const num = parseInt(n, 10)
      return num && num >= 0
    }
    duration.value = isMoreEqualZero(passedDuration) ? passedDuration : -1
    durationType.value = passedDuration === 'auto' ? 'auto' : 'fixed'
  }
}
// const changeDuration = () => {
//   if (durationType.value === 'auto') {
//     duration.value = -1
//     localContent.value.streamingData.duration = 'auto'
//   } else if (durationType.value === 'fixed') {
//     duration.value = duration.value >= 0 ? duration.value : 0
//     localContent.value.streamingData.duration = duration.value
//   }
//   change()
// }
interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
  (_event: 'changeDeliveryFormat', _val: string | undefined): void
}
const emit = defineEmits<Emits>()

onMounted(() => {
  setupDurations()
})

watch(
  () => props.content,
  obj => {
    localContent.value = obj
    setupDurations()
  }
)

const change = () => {
  emit('change', localContent.value)
}

const changeDeliveryFormat = (val: string) => {
  emit('changeDeliveryFormat', val)
}
</script>

<style scoped></style>
