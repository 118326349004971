<template>
  <v-container>
    <v-card color="white" class="mt-12 ml-auto mr-auto" max-width="940">
      <v-toolbar title="ログイン" color="indigo" />
      <v-form ref="form" class="pa-8" @submit.prevent="signIn()">
        <v-text-field
          v-model="username"
          label="メールアドレス"
          :rules="usernameRules"
          class="mb-2"
        />
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          label="パスワード"
          :rules="passwordRules"
          @click:append="showPassword = !showPassword"
        />
        <v-row justify="center" class="mt-4">
          <v-col cols="auto">
            <v-btn color="primary" variant="flat" size="large" block min-width="240" type="submit">
              ログイン
            </v-btn>
          </v-col>
        </v-row>
        <hr class="mt-8 mb-5" />
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn variant="plain" color="primary" to="/password/reset">
              パスワードをお忘れの方はこちら
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <ErrorDialog
      :error="hasError"
      :title="errorTitle"
      :text="errorMessage"
      @close="hasError = false"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { LocationQueryValue, useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useProgressDialogStore } from '@/stores/dialog/progress'
import { ref, onMounted } from 'vue'
import { Auth } from 'aws-amplify'
import axios from '@/common/axios'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const progressStore = useProgressDialogStore()

const username = ref('')
const password = ref('')
const showPassword = ref(false)
const hasError = ref(false)
const errorTitle = ref('ログインに失敗しました')
const errorMessage = ref('')
const userToken = ref<string | LocationQueryValue[]>('')
const usernameRules = ref([
  (v: string) => !!v || 'メールアドレスを入力してください',
  (v: string) => /.+@.+\..+/.test(v) || 'メールアドレスが正しい形式ではありません'
])
const passwordRules = ref([
  (v: string) => !!v || 'パスワードを入力してください',
  (v: string | any[]) =>
    (v && v.length >= 8) || 'パスワードは８文字以上の半角英数字を設定してください'
])

onMounted(() => {
  if (authStore.signedIn) router.push('/')
  // NOTE：URLにuserTokenがある場合は、変数に格納しておきログイン時にusersAPIに渡してテナントとの紐付けを行う
  if (route.query.userToken) userToken.value = route.query.userToken
})

const signIn = async () => {
  try {
    progressStore.setLoading(true)
    // NOTE：ログインに時間がかかりその間にusersAPIを叩いてエラーとなってしまうので実行順制御
    await Auth.signIn(username.value, password.value)
    if (userToken.value) await axios.post('/users', { userToken: userToken.value })

    if (sessionStorage.getItem('return-path')) {
      router.push(`${sessionStorage.getItem('return-path')}`)
      sessionStorage.removeItem('return-path')
    } else {
      router.push('/contents')
    }
  } catch (error) {
    hasError.value = true
    if (`${error}`.match('UserNotConfirmedException')) {
      errorMessage.value =
        'このメールアドレスは検証されていません。再度、QRコード（URL）からユーザ登録してください。'
    } else if (`${error}`.match('NotAuthorizedException')) {
      errorMessage.value = 'メールアドレスまたはパスワードが正しくありません。'
    } else {
      errorMessage.value = '入力内容をご確認の上、再度お試しください。'
    }
  }
  progressStore.setLoading(false)
}
</script>

<style lang="scss"></style>
