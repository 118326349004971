<template>
  <v-container class="pa-0">
    <v-card class="pa-8">
      <div class="d-flex align-center justify-space-between mb-6">
        <h1 class="text-h6 text-lg-h4">字幕編集</h1>
        <v-spacer />
        <v-btn class="mr-4" size="small" color="teal" icon="mdi-cog" @click="showOption" />
        <v-btn size="small" color="primary" icon="mdi-plus" @click="addCaption" />
      </div>
      <template v-if="localContent.streamingData?.closedCaptions">
        <div
          v-for="(closedCaption, closedCaptionIndex) in [
            ...localContent.streamingData?.closedCaptions
          ]"
          :key="`closeCaption_${closedCaptionIndex}`"
        >
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(caption, captionIndex) in closedCaption.captions"
              :key="captionIndex"
            >
              <v-expansion-panel-title>
                <template class="w-100 d-flex align-center justify-space-between">
                  <h2 class="text-subtitle-1">
                    {{ caption.time ? `${caption.time}秒〜` : '未設定' }}
                  </h2>
                  <v-spacer />
                  <v-btn
                    icon="mdi-trash-can"
                    size="x-small"
                    variant="plain"
                    color="red"
                    class="mr-2"
                    @click="deleteCaption(captionIndex)"
                  />
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="pt-4">
                  <div class="d-flex flex-wrap justify-space-between">
                    <v-text-field
                      v-model.number="caption.time"
                      type="number"
                      color="primary"
                      class="w-50 pr-4"
                      variant="underlined"
                      min="0"
                      :rules="[
                        v => v !== '' || '入力必須です',
                        v => checkNumberValue.test(v) || '数字以外は入力できません。'
                      ]"
                      @input="onChangeCaption"
                    >
                      <template #label>
                        表示開始時刻 (秒)
                        <span style="color: red">*</span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-model.number="caption.duration"
                      type="number"
                      color="primary"
                      class="w-50 pl-4"
                      variant="underlined"
                      min="1"
                      :rules="[
                        v => v !== '' || '入力必須です',
                        v => checkNumberValue.test(v) || '数字以外は入力できません。'
                      ]"
                      @input="onChangeCaption"
                    >
                      <template #label>
                        表示時間 (秒)
                        <span style="color: red">*</span>
                      </template>
                    </v-text-field>
                  </div>
                  <v-textarea
                    v-model="caption.label"
                    color="primary"
                    :rules="[value => !!value || '入力必須です']"
                    @input="onChangeCaption"
                  >
                    <template #label>
                      ラベル
                      <span style="color: red">*</span>
                    </template>
                  </v-textarea>
                  <v-checkbox
                    :value="caption.options ? true : false"
                    label="オプション設定"
                    color="primary"
                    hide-details
                    @change="toggleOptionSetting(captionIndex)"
                  />
                  <template v-if="caption.options && Object.keys(caption.options).length !== 0">
                    <div class="d-flex flex-wrap justify-space-between">
                      <v-text-field
                        v-model="caption.options.fontSize"
                        color="primary"
                        class="w-50 pr-4"
                        variant="underlined"
                        label="文字サイズ(単位含む)"
                        @input="onChangeCaption"
                      />
                      <v-text-field
                        v-model="caption.options.inlineSize"
                        color="primary"
                        class="w-50 pl-4"
                        variant="underlined"
                        label="字幕表示幅(単位含む)"
                        @input="onChangeCaption"
                      />
                    </div>

                    <div class="d-flex align-center mb-3 mt-1">
                      <label class="mr-4">文字色 ({{ caption.options.color }})</label>
                      <input
                        v-model="caption.options.color"
                        type="color"
                        @change="onChangeCaption"
                      />
                    </div>
                    <div class="d-flex align-center mb-3">
                      <label class="mr-4">背景色 ({{ caption.options.bgColor }})</label>
                      <input
                        v-model="caption.options.bgColor"
                        type="color"
                        @change="onChangeCaption"
                      />
                    </div>
                    <div class="d-flex">
                      <label class="mr-4 mt-1">
                        透明度 ({{ caption.options.opacity ? caption.options.opacity * 100 : 0 }}%)
                      </label>
                      <v-slider
                        v-model="caption.options.opacity"
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        color="primary"
                        @change="onChangeCaption"
                      />
                    </div>
                    <div class="d-flex flex-wrap justify-space-between">
                      <v-select
                        v-model="caption.options.writingMode"
                        item-title="label"
                        item-value="value"
                        color="primary"
                        class="w-50 pr-4"
                        label="視点選択"
                        variant="underlined"
                        :items="writingModeItems"
                        @update:model-value="onChangeCaption"
                      />
                      <v-select
                        v-model="caption.options.textAlign"
                        item-title="label"
                        item-value="value"
                        color="primary"
                        class="w-50 pl-4"
                        label="文字寄せ"
                        variant="underlined"
                        :items="alignItems"
                        @update:model-value="onChangeCaption"
                      />
                    </div>
                    <div class="d-flex flex-wrap justify-space-between">
                      <v-select
                        v-model="caption.options.align"
                        item-title="label"
                        item-value="value"
                        color="primary"
                        class="w-50 pr-4"
                        label="字幕位置"
                        variant="underlined"
                        :items="alignItems"
                        @update:model-value="onChangeCaption"
                      />
                      <v-select
                        v-model="caption.options.verticalAlign"
                        item-title="label"
                        item-value="value"
                        color="primary"
                        class="w-50 pl-4"
                        label="文字上下位置"
                        variant="underlined"
                        :items="verticalAlignItems"
                        @update:model-value="onChangeCaption"
                      />
                    </div>
                    <div class="d-flex flex-wrap justify-space-between">
                      <v-text-field
                        v-model="caption.options.offsetX"
                        color="primary"
                        class="w-50 pr-4"
                        variant="underlined"
                        type="number"
                        @input="onChangeCaption"
                      >
                        <template #label>横ずらし位置(単位不要)</template>
                      </v-text-field>
                      <v-text-field
                        v-model="caption.options.offsetY"
                        color="primary"
                        class="w-50 pl-4"
                        variant="underlined"
                        type="number"
                        @input="onChangeCaption"
                      >
                        <template #label>縦ずらし位置(単位不要)</template>
                      </v-text-field>
                    </div>
                  </template>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </template>
    </v-card>
    <CaptionOptionEditModal
      :show="showOptionDialog"
      :options="commonOptionValues"
      title="字幕全体設定"
      @close="closeCommonOption"
    />
  </v-container>
</template>

<script lang="ts" setup>
import { ref, PropType, watch, onMounted, computed } from 'vue'
import {
  writingModeItems,
  alignItems,
  verticalAlignItems
} from '@/views/Clips/Editor/utils/SelectItemsUtil'
import { EditableContentFormat } from '@/views/Clips/Editor/types/EditableContentFormat'
import { optionDefaultSetting } from '@/views/Clips/Editor/utils/ContentUtils'

const props = defineProps({
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)
const showOptionDialog = ref<boolean>(false)

interface Emits {
  (_event: 'changeCaption', _val: Partial<EditableContentFormat>): void
}
const emit = defineEmits<Emits>()

const onChangeCaption = () => {
  emit('changeCaption', localContent.value)
}

const checkNumberValue = /^[0-9]+$/

const addCaption = () => {
  if (localContent.value.streamingData?.closedCaptions.length === 0) {
    localContent.value.streamingData.closedCaptions[0] = {
      captions: [],
      lang: 'ja',
      isDefault: true
    }
  }
  localContent.value.streamingData?.closedCaptions[0].captions.push({
    time: null,
    duration: null,
    label: '',
    options: {}
  })

  onChangeCaption()
}

const commonOptionValues = computed(() => {
  if (
    localContent.value.streamingData?.closedCaptions[0] &&
    localContent.value.streamingData?.closedCaptions[0].options
  ) {
    return localContent.value.streamingData?.closedCaptions[0].options
  } else {
    return { ...optionDefaultSetting }
  }
})

const deleteCaption = (index: number) => {
  if (localContent.value.streamingData) {
    delete localContent.value.streamingData.closedCaptions[0].captions[index]
    const data = localContent.value.streamingData.closedCaptions[0].captions.filter(Boolean)
    localContent.value.streamingData.closedCaptions[0].captions = data
  }

  onChangeCaption()
}

const showOption = () => {
  showOptionDialog.value = true
}

const closeCommonOption = () => {
  showOptionDialog.value = false
  onChangeCaption()
}

const toggleOptionSetting = (index: number) => {
  const optionItem = localContent.value.streamingData?.closedCaptions[0].captions[index].options
  if (optionItem && Object.keys(optionItem).length === 0) {
    if (localContent.value.streamingData)
      localContent.value.streamingData.closedCaptions[0].captions[index].options =
        optionDefaultSetting
  } else {
    if (
      localContent.value.streamingData &&
      localContent.value.streamingData.closedCaptions?.[0]?.captions[index]
    ) {
      localContent.value.streamingData.closedCaptions[0].captions[index].options = {}
    }
  }
  onChangeCaption()
}

onMounted(() => {
  if (
    localContent.value.streamingData?.closedCaptions &&
    localContent.value.streamingData?.closedCaptions.length > 0
  ) {
    if (!localContent.value.streamingData?.closedCaptions[0]?.options) {
      localContent.value.streamingData.closedCaptions[0].options = optionDefaultSetting
    }
  }
})

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)
</script>

<style lang="scss"></style>
